import React from "react";
import { Link } from "react-router-dom";
import { Route, Switch } from "react-router";
import './style/_style.scss';
import '../../assets/common.scss';
import Header from "../template/header";
import Footer from "../template/footer";
import { isNullOrUndefined } from "util";
import Banner from "./banner";
import { FILE_UPLOAD_WEB_CONTENT, BASE_API } from "../../constants/appSettings";
import { get, post } from "../../api/index";
import Slider from "react-slick";
import { createSlugName } from "../../utils/common";
import Barlink from "../home/bar-link";

const API = 'http://195.201.59.164:9094/';

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listBanner: [],
            isMoble: false
        };
    }

    // getBanner() {
    //     get('web/api/page-home/slide-banner',
    //         result => {
    //             this.setState({
    //                 listBanner: result
    //             }, () => {
    //                 this.setState({
    //                     loadPage: false
    //                 });
    //             });
    //         }, () => { },
    //         BASE_API
    //     )
    // }


    _handleCheckWindowSize() {
        let width = window.innerWidth;
        this.setState({
            isMoble: false
        }, () => {
            this.setState({
                isMoble: width < 768
            })
        })

    }
    componentDidMount() {
        // this.getBanner();
        this._handleCheckWindowSize();
        window.addEventListener("resize", () => {
            this._handleCheckWindowSize()
        })

    }
    render() {
        const settings = {
            dots: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            speed: 1000,
            autoplaySpeed: 4000,
            cssEase: "linear"
        };
        let { listBanner, isMoble } = this.state
        let { Banner } = this.props
        return (
            <div>
                <div id="myCarousel" className="carousel slide" data-ride="carousel" >

                    <Slider {...settings}>
                        {
                            Banner && Banner.map((item, index) =>
                                <div key={index}>
                                    {
                                        item.link == '#' ?
                                            <Link to='#'>
                                                <div className='item-slider' >
                                                    <img src={isMoble ? item.backgroundMobile : item.background}></img>
                                                </div>
                                            </Link> :
                                            item.target ?
                                                <a target="_blank" href={item.link}>
                                                    <div className='item-slider' >
                                                        <img src={isMoble ? item.backgroundMobile : item.background}></img>

                                                    </div>
                                                </a> :
                                                <Link to='#'>
                                                    <div className='item-slider' >
                                                        <img src={isMoble ? item.backgroundMobile : item.background}></img>

                                                    </div>
                                                </Link>
                                    }


                                </div>
                            )
                        }
                    </Slider>
                    {/* <!-- Indicators --> */}
                    {/* <ol className="carousel-indicators">
    <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
    <li data-target="#myCarousel" data-slide-to="1"></li>
</ol> */}

                    {/* <!-- Wrapper for slides --> */}
                    {/* backgroundImage: 'url(' + img1 + ')' */}

                    {/* <div className="carousel-inner" >
    <div className="item active item-slider1" style={{ height: '493px' }}></div>
    <div className="item item-slider2" style={{ height: '493px' }}></div>
</div> */}

                    {/* <!-- Left and right controls --> */}
                    {/* <a className="left carousel-control" href="#myCarousel" data-slide="prev">
    <span className="glyphicon glyphicon-chevron-left"></span>
    <span className="sr-only">Previous</span>
</a>
<a className="right carousel-control" href="#myCarousel" data-slide="next">
    <span className="glyphicon glyphicon-chevron-right"></span>
    <span className="sr-only">Next</span>
</a> */}
                </div>
            </div>
        );
    }
}

export default Home;

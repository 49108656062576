import React from "react";
import "./style.scss";
import $ from 'jquery'
class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            labelWidth: "0px",
            isError: false
        }
    }

    componentDidMount() {
        this.setState({
            labelWidth: (this.props.labelPosition === "top-left" || this.props.labelPosition === "top-right") ? "0px" : (($(this.refs.acb).width() + 20) + "px")
        })
    }

    onBlur(name, displayName, value, skipError) {
        let {
            validations
        } = this.props

        if (validations) {
            let errors = ""
            for (let i = 0; i < validations.length; i++) {
                const validate = validations[i]
                var error = validate(displayName, value)
                if (error) errors += "\n" + error
            }

            if (skipError && errors.length === 0) {
                $(".text-danger").removeClass("show");
            } else {
                this.setState({
                    isError: (errors.length > 0) ? true : false
                })
                var element = document.getElementById("validator-for-" + name)
                if (element) element.innerHTML = errors
                if (errors)
                    $("#" + name).addClass("error");
                else
                    $("#" + name).removeClass("error");
            }
        }
    }

    render() {
        let {
            labelWidth,
            isError
        } = this.state
        let {
            name,
            displayName,
            type,
            placeholder,
            value,
            tabIndex,
            fontSize,
            autoFocus,
            label,
            multiline,
            icon
        } = this.props

        return (
            <div className={"custom-input " + (this.props.className ? this.props.className : "")}>
                <div
                    className={(this.props.edit ? "edit" : "view")}
                    id={(this.props.id) ? this.props.id : ""}
                    style={{ width: "calc(100% - " + labelWidth + ")" }}
                    id={name}
                >
                    {
                        this.props.edit ? (!multiline ?
                            <div className='custom-form-control'>
                                <input
                                    type={type}
                                    placeholder={placeholder ? placeholder : ""}
                                    value={value}
                                    tabIndex={tabIndex}
                                    onChange={(e) => this.props.onChange(e)}
                                    onBlur={(e) => this.onBlur(name, displayName, e.target.value)}
                                    style={{ fontSize: fontSize }}
                                    autoFocus={autoFocus}
                                />
                                <i className={icon} />
                            </div>
                            : <textarea
                                placeholder={placeholder ? placeholder : ""}
                                value={value}
                                tabIndex={tabIndex}
                                onChange={(e) => this.props.onChange(e)}
                                onBlur={(e) => this.onBlur(name, displayName, e.target.value)}
                                style={{ fontSize: fontSize }}
                                autoFocus={autoFocus}
                            />) : <span
                                style={{ fontSize }}
                            >
                                {
                                    value
                                }
                            </span>
                    }
                </div>
                <div className={(isError) ? "text-danger show" : "text-danger"}>
                    <pre id={"validator-for-" + name} />
                </div>
            </div>

        );
    }
}
export default Index;

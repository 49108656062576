import React from "react";
import './style/style.css';
import './style/style-mobile.css';
import ArticleSidebar from './article-sidebar'
import { NavLink } from "react-router-dom";
import { BASE_API } from "../../../constants/appSettings";
import { get, post } from "../../../api";
import { formatDate, formatCurrency, formatDate2, formatDate4 } from "../../../utils/common";
import { isMobile, showError, showSuccess } from "../../../utils/app";
import { ArticleFooter } from "../../common/article-footer";

class Index extends React.Component {

    constructor() {
        super();
        this.state = {
            data: null,
            id: "",
            page: "banner",
            data: null
        };
    }

    onloadDetail(id) {
        get("web/api/page-home/slide-content/" + id,
            result => {
                this.setState({
                    data: result
                })
            }, () => { },
            BASE_API
        );
    }

    componentDidMount() {
        const { id } = this.props.match.params;
        this.setState({
            id: id
        });
        this.onloadDetail(id);
    }

    componentWillReceiveProps(props) {
        const { id } = props.match.params;
        this.setState({
            id: id
        });
        this.onloadDetail(id);
    }

    submitLike = (like) => {
        let {
            id,
            page
        } = this.state;

        post("web/submit-like?id=" + id + "&like=" + like + "&page=" + page, {},
            result => {
                showSuccess("Success");
                this.onloadDetail(id);
            },
            () => {
                showError("Error");
            },
            BASE_API
        );

    };

    render() {


        if (isMobile()) return this.renderMobile();
        return this.renderDesktop();
    }

    renderMobile() {
        let {
            data,
            id,
            page
        } = this.state;
        return (
            <div className="components-mobile">
                <div className="articles-slide">
                    <nav className="sub-nav">
                        <ol className="breadcrumbs text-summary text-row-1 mr20">
                            <li>
                                <NavLink to="/">Home</NavLink>
                            </li>
                            <li>
                                <NavLink to="/articles-slide-content">Articles Slide Content</NavLink>
                            </li>
                            <li>
                                <a>{data && data.title}</a>
                            </li>
                        </ol>

                        {/* <form className="search">
                            <i className="fas fa-search" />
                            <input type="search" placeholder="Search" />
                        </form> */}
                    </nav>

                    <div className="article-container">
                        {/* <section className="article-sidebar">
                            <ArticleSidebar
                                articleSlide={data.id}
                            />
                            <i className="fas fa-chevron-down" />
                        </section> */}

                        {/* <article className="article">
                            <div className="article-header">
                                <h1 className="article-title">{data && data.description}</h1>
                                <div className="article-author">
                                    <div className="avatar article-avatar">
                                        <img src={require("../../../assets/images/iconnew.png")} alt="Avatar" className="user-avatar" />
                                    </div>
                                    <div className="article-meta">
                                        KryptonieX
                                    <ul className="meta-group">
                                            <li className="meta-data">
                                                <time>{formatDate4(data && data.dateCreate)}</time>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div> */}

                        <article className="article">

                            <div className="article-content">
                                {
                                    data && <div className="article-body" dangerouslySetInnerHTML={{ __html: data.details }} />

                                }
                            </div>

                        </article>

                    </div>
                </div>
            </div >
        );
    }

    renderDesktop() {
        let {
            data,
            id,
            page
        } = this.state;

        return (
            <div className="components-pc">
                <div className="content-main articles-slide">
                    <div className="container">
                        <nav className="sub-nav">
                            <ol className="breadcrumbs text-summary text-row-1 mr20">
                                <li>
                                    <NavLink to="/">Home</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/articles-slide-content">Articles Slide Content</NavLink>
                                </li>
                                <li>
                                    <a>{data && data.description}</a>
                                </li>
                            </ol>

                            {/* <form className="search">
                                <i className="fas fa-search" />
                                <input type="search" placeholder="Search" />
                            </form> */}
                        </nav>

                        <div className="article-container">
                            {/* <section className="article-sidebar">
                                <ArticleSidebar
                                    articleSlide={data && data.id}
                                />
                            </section> */}

                            <article className="article">

                                <div className="article-content">
                                    {
                                        data && <div className="article-body" dangerouslySetInnerHTML={{ __html: data.details }} />

                                    }
                                </div>

                            </article>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Index;

import React from 'react';
import { Player, ControlBar, PlayToggle } from 'video-react';
import "video-react/dist/video-react.css";

const sources = {
    //bunnyMovie: require('../../../assets/video/video.mp4'),
    bunnyMovie: 'https://foresterx.com/video/video.mp4',
};

export class PlayerControl extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            source: sources.bunnyMovie,
            player: null
        };
    }

    componentDidMount() {
        this.player.subscribeToStateChange(this.handleStateChange.bind(this));
    }

    handleStateChange(state) {
        this.setState({
            player: state
        }, () => {
            this.player.volume = 0;
            if (this.state.player && this.state.player.duration === this.state.player.currentTime) {
                this.player.play();
            }
        });
    }
    render() {
        return (
            <div>
                <Player
                    autoPlay
                    loop
                    muted='bool'
                    playsInline
                    ref={player => {
                        this.player = player;
                    }}
                    src={this.props.src}
                    width='100%'
                    height='100%'
                >
                    <ControlBar autoHide={true} disableDefaultControls={true}>
                        <PlayToggle />
                    </ControlBar>
                </Player>
            </div>
        );
    }
}

import React from "react";

export class Pagination extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      pages: [],
      lastPage: 1
    };
  }


  resolvePages = props => {
    const { total, pageSize } = props;
    const { currentPage } = this.state;
    let pages = [];

    var numOfPages =
      parseInt(total / pageSize) + (total % pageSize > 0 ? 1 : 0);

    if (numOfPages <= 2) {
      pages.push(1);
      pages.push(2);
    } else {
      if (
        currentPage - 2 >= 1 &&
        (currentPage < 3 || currentPage > numOfPages - 1)
      )
        pages.push(currentPage - 2);
      if (currentPage - 1 >= 1) pages.push(currentPage - 1);
      pages.push(currentPage);
      if (currentPage + 1 <= numOfPages) pages.push(currentPage + 1);
      if (currentPage + 2 <= numOfPages && currentPage < 2)
        pages.push(currentPage + 2);
    }
    this.setState({
      pages,
      lastPage: numOfPages
    });
  };

  onChange = page => {
    this.setState({ currentPage: page }, () => {
      this.resolvePages(this.props);
      let { onChange } = this.props;
      if (!onChange) return;
      onChange(page);
    });
  };

  componentWillReceiveProps(props) {
    const { total, pageSize } = props;
    if (total == this.props.total && pageSize == this.props.pageSize) return;
    if (pageSize != this.props.pageSize || total != this.props.total) {
      this.setState({
        currentPage: 1
      }, () => this.resolvePages(props))
    }
    this.resolvePages(props);
  }

  componentWillMount() {
    this.resolvePages(this.props);
  }

  render() {
    let { pages, lastPage, currentPage } = this.state;
    return (
      <nav aria-label="Page navigation example">
        <ul className="pagination justify-content-end">
          <li className={"page-item"}>
            <a className={"page-link"} onClick={() => this.onChange(1)}>
              First
            </a>
          </li>
          {pages.map(page => (
            <li
              key={page}
              className={"page-item" + (page == currentPage ? " active" : "")}
            >
              <a className={"page-link"} onClick={() => this.onChange(page)}>
                {page}
              </a>
            </li>
          ))}
          <li className={"page-item"}>
            <a className={"page-link"} onClick={() => this.onChange(lastPage)}>
              Last
            </a>
          </li>
        </ul>
      </nav>
    );
  }
}
export default Pagination;

import React from "react";
import { Link, NavLink } from "react-router-dom";
import "./CSS/style.scss";
import '../../../assets/common.scss'
import { ACCESS_TOKEN, USER_INFO } from '../../../constants/localStorageKeys'
import { BASE_API } from "../../../constants/appSettings";
import { get } from "../../../api/index";
import { createSlugName } from "../../../utils/common";
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

const logo = require('../../../assets/images/logo.png')
class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      expanded: {},
      activeMenuMini: 0,
      openMenuMini: false,
      isSigned: false,
      email: null,
      activemenu: false,
      dataLogo: logo,
      activeMenuPc: '/credit-card',
      menu: [
        {
          "id": 1,
          "fullName": " Credit Card Processing",
          "details": null,
          "link": "/credit-card",
          "index": 1,
          "target": false,
          "parentsId": null,
          "menuHeaderWebs": []
        },
        {
          "id": 2,
          "fullName": "Nails & Salon POS",
          "details": null,
          "link": "/nails-salon",
          "index": 2,
          "target": false,
          "parentsId": null,
          "menuHeaderWebs": []
        },
        {
          "id": 3,
          "fullName": "Funding",
          "details": null,
          "link": "/funding",
          "index": 3,
          "target": false,
          "parentsId": null,
          "menuHeaderWebs": []
        },
        {
          "id": 4,
          "fullName": "Email & SMS MARTKETING",
          "details": null,
          "link": "/email-sms",
          "index": 4,
          "target": false,
          "parentsId": null,
          "menuHeaderWebs": []
        }
      ]
    }

  }
  _handleCheckWindowSize() {
    let width = window.innerWidth;
    this.setState({
      isMoble: false
    }, () => {
      this.setState({
        isMoble: width < 992
      })
    })

  }
  // _signOut() {
  //     window.localStorage.removeItem(ACCESS_TOKEN)
  //     window.localStorage.removeItem(USER_INFO)
  //     this.setState({
  //         isSigned: false,
  //         email: null
  //     })
  // }
  _activeMenu() {
    let { activemenu } = this.state
    activemenu = !activemenu
    this.setState({ activemenu })
  }
  _closeMenu() {
    this.setState({ activemenu: false })
  }
  handleChange(index) {
    this.setState({ activeMenuMini: index })
  }
  // getMenu() {
  //     get('web/api/menu-header',
  //         result => {
  //             this.setState({
  //                 menu: result
  //             })
  //         },
  //         BASE_API
  //     )
  // }


  // getLogo() {
  //     get('web/api/logo',
  //         result => {
  //             this.setState({
  //                 dataLogo: result
  //             }, () => {
  //                 this.setState({
  //                     loadPage: false
  //                 });
  //             });
  //         }, () => { },
  //         BASE_API
  //     )
  // }
  splitMenuPC() {
    let str = window.location.href
    let words = window.location.href.split('/');
    this.setState({
      activeMenuPc: '/' + words[3]
    })
  }
  componentDidMount() {
    let token = window.localStorage.getItem(ACCESS_TOKEN)
    let user = window.localStorage.getItem(USER_INFO)
    if (user) user = JSON.parse(user)
    this.setState({
      isSigned: token ? true : false,
      email: user ? user.email : ""
    })


    this._handleCheckWindowSize();
    window.addEventListener("resize", () => {
      this._handleCheckWindowSize()
    })
    // this.getLogo()
    // this.getMenu()
    this.splitMenuPC()
  }

  render() {
    let { isMoble, activemenu, dataLogo, menu, openMenuMini, activeMenuPc, activeMenuMini } = this.state

    return (
      <div className="header-main">
        {
          isMoble == false ?
            <div>
              <div className="top-head d-none">
                <ul className="notice-list">
                  <li className="item-list">
                    <span className="text-list">MAY 3 -</span>
                    <a className="link-list"> Deposit Minimum Update</a>
                  </li>
                  <li className="item-list">
                    <span className="text-list">ARR 24 -</span>
                    <a className="link-list"> Cardano (ADA) &amp; Nebulas (NAS) Are Now Available on Huobi.com</a>
                  </li>
                  <li className="item-list">
                    <span className="text-list">ARR 18 -</span>
                    <a className="link-list"> XRP Is Now Available on Huobi.com</a>
                  </li>
                </ul>
                <div className="view-all-btn">VIEW ALL</div>
              </div>
              <div className="l-head">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div className="h-logo">
                    <NavLink to='/credit-card' className="logo-head">
                      {/* <img src={dataLogo && BASE_API + dataLogo.desktop}></img> */}
                      <img src={dataLogo}></img>
                    </NavLink>
                  </div>
                  <ul className="nav-menu">
                    {
                      menu && menu.map((item, index) =>
                        <li className={"item-menu" + (activeMenuPc == item.link ? ' activemenuPc' : '')} key={index} onClick={() => this.splitMenuPC()}>
                          {
                            item.menuHeaderWebs.length == 0 ?
                              <Link to={item.link}>{item.fullName}</Link>
                              :
                              <div>
                                <a>{item.fullName}</a>
                                <div className='sub-menu'>
                                  <ul>
                                    {
                                      item.menuHeaderWebs.map((item_a, index) =>
                                        <li key={index}>
                                          {
                                            item_a.link == '#' ?
                                              <Link to={'/page-header/' + createSlugName(item_a.fullName) + '/' + item_a.id}>{item_a.fullName}</Link>
                                              :
                                              item_a.target ?
                                                <a target="_blank" href={item_a.link}>{item_a.fullName}</a>
                                                :
                                                <a href={item_a.link}>{item_a.fullName}</a>
                                          }
                                        </li>
                                      )
                                    }
                                  </ul>
                                </div>

                              </div>
                          }
                        </li>
                      )
                    }
                  </ul>
                </div>
                <ul className="nav-menu">
                  {/* <li className="item-menu">
                                        <a>Referral Bonus</a>
                                    </li>
                                    <li className="item-menu">
                                        <a>Blog</a>
                                    </li>
                                    <li className="item-menu">
                                        <a href='https://backoffice.foresterx.com/'>Log In</a>
                                    </li>
                                    <li className="item-menu log-up">
                                        <a href='https://backoffice.foresterx.com/sign-up'>Register</a>
                                    </li> */}
                  {/* <li className="item-menu">
                                        <a>My Referrals</a>
                                    </li>
                                    <li className="item-menu">
                                        <a>Balances</a>
                                    </li>
                                    <li className="item-menu">
                                        <a>Order</a>
                                    </li>
                                    <li className="item-menu">
                                        <a>abc@gmail.com</a>
                                    </li> */}
                </ul>
              </div>
            </div>
            :
            <div className='responsive-header'>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0 25px' }}>
                <div className='left-head'>
                  <NavLink to='/credit-card' className="logo-head">
                    {/* <img src={require('../../../assets/images/dark-logo.png')}></img> */}
                    <img src={dataLogo}></img>
                  </NavLink>
                </div>
                <div className='right-head' onClick={() => this._activeMenu()}>
                  <span><i className="fas fa-bars"></i></span>
                </div>
              </div>
              {
                activemenu && <div className='menu-mini'>
                  <ul className="content-menu">
                    {
                      menu && menu.map((item, index) =>
                      (
                        item.menuHeaderWebs.length == 0 ?
                          <ExpansionPanelSummary
                            expandIcon={false}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            className="title-menu-mini"
                          >
                            <Link onClick={() => this._activeMenu()} to={item.link}>{item.fullName}</Link>
                          </ExpansionPanelSummary>

                          : <ExpansionPanel
                            key={index} onClick={() => this.setState({ openMenuMini: !openMenuMini })}>
                            <ExpansionPanelSummary
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              className="title-menu-mini"
                            >
                              <Link to={item.link}>{item.fullName}</Link>
                            </ExpansionPanelSummary>
                            {
                              item.menuHeaderWebs.map((item_a, j) =>
                                item_a.link == '#' ?
                                  <ExpansionPanelDetails key={j} className="sub-menu-mini">
                                    <Link to={'/page-header/' + createSlugName(item_a.fullName) + '/' + item_a.id}>{item_a.fullName}</Link>
                                  </ExpansionPanelDetails>
                                  :
                                  item_a.target ?
                                    <ExpansionPanelDetails className="sub-menu-mini">
                                      <a target="_blank" href={item_a.link}>{item_a.fullName}</a>
                                    </ExpansionPanelDetails>
                                    :
                                    <ExpansionPanelDetails className="sub-menu-mini">
                                      <a href={item_a.link}>{item_a.fullName}</a>
                                    </ExpansionPanelDetails>
                              )
                            }
                          </ExpansionPanel>
                      )
                      )
                    }
                    {/* <li className="title-menu-mini" onClick={() => this._closeMenu()}><a >Referral Bonus</a></li>
                                        <li className="title-menu-mini" onClick={() => this._closeMenu()}><a >Blog</a></li>
                                        <li className="title-menu-mini" onClick={() => this._closeMenu()}><a href='#'>Sign In</a></li>
                                        <li className="title-menu-mini" onClick={() => this._closeMenu()}><a href='#'>Register</a></li> */}
                  </ul>
                </div>
              }

            </div>
        }
      </div>
    );
  }
}

export default Header;

import React from "react";
import './style/style.css';
import './style/style-mobile.css';
import ArticleSidebar from './article-sidebar'
import { NavLink } from "react-router-dom";
import { BASE_API } from "../../../constants/appSettings";
import { get, post } from "../../../api";
import { formatDate, formatCurrency, formatDate2 } from "../../../utils/common";
import { isMobile, showError, showSuccess } from "../../../utils/app";
import { ArticleFooter } from "../../common/article-footer";

class Index extends React.Component {

    constructor() {
        super();
        this.state = {
            news: null,
            id: "",
            page: "news"
        };
    }

    onloadDetail(id) {
        get("web/get-news/" + id,
            result => {
                this.setState({
                    news: result
                })
            }, () => { },
            BASE_API
        );
    }

    componentDidMount() {
        const { id } = this.props.match.params;
        this.setState({
            id: id
        });
        this.onloadDetail(id);
    }

    submitLike = (like) => {
        let {
            id,
            page
        } = this.state;

        post("web/submit-like?id=" + id + "&like=" + like + "&page=" + page, {},
            result => {
                showSuccess("Success");
                this.onloadDetail(id);
            },
            () => {
                showError("Error");
            },
            BASE_API
        );

    };

    componentWillReceiveProps(props) {
        const { id } = props.match.params;
        this.setState({
            id: id
        });
        this.onloadDetail(id);
    }

    render() {
        if (isMobile()) return this.renderMobile();
        return this.renderDesktop();
    }

    renderMobile() {
        let {
            news,
            openCollapsible,
            page,
            id
        } = this.state;

        if (news == null)
            return null;

        return (
            <div className="components-mobile">
                <div className="articles">
                    <nav className="sub-nav">
                        <ol className="breadcrumbs text-summary text-row-1 mr20">
                            <li>
                                <NavLink to="/">Home</NavLink>
                            </li>
                            <li>
                                <NavLink to="/articles">Articles</NavLink>
                            </li>
                            <li>
                                <a>{news.description}</a>
                            </li>
                        </ol>

                        {/* <form className="search">
                            <i className="fas fa-search" />
                            <input type="search" placeholder="Search" />
                        </form> */}
                    </nav>

                    <div className="article-container">
                        <section className="article-sidebar">
                            <ArticleSidebar
                                idNews={news.id}
                                openCollapsible={false}
                            />
                            <i className="fas fa-chevron-down" />
                        </section>

                        <article className="article">
                            <div className="article-header">
                                <h1 className="article-title">{news.description}</h1>
                                <div className="article-author">
                                    <div className="avatar article-avatar">
                                        <img src={require("../../../assets/images/iconnew.png")} alt="Avatar" className="user-avatar" />
                                    </div>
                                    <div className="article-meta">
                                        KryptonieX
                                    <ul className="meta-group">
                                            <li className="meta-data">
                                                <time>{formatDate2(news.creationDate)}</time>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <section className="article-info">
                                <div className="article-content">
                                    <div className="article-body" dangerouslySetInnerHTML={{ __html: news.details }} />
                                </div>
                                <ArticleFooter
                                    submitLike={(e) => this.submitLike(e)}
                                    idArticle={id}
                                    likeNo={news.likeNo}
                                    likeYes={news.likeYes}
                                    page={page}
                                />
                            </section>

                        </article>
                    </div>
                </div>
            </div>
        );
    }

    renderDesktop() {
        let {
            news,
            id,
            page
        } = this.state;

        if (news == null)
            return null;

        return (
            <div className="components-pc">
                <div className="content-main articles">
                    <div className="container">
                        <nav className="sub-nav">
                            <ol className="breadcrumbs text-summary text-row-1 mr20">
                                <li>
                                    <NavLink to="/">Home</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/articles">Articles</NavLink>
                                </li>
                                <li>
                                    <a>{news.description}</a>
                                </li>
                            </ol>

                            {/* <form className="search">
                                <i className="fas fa-search" />
                                <input type="search" placeholder="Search" />
                            </form> */}
                        </nav>

                        <div className="article-container">
                            <section className="article-sidebar">
                                <ArticleSidebar
                                    idNews={news.id}
                                />
                            </section>

                            <article className="article">
                                <div className="article-header">
                                    <h1 className="article-title">{news.description}</h1>
                                    <div className="article-author">
                                        <div className="avatar article-avatar">
                                            <img src={require("../../../assets/images/iconnew.png")} alt="Avatar" className="user-avatar" />
                                        </div>
                                        <div className="article-meta">
                                            KryptonieX
                                        <ul className="meta-group">
                                                <li className="meta-data">
                                                    <time>{formatDate2(news.creationDate)}</time>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <section className="article-info">
                                    <div className="article-content">
                                        <div className="article-body" dangerouslySetInnerHTML={{ __html: news.details }} />
                                        <ArticleFooter
                                            submitLike={(e) => this.submitLike(e)}
                                            idArticle={id}
                                            likeNo={news.likeNo}
                                            likeYes={news.likeYes}
                                            page={page}
                                        />
                                    </div>
                                </section>

                            </article>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Index;

import React from "react";
import { Link } from "react-router-dom";
import "./style.scss";
import $ from "jquery";
import '../../../assets/style/common.scss';
import { BASE_API } from "../../../constants/appSettings";
import { get, post } from "../../../api/index";
import { createSlugName } from "../../../utils/common";
import Slider from "react-slick";

class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            openAbout: false,
            openDk: false,
            isMoble: false,
            dataInfo: null,
            menuFooter: [
                {
                    "id": 1,
                    "fullName": "Trading Platform",
                    "details": null,
                    "link": "#",
                    "index": 1,
                    "target": false,
                    "parentsId": null,
                    "menuFooterWebs": [
                        {
                            "id": 5,
                            "fullName": "Wallet",
                            "details": null,
                            "link": "#",
                            "index": 5,
                            "target": false,
                            "parentsId": 1,
                            "menuFooterWebs": null
                        },
                        {
                            "id": 6,
                            "fullName": "Explorer",
                            "details": null,
                            "link": "#",
                            "index": 6,
                            "target": false,
                            "parentsId": 1,
                            "menuFooterWebs": null
                        },
                        {
                            "id": 7,
                            "fullName": "Exchange",
                            "details": null,
                            "link": "#",
                            "index": 7,
                            "target": false,
                            "parentsId": 1,
                            "menuFooterWebs": null
                        }
                    ]
                },
                {
                    "id": 2,
                    "fullName": "Masternode Pools",
                    "details": null,
                    "link": "#",
                    "index": 2,
                    "target": false,
                    "parentsId": null,
                    "menuFooterWebs": [
                        {
                            "id": 9,
                            "fullName": "Become masternode",
                            "details": null,
                            "link": "#",
                            "index": 9,
                            "target": false,
                            "parentsId": 2,
                            "menuFooterWebs": null
                        },
                        {
                            "id": 10,
                            "fullName": "Staking",
                            "details": null,
                            "link": "#",
                            "index": 10,
                            "target": false,
                            "parentsId": 2,
                            "menuFooterWebs": null
                        },
                        {
                            "id": 11,
                            "fullName": "API documnet",
                            "details": null,
                            "link": "/support",
                            "index": 11,
                            "target": false,
                            "parentsId": 2,
                            "menuFooterWebs": null
                        }
                    ]
                },
                {
                    "id": 3,
                    "fullName": "Support",
                    "details": null,
                    "link": "#",
                    "index": 3,
                    "target": false,
                    "parentsId": null,
                    "menuFooterWebs": [
                        {
                            "id": 14,
                            "fullName": "Getting started",
                            "details": null,
                            "link": "#",
                            "index": 14,
                            "target": false,
                            "parentsId": 3,
                            "menuFooterWebs": null
                        },
                        {
                            "id": 15,
                            "fullName": "Tickets",
                            "details": null,
                            "link": "#",
                            "index": 15,
                            "target": false,
                            "parentsId": 3,
                            "menuFooterWebs": null
                        },
                        {
                            "id": 16,
                            "fullName": "Knowledge base",
                            "details": null,
                            "link": "#",
                            "index": 16,
                            "target": false,
                            "parentsId": 3,
                            "menuFooterWebs": null
                        }
                    ]
                },
                {
                    "id": 4,
                    "fullName": "Company",
                    "details": null,
                    "link": "#",
                    "index": 4,
                    "target": false,
                    "parentsId": null,
                    "menuFooterWebs": [
                        {
                            "id": 20,
                            "fullName": "Term of services",
                            "details": null,
                            "link": "#",
                            "index": 20,
                            "target": true,
                            "parentsId": 4,
                            "menuFooterWebs": null
                        },
                        {
                            "id": 20,
                            "fullName": "Privacy policy",
                            "details": null,
                            "link": "#",
                            "index": 20,
                            "target": true,
                            "parentsId": 4,
                            "menuFooterWebs": null
                        },
                        {
                            "id": 20,
                            "fullName": "Teams",
                            "details": null,
                            "link": "#",
                            "index": 20,
                            "target": true,
                            "parentsId": 4,
                            "menuFooterWebs": null
                        }
                    ]
                }
            ],
            dataCopyright: {
                value: "myFintegri"
            },
            y: 0,
            listFeatured: [
                {
                    imgPC: require('../../../assets/images/img04.jpg'),
                    imgMobile: require('../../../assets/images/img04.jpg'),
                    title: 'RETAIL / RESTAURANT',
                    text: 'Keep your business moving with instant broadband connectivity for speedy transactions.'
                },
                {
                    imgPC: require('../../../assets/images/img03.png'),
                    imgMobile: require('../../../assets/images/img03.png'),
                    title: 'MOBILE',
                    text: 'Accept payments out in the field with the iPayment MobilePay Swipe and mobile application.'
                },
                {
                    imgPC: require('../../../assets/images/img02.png'),
                    imgMobile: require('../../../assets/images/img02.png'),
                    title: 'POINT OF SALE',
                    text: 'The Clover® Suite has a versatile and scalable Point-of-Sale system for every business type.'
                },
                {
                    imgPC: require('../../../assets/images/img01.JPG'),
                    imgMobile: require('../../../assets/images/img01web.png'),
                    title: 'E-COMMERCE',
                    text: 'Accept credit cards online through your website. Our solutions are designed to save time and money.'
                }
            ]
        }
    }
    _handleCheckWindowSize() {
        let width = window.innerWidth;
        this.setState({
            isMoble: false
        }, () => {
            this.setState({
                isMoble: width < 768
            })
        })

    }

    // getMenu() {
    //     get('web/api/menu-footer',
    //         result => {
    //             this.setState({
    //                 menuFooter: result
    //             })
    //         },
    //         BASE_API
    //     )
    // }

    // getCopyright() {
    //     get('web/api/copyright',
    //         result => {
    //             this.setState({
    //                 dataCopyright: result
    //             }, () => {
    //                 this.setState({
    //                     loadPage: false
    //                 });
    //             });
    //         }, () => { },
    //         BASE_API
    //     )
    // }


    componentDidMount() {
        this._handleCheckWindowSize();
        window.addEventListener("resize", () => {
            this._handleCheckWindowSize()
        })
        $(window).scroll(() => this.setState({ y: $(window).scrollTop() }));
        window.$('.content-featured .item-featured').matchHeight();
        // this.getCopyright();
        // this.getMenu()
    }
    render() {
        let { isMoble, dataCopyright, menuFooter, activeBoxPhone, y, listFeatured } = this.state
        const settings = {
            dots: true,
            infinite: true,
            slidesToShow: isMoble ? 1 : 2,
            slidesToScroll: 1,
            autoplay: false,
            speed: 2000,
            autoplaySpeed: 2000,
            cssEase: "linear"
        };

        return (
            <div className={"footer" + (isMoble ? ' responsive-footer' : '')}>

                <div id="featured-section">
                    <ul className="content-featured clearfix">
                        {
                            listFeatured && listFeatured.map((item, index) =>
                                <li key={index} className="item-featured">
                                    <a href="#">
                                        <div className='box-img'>
                                            <img className="img-item" src={isMoble ? item.imgMobile : item.imgPC}></img>
                                        </div>
                                        <span className="text-title">{item.title}</span>
                                    </a>
                                    <p className="text-detail">{item.text}</p>
                                </li>
                            )
                        }
                    </ul>
                </div>
                <div id="comment-star">
                    <div className="content-slide">
                        <h2 className="title">OVER 1,000 SATISFIED BUSINESSES USE US</h2>
                        <Slider {...settings} className="slide-star">
                            <div className="testimonial-inner">
                                <div className="testimonial-client">
                                    <ul className="strong-rating-wrapper">
                                        <li className="star">
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                        </li>
                                    </ul>
                                    <div className="testimonial-content">
                                        <p>At first, I was hesitating to contact merchantpossolutions.com when I saw them online.  It took me less than a minute to fill out the request form and the rep call me. To make story short it was the best form I ever filled out.</p>
                                    </div>
                                    <p className="testimonial-name">Jessica P.</p>
                                </div>
                            </div>
                            <div className="testimonial-inner">
                                <div className="testimonial-client">
                                    <ul className="strong-rating-wrapper">
                                        <li className="star">
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                        </li>
                                    </ul>
                                    <p className="testimonial-content">Fast and easy to started with and I saved a lots of money too.  Thank you very very much.</p>
                                    <p className="testimonial-name">Bridget D.</p>
                                </div>
                            </div>
                            <div className="testimonial-inner">
                                <div className="testimonial-client">
                                    <ul className="strong-rating-wrapper">
                                        <li className="star">
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                        </li>
                                    </ul>
                                    <p className="testimonial-content">At first, I was hesitating to contact merchantpossolutions.com when I saw them online.  It took me less than a minute to fill out the request form and the rep call me. To make story short it was the best form I ever filled out.</p>
                                    <p className="testimonial-name">Jessica P.</p>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>
                <div className="thump">
                    <ul className="content-thumb ">
                        {/* <img src={require('../../../assets/images/thumbs.png')}></img> */}
                        <li>Easy Application <i className="fas fa-thumbs-up"></i></li>
                        <li>High Approval rating <i className="fas fa-thumbs-up"></i></li>
                        <li>Same Day Funting<i className="fas fa-thumbs-up"></i></li>

                    </ul>
                </div>
                <div className="flying_footer clearfix" style={{ position: ((y > 1400) ? "relative" : "fixed") }}>
                    <div className="left">
                        <ul className="list-icon">
                            <li className={"phone" + (activeBoxPhone ? ' active' : '')} onClick={() => this.setState({ activeBoxPhone: !activeBoxPhone })}>
                                <i class="fa fa-phone"></i>
                                <div className="phone-box">
                                    <p className="title">Phone</p>
                                    <p className="message">Call now to save</p>
                                    <div className="contact">
                                        <div className="contact-item">
                                            <span className="name"> United States </span>
                                            <span className="phone"> 210-607-8888 </span>
                                        </div>
                                    </div>

                                </div>
                            </li>
                            <li className="chat">
                                <i class="fa fa-comments"></i>
                            </li>
                        </ul>
                    </div>
                    <div className="right">
                        <a className="component menu_parent" aria-current="false" href="#">Save Money Now</a>
                    </div>
                </div>
            </div >

        );
    }
}

export default Index;
import React from "react";
import { NavLink } from "react-router-dom";
import { FILE_UPLOAD_WEB_CONTENT, BASE_API } from "../../../../constants/appSettings";
import { get, post } from "../../../../api/index";
import { isMobile } from "../../../../utils/app";
import Collapsible from "react-collapsible";
import { createSlugName } from "../../../../utils/common";
const TAKE = 10;

class Index extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loadPage: true,
            statusText: "Waiting...",
            articles: [],
        }
    }

    getArticlesSlide() {
        get('web/get-banner',
            result => {
                this.setState({
                    articles: result.banners,
                    statusText: "No record found"
                }, () => {
                    this.setState({
                        loadPage: false
                    });
                });
            }, () => { },
            BASE_API
        )
    }

    componentDidMount() {
        this.getArticlesSlide();
    }

    render() {
        if (isMobile()) return this.renderMobile();
        return this.renderDesktop();
    }

    renderMobile() {
        const {
            articles,
            statusText
        } = this.state;
        const { articleSlide } = this.props;

        return (
            <section className="section-articles collapsible-sidebar">
                <div className="accordion">
                    <Collapsible
                        trigger="Articles in this section">
                        <ul>
                            {
                                articles && articles.length > 0
                                    ?
                                    articles.map((item, i) =>
                                        <li key={i}>
                                            <NavLink to={"/articles-slide/" + createSlugName(item.title) + "/" + item.id} className={"sidenav-item" + (articleSlide === item.id ? ' current-article' : '')}>
                                                <span className="text-summary text-row-3">{item.title}</span>
                                            </NavLink>
                                        </li>
                                    )
                                    :
                                    <li>
                                        <h1 className="text-center">{statusText}</h1>
                                    </li>
                            }

                        </ul>
                        <NavLink to="/articles-slide" className="article-sidebar-item">See more</NavLink>
                    </Collapsible>
                </div>
            </section>
        );
    }

    renderDesktop() {
        const {
            articles,
            statusText
        } = this.state;
        const { articleSlide } = this.props;

        return (
            <section className="section-articles collapsible-sidebar">
                <h3 className="collapsible-sidebar-title sidenav-title">Articles in this section</h3>
                <ul>
                    {
                        articles.length > 0
                            ?
                            articles.map((item, i) =>
                                <li key={i}>
                                    <NavLink to={"/articles-slide/" + createSlugName(item.description) + "/" + item.id} className={"sidenav-item" + (articleSlide === item.id ? ' current-article' : '')}><span className="text-summary text-row-3">{item.description}</span></NavLink>
                                </li>
                            )
                            :
                            <li>
                                <h1 className="text-center">{statusText}</h1>
                            </li>
                    }

                </ul>
                <NavLink to="/articles-slide" className="article-sidebar-item">See more</NavLink>
            </section>
        );
    }
}

export default Index;

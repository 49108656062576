

const mustNotEmpty = (value, element, name) => {
    var valid = !isEmpty(value);
    if (valid) {
        removeError(name);
    } else {
        showError(name + " is not empty", element, name);
    }
    return valid;
};
export const validate = (elements, successCallback) => {
    var valid = true;
    for (let i = 0; i < elements.length; i++) {
        const element = elements[i];
        var name = element.getAttribute("name");
        var value = element.value;
        var validatorsAstring = element.getAttribute("validators");
        var validators = validatorsAstring.split(";");
        for (let j = 0; j < validators.length; j++) {
            const validator = validators[j];
            if (validator.indexOf("not-empty") >= 0) {
                if (!mustNotEmpty(value, element, name)) valid = false;
            } else if (validator.indexOf("email") >= 0) {
                if (!mustIsEmail(value, element, name)) valid = false;
            } else if (validator.indexOf("length(") >= 0) {
                if (!mustLength(value, validator, element, name)) valid = false;
            } else if (validator.indexOf("match(") >= 0) {
                if (!mustMatch(value, validator, element, name)) valid = false;
            } else if (validator.indexOf("password") >= 0) {
                if (!password(value, validator, element, name)) valid = false;
            }
        }
    }
    if (valid) successCallback();
};

const isEmpty = value => {
    if (value) {
        if (value === "") return true;
        if (value.trim() === "") return true;
        return false;
    } else {
        return true;
    }
};

const mustIsEmail = (email, element, name) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var valid = re.test(String(email).toLowerCase());
    if (valid) {
        removeError(name);
    } else {
        showError("Email is not valid", element, name);
    }
    return valid;
};

const mustLength = (value, validator, element, name) => {
    if (isEmpty(value)) return;
    var lengths = validator
        .replace("length(", "")
        .replace(")", "")
        .split(",");
    var min = lengths[0];
    var max = lengths[1];

    var valid = value.length <= max && value.length >= min;
    if (valid) {
        removeError(name);
    } else {
        showError(
            name + " is from " + min + " to " + max + " characters",
            element,
            name
        );
    }
    return valid;
};


const mustMatch = (value, validator, element, name) => {
    if (isEmpty(value)) return;
    var mustMatchName = validator.replace("match(", "").replace(")", "");
    var value1 = document.getElementsByName(mustMatchName)[0].value;

    var valid = value == value1;
    if (valid) {
        removeError(name);
    } else {
        showError(name + " is not match with " + mustMatchName, element, name);
    }
    return valid;
};

const showError = (message, htmlElement, name) => {
    var errorElId = name + "-error";
    if (document.getElementById(errorElId) == null) {
        var errorElement = document.createElement("span");
        errorElement.className = "text-danger";
        errorElement.innerHTML = message;
        errorElement.id = errorElId;
        htmlElement.closest(".ct-filed").after(errorElement);
    }
};
const removeError = name => {
    var errorElId = name + "-error";
    var errorElement = document.getElementById(errorElId);
    if (errorElement) errorElement.remove();
};
export const notEmpty = (entity, value) => {
    if (!value || !value.trim())
        return entity + " is require. Please do not leave blank"
    return ""
}

export const emailAddress = (entity, value) => {
    if (value && !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value))
        return "Please enter the correct email format. Example: example@gmail.com"
    return ""
}

export const urlFormat = (entity, value) => {
    if (value && !/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g.test(value))
        return "Vui lòng nhập đầy đủ đường dẫn website."
    return ""
}

export const justNumber = (entity, value) => {
    if (value && !/^[0-9.,]*$/g.test(value))
        return entity + " must be a number."
    return ""
}

export const password = (entity, value) => {
    if (value && !/^(?=.*[A-Za-z*!@#$%^&()])(?=.*\d)[A-Za-z*!@#$%^&()\d]{8,}$/g.test(value))
        return entity + " must be minimum eight characters, at least one letter and one number."
    return ""
}

export const isValid = (validationData) => {
    var fieldNames = Object.keys(validationData)
    let valid = true
    for (let i = 0; i < fieldNames.length; i++) {
        const fieldName = fieldNames[i]
        const validations = validationData[fieldName]
        let errors = ""
        if (validations) {
            var element = document.getElementById(fieldName)
            for (let i = 0; i < validations.length; i++) {
                const validate = validations[i]
                if (element) {
                    var error = validate(element.getAttribute("label"), element.value)
                    if (error) errors += "\n" + error
                }
            }
            var errorElement = document.getElementById("validator-for-" + fieldName)
            if (errorElement) {
                errorElement.innerHTML = errors
            }
            if (element) {
                if (errors)
                    element.style.border = "1px solid #E46B50"
                else
                    element.style.border = "1px solid #ccc"
            }
            if (errors)
                valid = false
        }
    }
    return valid
}
// export const BASE_API = "https://foresterx-api.kryptoniex.com/";
// export const DATETIME_FORMAT = "MM/DD/YYYY HH:mm";
// export const DOMAIN = "https://backoffice.myfintegri.com/";

export const FILE_UPLOAD_WEB_CONTENT = "https://content-admin.foresterx.com/";


export const BASE_API = "http://45.119.82.18:9602/";
export const DATETIME_FORMAT = "MM/DD/YYYY HH:mm";
export const DOMAIN = "https://backoffice.myfintegri.com/";



export const TIME_FORMAT = "HH:mm:ss";
export const CAPTCHA_KEY = "6Lcd_HYUAAAAAMs4TRpiP7OhhOIkHPCNvEFV9fhr";
export const DEFAULT_RESOLUTION = "15";
export const DATE_FORMAT = "MM/DD/YYYY";

export const EMAIL = "EMAIL";

import React from "react";
import { Link } from "react-router-dom";
import '../../../../assets/common.scss';
import Slider from "react-slick";
import { createSlugName } from "../../../../utils/common";


class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }


    _handleCheckWindowSize() {
        let width = window.innerWidth;
        this.setState({
            isMoble: false
        }, () => {
            this.setState({
                isMoble: width < 768
            })
        })

    }
    componentDidMount() {
        this._handleCheckWindowSize();
        window.addEventListener("resize", () => {
            this._handleCheckWindowSize()
        })

    }
    render() {
        const settings = {
            dots: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            speed: 1000,
            autoplaySpeed: 10000,
            cssEase: "linear"
        };
        let { listAbout } = this.props
        console.log(listAbout)
        return (
            <div id="indexAbout" class="carousel slide" data-ride="carousel">
                <Slider {...settings}>

                    {listAbout && listAbout.length > 0 &&
                        listAbout.map((item, index) =>
                            <div className="content-about" >
                                <div key={index} className="item  item-indexAbout1 index-about" >
                                    <Link to={"/Post/" + item.name} className="about-item">
                                        <div className="img-item">
                                            <div className="content-img">
                                                <img src={item.img} />
                                            </div>
                                        </div>
                                        <div className="title-item">{item.title}</div>
                                        <div className="desc-item">{item.desc}</div>
                                        <div className="btn-item">
                                            <span href={"/Post/" + item.name} className="btn">Learn more</span>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        )}
                </Slider>
            </div >
        );
    }
}

export default Index;

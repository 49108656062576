import React from 'react';
import $ from "jquery";

import './style/style.css';

export class Alerts extends React.Component {

    constructor(props) {
        super(props);
    };

    componentDidMount() {

    };

    render() {
        return (
            <div className={"alerts alert-" + this.props.status}>
                <button type="button" className="alert-close">×</button>
                <h4 className="alerts-title">
                    <i className={this.props.icon}/>
                    {this.props.title}
                </h4>
                <div className="alerts-message">
                    <p>{this.props.message}</p>
                </div>
            </div>
        )
    }
}

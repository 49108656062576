import React from "react";
import { Link } from "react-router-dom";
import "./CSS/style.scss";
import $ from "jquery";
import '../../../assets/style/common.scss';
import { BASE_API } from "../../../constants/appSettings";
import { get, post } from "../../../api/index";
import { createSlugName } from "../../../utils/common";
import Slider from "react-slick";
import DownPaneSlide from "../../home/downpane-slide";

class Footer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            activeFooter: null,
            activefooter1: false,
            openAbout: false,
            openDk: false,
            isMoble: false,
            dataInfo: null,
            menuFooter: [
                {
                    "id": 1,
                    "fullName": "Company",
                    "details": null,
                    "link": "#",
                    "index": 1,
                    "target": false,
                    "parentsId": null,
                    "menuFooterWebs": [
                        {
                            "id": 5,
                            "fullName": "About us",
                            "details": null,
                            "link": "#",
                            "index": 5,
                            "target": false,
                            "parentsId": 1,
                            "menuFooterWebs": null
                        },
                        {
                            "id": 6,
                            "fullName": "Become a partner",
                            "details": null,
                            "link": "#",
                            "index": 6,
                            "target": false,
                            "parentsId": 1,
                            "menuFooterWebs": null
                        }
                    ]
                },
                // {
                //     "id": 2,
                //     "fullName": "Services",
                //     "details": null,
                //     "link": "#",
                //     "index": 2,
                //     "target": false,
                //     "parentsId": null,
                //     "menuFooterWebs": [
                //         {
                //             "id": 9,
                //             "fullName": "Cash Discount",
                //             "details": null,
                //             "link": "#",
                //             "index": 9,
                //             "target": false,
                //             "parentsId": 2,
                //             "menuFooterWebs": null
                //         },
                //         {
                //             "id": 10,
                //             "fullName": "Credit Surcharge",
                //             "details": null,
                //             "link": "#",
                //             "index": 10,
                //             "target": false,
                //             "parentsId": 2,
                //             "menuFooterWebs": null
                //         },
                //         {
                //             "id": 11,
                //             "fullName": "Traditional Processing",
                //             "details": null,
                //             "link": "/support",
                //             "index": 11,
                //             "target": false,
                //             "parentsId": 2,
                //             "menuFooterWebs": null
                //         },
                //         {
                //             "id": 12,
                //             "fullName": "Capital",
                //             "details": null,
                //             "link": "/support",
                //             "index": 11,
                //             "target": false,
                //             "parentsId": 2,
                //             "menuFooterWebs": null
                //         }
                //     ]
                // },
                {
                    "id": 3,
                    "fullName": "Equipment",
                    "details": null,
                    "link": "#",
                    "index": 3,
                    "target": false,
                    "parentsId": null,
                    "menuFooterWebs": [
                        // {
                        //     "id": 14,
                        //     "fullName": "Standalone terminals",
                        //     "details": null,
                        //     "link": "#",
                        //     "index": 14,
                        //     "target": false,
                        //     "parentsId": 3,
                        //     "menuFooterWebs": null
                        // },
                        {
                            "id": 15,
                            "fullName": "Clover POS Family",
                            "details": null,
                            "link": "#",
                            "index": 15,
                            "target": false,
                            "parentsId": 3,
                            "menuFooterWebs": null
                        },

                        // {
                        //     "id": 16,
                        //     "fullName": "Slice POS",
                        //     "details": null,
                        //     "link": "#",
                        //     "index": 16,
                        //     "target": false,
                        //     "parentsId": 3,
                        //     "menuFooterWebs": null
                        // },
                        // {
                        //     "id": 17,
                        //     "fullName": "Aldelo POS",
                        //     "details": null,
                        //     "link": "#",
                        //     "index": 16,
                        //     "target": false,
                        //     "parentsId": 3,
                        //     "menuFooterWebs": null
                        // },
                        // {
                        //     "id": 18,
                        //     "fullName": "Paradise POS",
                        //     "details": null,
                        //     "link": "#",
                        //     "index": 16,
                        //     "target": false,
                        //     "parentsId": 3,
                        //     "menuFooterWebs": null
                        // }
                        {
                            "id": 19,
                            "fullName": "Nails Solutions POS",
                            "details": null,
                            "link": "#",
                            "index": 15,
                            "target": false,
                            "parentsId": 3,
                            "menuFooterWebs": null
                        },
                    ]
                },
                {
                    "id": 4,
                    "fullName": "Resources",
                    "details": null,
                    "link": "#",
                    "index": 4,
                    "target": false,
                    "parentsId": null,
                    "menuFooterWebs": [
                        {
                            "id": 20,
                            "fullName": "Support",
                            "details": null,
                            "link": "#",
                            "index": 20,
                            "target": true,
                            "parentsId": 4,
                            "menuFooterWebs": null
                        },
                        {
                            "id": 20,
                            "fullName": "FAQs",
                            "details": null,
                            "link": "#",
                            "index": 20,
                            "target": true,
                            "parentsId": 4,
                            "menuFooterWebs": null
                        },
                        {
                            "id": 20,
                            "fullName": "Contact us",
                            "details": null,
                            "link": "/contact-us",
                            "index": 20,
                            "target": false,
                            "parentsId": 4,
                            "menuFooterWebs": null
                        },
                        {
                            "id": 20,
                            "fullName": "State Regulations",
                            "details": null,
                            "link": "#",
                            "index": 20,
                            "target": true,
                            "parentsId": 4,
                            "menuFooterWebs": null
                        }
                    ]
                }
            ],
            dataCopyright: {
                value: "myFintegri"
            },
            y: 0,

        }
    }

    _handleCheckWindowSize() {
        let width = window.innerWidth;
        this.setState({
            isMoble: false
        }, () => {
            this.setState({
                isMoble: width < 768
            })
        })

    }

    // getMenu() {
    //     get('web/api/menu-footer',
    //         result => {
    //             this.setState({
    //                 menuFooter: result
    //             })
    //         },
    //         BASE_API
    //     )
    // }

    // getCopyright() {
    //     get('web/api/copyright',
    //         result => {
    //             this.setState({
    //                 dataCopyright: result
    //             }, () => {
    //                 this.setState({
    //                     loadPage: false
    //                 });
    //             });
    //         }, () => { },
    //         BASE_API
    //     )
    // }

    componentDidMount() {
        this._handleCheckWindowSize();
        window.addEventListener("resize", () => {
            this._handleCheckWindowSize()
        })
        $(window).scroll(() => this.setState({ y: $(window).scrollTop() }));
        // this.getCopyright();
        // this.getMenu()
    }
    render() {
        let { isMoble, dataCopyright, menuFooter, activeBoxPhone, y, listFeatured, activeFooter, activefooter1 } = this.state
        const settings = {
            dots: true,
            infinite: true,
            slidesToShow: isMoble ? 1 : 2,
            slidesToScroll: 1,
            autoplay: false,
            speed: 2000,
            autoplaySpeed: 2000,
            cssEase: "linear"
        };
        return (
            <div className={"footer" + (isMoble ? ' responsive-footer' : '')}>
                <div className="footer-content">
                    <div className="nav-info">
                        <ul className="nav-items" >
                            {
                                menuFooter && menuFooter.map((item, index) =>
                                    <li className={"item-fc" + (isMoble && activeFooter == index ? ' active-sub' : '')} key={index}
                                        onClick={() => this.setState({ activeFooter: index == activeFooter ? null : index })}
                                    >
                                        <p className="nav-title">{item.fullName}</p>
                                        <div className="nav-text">
                                            {
                                                item.menuFooterWebs && item.menuFooterWebs.length > 0 &&
                                                item.menuFooterWebs.map((item_a, j) =>
                                                    <p className="ctt-text" key={j}>
                                                        {
                                                            item_a.link == '#' ?
                                                                <Link to={item.link}>{item_a.fullName}</Link>
                                                                :
                                                                item_a.target ?
                                                                    <a target="_blank" href={item_a.link}>{item_a.fullName}</a>
                                                                    :
                                                                    <Link to={item_a.link}>{item_a.fullName}</Link>
                                                        }
                                                    </p>
                                                )}
                                        </div>
                                    </li>
                                )
                            }
                        </ul>
                    </div>
                    <div className="site-desc">
                        <div className="wrapper-left">
                            <ul className="icon-list">
                                <li className="icon">
                                    <a href="https://www.google.com/"><i class="fab fa-facebook-f"></i></a>
                                </li>
                                <li className="icon">
                                    <a href="google.com"><i class="fab fa-twitter"></i></a>
                                </li>
                            </ul>
                        </div>
                        {/* <div className="wrapper-right">
                            <span className="copy-right">{dataCopyright && dataCopyright.value}</span>
                        </div> */}
                    </div>
                </div>
            </div >

        );
    }
}

export default Footer;
import React from "react";
import './style/style.css';
import './style/style-mibile.css';
import { isMobile, putInProcess, showError, showInfo, takeOutProcess } from "../../../utils/app";
import { NavLink } from "react-router-dom";
import { validate } from "../../../utils/validators";
import { post } from "../../../api";
import { BASE_API } from "../../../constants/appSettings";

class Index extends React.Component {
    constructor() {
        super();
    }

    render() {
        if (isMobile()) return this.renderMobile();
        return this.renderDesktop();
    }

    submitForm = () => {
        let refs = this.refs;
        validate(
            [
                refs.email,
                refs.nameOfTheToken,
                refs.symbolOfTheToken,
                refs.precision,
                refs.tokenAddress,
                refs.websiteOfTheToken,
                refs.linkVerified,
                refs.announcementLink,
            ],
            () => {
                var tokenPayFalse = refs.tokenPayFalse.checked;
                var tokenPayTrue = refs.tokenPayTrue.checked;
                if (!tokenPayFalse && !tokenPayTrue) {
                    showError("Token pay checked");
                    return;
                }
                putInProcess(refs.btnSubmit);

                var param = {
                    "email": refs.email.value,
                    "nameOfTheToken": refs.nameOfTheToken.value,
                    "symbolOfTheToken": refs.symbolOfTheToken.value,
                    "precision": refs.precision.value,
                    "tokenAddress": refs.tokenAddress.value,
                    "websiteOfTheToken": refs.websiteOfTheToken.value,
                    "linkVerified": refs.linkVerified.value,
                    "announcementLink": refs.announcementLink.value,
                    "tokenPay": tokenPayFalse ? "false" : "true"
                };

                post("web/list-coin/submit", param,
                    () => {
                        refs.email.value = "";
                        refs.nameOfTheToken.value = "";
                        refs.symbolOfTheToken.value = "";
                        refs.precision.value = "";
                        refs.tokenAddress.value = "";
                        refs.websiteOfTheToken.value = "";
                        refs.linkVerified.value = "";
                        refs.announcementLink.value = "";

                        showInfo("Success");
                        takeOutProcess(refs.btnSubmit);
                    },
                    () => {
                        takeOutProcess(refs.btnSubmit);
                    },
                    BASE_API
                );
            }
        );
    };

    renderMobile() {
        return (
            <div className="components-mobile">
                <div className="list-coin-page">
                    <nav className="sub-nav">
                        <ol className="breadcrumbs">
                            <li>
                                <NavLink to="/">Home</NavLink>
                            </li>
                            <li>
                                <NavLink to="/support">Support</NavLink>
                            </li>
                            <li>
                                <NavLink to="/support">List coin</NavLink>
                            </li>
                        </ol>
                    </nav>
                    <div className="form-list-coin">
                        <form>
                            <h3 className="title">Token Request</h3>
                            <p>*Important! Read the listing guidelines before submitting.*<br />*Reminder: Foresterx does NOT charge a fee to be considered or listed. If anyone tries to charge you a listing fee, please contact our support.*</p>
                            <p style={{ marginTop: "1em", marginBottom: "1em" }}>Listing Guidlines: <a href="https://drive.google.com/open?id=1WYkB8cnT3hU76DIXs61hl7qeEbLWZ-2b" target="_blank" style={{ color: '#0894ec' }}>https://drive.google.com/open?id=1WYkB8cnT3hU76DIXs61hl7qeEbLWZ-2b</a></p>
                            <p>Please submit new token requests here. Duplicate requests from the same user will be deleted. We will consider all requests on a first come, first served basis. Thank you</p>
                            <p className="text-required">*Required</p>

                            <div className="mb15">
                                <p className="mb05"><b>Email address</b> <span className="text-required">*</span></p>
                                <div className="filed">
                                    <div className="ct-filed">
                                        <label className="text-layout-2">
                                            <input
                                                type="text"
                                                ref="email"
                                                name="Email"
                                                validators="email"
                                            />
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="mb15">
                                <p className="mb05"><b>Name of the token</b> <span className="text-required">*</span></p>
                                <div className="filed">
                                    <div className="ct-filed">
                                        <label className="text-layout-2">
                                            <input
                                                type="text"
                                                ref="nameOfTheToken"
                                                name="Name of the token"
                                                validators="not-empty"
                                            />
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="mb15">
                                <p className="mb05"><b>Symbol of the token</b> <span className="text-required">*</span></p>
                                <div className="filed">
                                    <div className="ct-filed">
                                        <label className="text-layout-2">
                                            <input
                                                type="text"
                                                ref="symbolOfTheToken"
                                                name="Symbol of the token"
                                                validators="not-empty"
                                            />
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="mb15">
                                <p className="mb05"><b>Precision (decimals)</b> <span className="text-required">*</span></p>
                                <p className="mb05"><i>Please enter the number of decimals that are in the token.</i></p>
                                <div className="filed">
                                    <div className="ct-filed">
                                        <label className="text-layout-2">
                                            <input
                                                type="text"
                                                ref="precision"
                                                name="Precision"
                                                validators="not-empty"
                                            />
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="mb15">
                                <p className="mb05"><b>Token address</b> <span className="text-required">*</span></p>
                                <p className="mb05"><i>Please enter the address of the token contract.</i></p>
                                <div className="filed">
                                    <div className="ct-filed">
                                        <label className="text-layout-2">
                                            <input
                                                type="text"
                                                ref="tokenAddress"
                                                name="Token address"
                                                validators="not-empty"
                                            />
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="mb15">
                                <p className="mb05"><b>Website of the token</b> <span className="text-required">*</span></p>
                                <p className="mb05"><i>Please enter the primary website for the token.</i></p>
                                <div className="filed">
                                    <div className="ct-filed">
                                        <label className="text-layout-2">
                                            <input
                                                type="text"
                                                ref="websiteOfTheToken"
                                                name="Website of the token"
                                                validators="not-empty"
                                            />
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="mb15">
                                <p className="mb05"><b>Link to the website where token address can be verified</b> <span className="text-required">*</span></p>
                                <p className="mb05"><i>Tokens without details on the company/project website will not be considered due to the potential of counterfeit coins. Contact us if you have questions.</i></p>
                                <div className="filed">
                                    <div className="ct-filed">
                                        <label className="text-layout-2">
                                            <input
                                                type="text"
                                                ref="linkVerified"
                                                name="Link verified"
                                                validators="not-empty"
                                            />
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="mb15">
                                <p className="mb05"><b>Does the token pay dividends or guarantee a return of investment?</b> <span className="text-required">*</span></p>
                                <div className="filed">
                                    <div className="ct-filed">
                                        <label className="radio">
                                            <input
                                                type="radio"
                                                name="Token Pay"
                                                ref="tokenPayTrue"
                                                value="true"
                                            /> Yes
                                    </label>
                                    </div>
                                </div>
                                <div className="filed">
                                    <div className="ct-filed">
                                        <label className="radio">
                                            <input
                                                type="radio"
                                                name="Token Pay"
                                                ref="tokenPayFalse"
                                                value="false"
                                            /> No
                                    </label>
                                    </div>
                                </div>
                            </div>

                            <div className="mb15">
                                <p className="mb05"><b>Announcement forum thread link</b> <span className="text-required">*</span></p>
                                <p className="mb05"><i>Please link to the announcement thread for the asset. Example: <a href="" target="_blank" style={{ textDecoration: 'underline', color: '#0894ec' }}>https://test.com</a></i></p>
                                <div className="filed">
                                    <div className="ct-filed">
                                        <label className="text-layout-2">
                                            <input
                                                type="text"
                                                ref="announcementLink"
                                                name="Announcement forum thread link"
                                                validators="not-empty"
                                            />
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="clear-fix filed">
                                <input
                                    className="btn btn-default bg-orange pull-left"
                                    type="button"
                                    value="Submit"
                                    ref="btnSubmit"
                                    onClick={this.submitForm}
                                />
                                <div className="lds-facebook">
                                    <div />
                                    <div />
                                    <div />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    renderDesktop() {
        return (
            <div className="components-pc">
                <div className="content-main list-coin-page">
                    <div className="container">
                        <nav className="sub-nav">
                            <ol className="breadcrumbs">
                                <li>
                                    <NavLink to="/">Home</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/support">Support</NavLink>
                                </li>
                                <li>
                                    <a>List coin</a>
                                </li>
                            </ol>
                        </nav>
                        <div className="form-list-coin">
                            <form>
                                <h3 className="title">Token Request</h3>
                                <p>*Important! Read the listing guidelines before submitting.*<br />*Reminder: Foresterx does NOT charge a fee to be considered or listed. If anyone tries to charge you a listing fee, please contact our support.*</p>
                                <p style={{ marginTop: "1em", marginBottom: "1em" }}>Listing Guidlines: <a href="https://drive.google.com/open?id=1WYkB8cnT3hU76DIXs61hl7qeEbLWZ-2b" target="_blank" style={{ color: '#0894ec' }}>https://drive.google.com/open?id=1WYkB8cnT3hU76DIXs61hl7qeEbLWZ-2b</a></p>
                                <p>Please submit new token requests here. Duplicate requests from the same user will be deleted. We will consider all requests on a first come, first served basis. Thank you</p>
                                <p className="text-required">*Required</p>

                                <div className="mb15">
                                    <p className="mb05"><b>Email address</b> <span className="text-required">*</span></p>
                                    <div className="filed">
                                        <div className="ct-filed">
                                            <label className="text-layout-2">
                                                <input
                                                    type="text"
                                                    ref="email"
                                                    name="Email"
                                                    validators="email"
                                                />
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div className="mb15">
                                    <p className="mb05"><b>Name of the token</b> <span className="text-required">*</span></p>
                                    <div className="filed">
                                        <div className="ct-filed">
                                            <label className="text-layout-2">
                                                <input
                                                    type="text"
                                                    ref="nameOfTheToken"
                                                    name="Name of the token"
                                                    validators="not-empty"
                                                />
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div className="mb15">
                                    <p className="mb05"><b>Symbol of the token</b> <span className="text-required">*</span></p>
                                    <div className="filed">
                                        <div className="ct-filed">
                                            <label className="text-layout-2">
                                                <input
                                                    type="text"
                                                    ref="symbolOfTheToken"
                                                    name="Symbol of the token"
                                                    validators="not-empty"
                                                />
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div className="mb15">
                                    <p className="mb05"><b>Precision (decimals)</b> <span className="text-required">*</span></p>
                                    <p className="mb05"><i>Please enter the number of decimals that are in the token.</i></p>
                                    <div className="filed">
                                        <div className="ct-filed">
                                            <label className="text-layout-2">
                                                <input
                                                    type="text"
                                                    ref="precision"
                                                    name="Precision"
                                                    validators="not-empty"
                                                />
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div className="mb15">
                                    <p className="mb05"><b>Token address</b> <span className="text-required">*</span></p>
                                    <p className="mb05"><i>Please enter the address of the token contract.</i></p>
                                    <div className="filed">
                                        <div className="ct-filed">
                                            <label className="text-layout-2">
                                                <input
                                                    type="text"
                                                    ref="tokenAddress"
                                                    name="Token address"
                                                    validators="not-empty"
                                                />
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div className="mb15">
                                    <p className="mb05"><b>Website of the token</b> <span className="text-required">*</span></p>
                                    <p className="mb05"><i>Please enter the primary website for the token.</i></p>
                                    <div className="filed">
                                        <div className="ct-filed">
                                            <label className="text-layout-2">
                                                <input
                                                    type="text"
                                                    ref="websiteOfTheToken"
                                                    name="Website of the token"
                                                    validators="not-empty"
                                                />
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div className="mb15">
                                    <p className="mb05"><b>Link to the website where token address can be verified</b> <span className="text-required">*</span></p>
                                    <p className="mb05"><i>Tokens without details on the company/project website will not be considered due to the potential of counterfeit coins. Contact us if you have questions.</i></p>
                                    <div className="filed">
                                        <div className="ct-filed">
                                            <label className="text-layout-2">
                                                <input
                                                    type="text"
                                                    ref="linkVerified"
                                                    name="Link verified"
                                                    validators="not-empty"
                                                />
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div className="mb15">
                                    <p className="mb05"><b>Does the token pay dividends or guarantee a return of investment?</b> <span className="text-required">*</span></p>
                                    <div className="filed">
                                        <div className="ct-filed">
                                            <label className="radio">
                                                <input
                                                    type="radio"
                                                    name="Token Pay"
                                                    ref="tokenPayTrue"
                                                    value="true"
                                                /> Yes
                                        </label>
                                        </div>
                                    </div>
                                    <div className="filed">
                                        <div className="ct-filed">
                                            <label className="radio">
                                                <input
                                                    type="radio"
                                                    name="Token Pay"
                                                    ref="tokenPayFalse"
                                                    value="false"
                                                /> No
                                        </label>
                                        </div>
                                    </div>
                                </div>

                                <div className="mb15">
                                    <p className="mb05"><b>Announcement forum thread link</b> <span className="text-required">*</span></p>
                                    <p className="mb05"><i>Please link to the announcement thread for the asset. Example: <a href="" target="_blank" style={{ textDecoration: 'underline', color: '#0894ec' }}>https://test.com</a></i></p>
                                    <div className="filed">
                                        <div className="ct-filed">
                                            <label className="text-layout-2">
                                                <input
                                                    type="text"
                                                    ref="announcementLink"
                                                    name="Announcement forum thread link"
                                                    validators="not-empty"
                                                />
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div className="clear-fix filed">
                                    <input
                                        className="btn btn-default bg-orange pull-left"
                                        type="button"
                                        value="Submit"
                                        ref="btnSubmit"
                                        onClick={this.submitForm}
                                    />
                                    <div className="lds-facebook">
                                        <div />
                                        <div />
                                        <div />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Index;

import React from "react";
import { Link } from "react-router-dom";
import './style.scss';
import '../../../assets/common.scss';


class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listImg: [

                {
                    img: require('../../../assets/images/transaction1.gif')
                },
                {
                    img: require('../../../assets/images/freeclover1-01.jpg')
                }
            ],
            isMoble: false
        };
    }




    _handleCheckWindowSize() {
        let width = window.innerWidth;
        this.setState({
            isMoble: false
        }, () => {
            this.setState({
                isMoble: width < 568
            })
        })

    }
    componentDidMount() {

        this._handleCheckWindowSize();
        window.addEventListener("resize", () => {
            this._handleCheckWindowSize()
        })

    }

    render() {
        const settings = {
            dots: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            speed: 1000,
            autoplaySpeed: 4000,
            cssEase: "linear"
        };
        let { isMoble, listImg } = this.state

        return (
            <div className="list-promotion">
                <ul className="content-l-pro">
                    {listImg && listImg.map((item, index) =>
                        <li key={index}>
                            <Link to='#'><img src={item.img} /></Link>
                        </li>
                    )}
                </ul>
                <div className='text-box-credit' style={{ width: '100%' }}>
                    <div style={{ marginBottom: '35px' }}>
                        <p><span><i class="fas fa-check"></i>FREE</span> EMV Terminal</p>
                        <p><span><i class="fas fa-check"></i>FREE</span> Smart Phone Reader</p>
                        <p><span><i class="fas fa-check"></i>FREE</span> Terminal Paper Supply</p>
                        <p><span><i class="fas fa-check"></i></span>No Application Fee</p>
                        <p><span><i class="fas fa-check"></i></span>No Long Terms Contact</p>
                        <p><span><i class="fas fa-check"></i></span>No Cancelation Fees</p>
                        <p><span><i class="fas fa-check"></i></span>Rate Start As Low As 0.10%</p>
                        <p><span><i class="fas fa-check"></i></span>POS CASH Register Systems</p>
                        <p><span><i class="fas fa-check"></i></span>Online Reporting and Sales Tracking</p>
                    </div>
                </div>

            </div>
        );
    }
}

export default Index;

import React from "react";
import { Route, Switch } from "react-router";

import Main from "./components/main";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Detail from "./components/Detail";


class App extends React.Component {
  render() {
    return (
      <div>
        <Switch>
          <Route exact path="/Post/:slug" component={Detail} />
          <Route exact path="/*" component={Main} />
        </Switch>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
          className="custom-toast"
        />
      </div>
    );
  }
}

export default App;

import React from "react";
import './style/style.css';
import './style/style-mobile.css';
import { post, get } from "../../../api";
import { BASE_API } from "../../../constants/appSettings";
import { isMobile } from "../../../utils/app";
import { NavLink } from "react-router-dom";
import { createSlugName, formatDate2 } from "../../../utils/common";
const TAKE = 10;

class Index extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loadPage: true,
            statusText: "Waiting...",
            data: [],
        }
    }

    getData() {
        var data = [];
        get('web/api/announcement',
            result => {
                this.setState({
                    data: result,
                    statusText: 'No record found'
                })
            }, () => { },
            BASE_API
        )
    }

    componentDidMount() {
        this.getData();
    }

    render() {
        if (isMobile()) return this.renderMobile();
        return this.renderDesktop();
    }

    renderMobile() {
        const {
            data,
            statusText
        } = this.state;

        return (
            <div className="components-mobile">
                <div className="faq-support">
                    <nav className="sub-nav">
                        <ol className="breadcrumbs">
                            <li>
                                <NavLink to="/">Home</NavLink>
                            </li>
                            <li>
                                <NavLink to="/support">Support</NavLink>
                            </li>
                            <li>
                                <a>Announcements</a>
                            </li>
                        </ol>

                        {/* <form className="search">
                            <i className="fas fa-search" />
                            <input type="search" placeholder="Search" />
                        </form> */}
                    </nav>
                    <div className="article-container">
                        <h1 className="collapsible-sidebar-title sidenav-title mb20">Announcements</h1>
                        <div className="list-section">
                            {
                                data && data.length > 0
                                    ?
                                    data.map((item, i) =>
                                        item.announcementPosts != null && item.announcementPosts.length > 0
                                        &&
                                        <div className="section new-listings-container mb50" key={i}>
                                            <h3 className="mb20 text-left">{item.fullName}</h3>
                                            <ul className="list-article clear-fix">
                                                {
                                                    item.announcementPosts.map((item_a, j) =>
                                                        <li className="recent-activity-item" key={j}>
                                                            <div>
                                                                <p className="recent-activity-item-link">
                                                                    <NavLink to={"/support/announcements/" + createSlugName(item_a.title) + "/" + item.id + "/" + item_a.id}>
                                                                        <span className="text-summary text-row-1">{item_a.title}</span>
                                                                    </NavLink>
                                                                </p>
                                                            </div>
                                                        </li>
                                                    )
                                                }
                                            </ul>
                                            {
                                                item.announcementPosts && item.announcementPosts.length > 0
                                                &&
                                                <div className="mt10 text-right">
                                                    <NavLink to={"/support/announcements/" + item.id} className="btn btn-default bg-orange">See all</NavLink>
                                                </div>
                                            }
                                        </div>
                                    )
                                    :
                                    <h3 className="text-center">{statusText}</h3>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderDesktop() {
        const {
            data,
            statusText
        } = this.state;
        return (
            <div className="components-pc">
                <div className="content-main faq-support">
                    <div className="container">
                        <nav className="sub-nav">
                            <ol className="breadcrumbs">
                                <li>
                                    <NavLink to="/">Home</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/support">Support</NavLink>
                                </li>
                                <li>
                                    <a>Announcements</a>
                                </li>
                            </ol>

                            {/* <form className="search">
                                <i className="fas fa-search" />
                                <input type="search" placeholder="Search" />
                            </form> */}
                        </nav>
                        <div className="article-container">
                            <h1 className="collapsible-sidebar-title sidenav-title mb20">Announcements</h1>
                            <div className="list-section">
                                {
                                    data && data.length > 0
                                        ?
                                        data.map((item, i) =>
                                            item.announcementPosts != null && item.announcementPosts.length > 0 &&
                                            <div className="section new-listings-container mb50" key={i}>
                                                <h3 className="mb20 text-left">{item.fullName}</h3>
                                                <ul className="list-article clear-fix">
                                                    {
                                                        item.announcementPosts.map((item_a, j) =>
                                                            <li className="recent-activity-item" key={j}>
                                                                <div>
                                                                    <p className="recent-activity-item-link">
                                                                        <NavLink to={"/support/announcements/" + createSlugName(item_a.title) + "/" + item.id + "/" + item_a.id}>
                                                                            <span className="text-summary text-row-1">{item_a.title}</span>
                                                                        </NavLink>
                                                                    </p>
                                                                </div>
                                                            </li>
                                                        )
                                                    }
                                                </ul>
                                                {
                                                    item.announcementPosts && item.announcementPosts.length > 0
                                                    &&
                                                    <div className="mt10 text-left">
                                                        <NavLink to={"/support/announcements/" + item.id} className="" style={{ color: '#f5c424', paddingLeft: '15px' }}>See all {item.sumAll} articles</NavLink>
                                                    </div>
                                                }
                                            </div>
                                        )
                                        :
                                        <h3 className="text-center">{statusText}</h3>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Index;

import React from "react";
import { NavLink } from "react-router-dom";
import { FILE_UPLOAD_WEB_CONTENT, BASE_API } from "../../../../constants/appSettings";
import { get, post } from "../../../../api/index";
import { isMobile } from "../../../../utils/app";
import { createSlugName } from "../../../../utils/common";
import Collapsible from "react-collapsible";
const TAKE = 10;

class Index extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loadPage: true,
            statusText: "Waiting...",
            news: [],
            total: 0,
            skip: 0,
        }
    }

    getNews() {
        var { skip, news } = this.state;

        let param = {};
        if (skip === 0) {
            param = {
                skip: 0,
                take: 10,
            };
        } else {
            param = {
                skip: skip,
                take: TAKE,
            };
        }

        post('web/get-news', param,
            result => {
                if (skip === 0) {
                    this.setState({
                        news: result,
                        statusText: "No record found"
                    }, () => {
                        this.setState({
                            loadPage: false
                        });
                    });
                } else {
                    this.setState({
                        news: news.concat(result),
                    });
                }
            }, () => { },
            BASE_API
        )
    }

    onLoadMore = () => {
        var { skip } = this.state;

        this.setState(
            {
                skip: skip + TAKE
            },
            () => this.getNews()
        );
    };

    componentDidMount() {
        this.getNews();
    }


    render() {
        if (isMobile()) return this.renderMobile();
        return this.renderDesktop();
    }

    renderMobile() {
        const {
            news,
            statusText,

        } = this.state;
        const { idNews, openCollapsible } = this.props;

        return (
            <section className="section-articles collapsible-sidebar">
                <div className="accordion">
                    <Collapsible
                        trigger="Articles in this section"
                    >
                        <ul>
                            {
                                news.length > 0
                                    ?
                                    news.map((item, i) =>
                                        <li key={i}>
                                            <NavLink to={"/articles/" + createSlugName(item.description) + "/" + item.id} className={"sidenav-item" + (idNews === item.id ? ' current-article' : '')}>
                                                <span className="text-summary text-row-3">{item.description}</span>
                                            </NavLink>
                                        </li>
                                    )
                                    :
                                    <li>
                                        <h1 className="text-center">{statusText}</h1>
                                    </li>
                            }

                        </ul>
                        <NavLink to="/articles" className="article-sidebar-item">See more</NavLink>
                    </Collapsible>
                </div>
            </section>
        );
    }

    renderDesktop() {
        const {
            news,
            statusText
        } = this.state;
        const { idNews } = this.props;

        return (
            <section className="section-articles collapsible-sidebar">
                <h3 className="collapsible-sidebar-title sidenav-title">Articles in this section</h3>
                <ul>
                    {
                        news.length > 0
                            ?
                            news.map((item, i) =>
                                <li key={i}>
                                    <NavLink to={"/articles/" + createSlugName(item.description) + "/" + item.id} className={"sidenav-item" + (idNews === item.id ? ' current-article' : '')}><span className="text-summary text-row-3">{item.description}</span></NavLink>
                                </li>
                            )
                            :
                            <li>
                                <h1 className="text-center">{statusText}</h1>
                            </li>
                    }

                </ul>
                <NavLink to="/articles" className="article-sidebar-item">See more</NavLink>
            </section>
        );
    }
}

export default Index;

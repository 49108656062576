import React from "react";
import { NavLink } from "react-router-dom";
import { FILE_UPLOAD_WEB_CONTENT, BASE_API } from "../../../constants/appSettings";
import { get, post } from "../../../api/index";
import './style/style.css';
import './style/style-mobile.css';
import ArticleSidebar from "../detail/article-sidebar";
import { isMobile } from "../../../utils/app";
import { createSlugName } from "../../../utils/common";
const TAKE = 10;

class Index extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loadPage: true,
            statusText: "Waiting...",
            articles: [],
        }
    }

    getArticlesSlide() {
        get('web/api/page-home/slide-content',
            result => {
                this.setState({
                    articles: result,
                    statusText: "No record found"
                }, () => {
                    this.setState({
                        loadPage: false
                    });
                });
            }, () => { },
            BASE_API
        )
    }

    componentDidMount() {
        this.getArticlesSlide();
    }

    render() {
        if (isMobile()) return this.renderMobile();
        return this.renderDesktop();
    }

    renderMobile() {
        const {
            articles,
            statusText
        } = this.state;

        return (
            <div className="components-mobile">
                <div className="articles-slide-list">
                    <nav className="sub-nav">
                        <ol className="breadcrumbs">
                            <li>
                                <NavLink to="/">Home</NavLink>
                            </li>
                            <li>
                                <NavLink to="/articles-slide-content">Articles Slide Content</NavLink>
                            </li>
                        </ol>

                        {/* <form className="search">
                            <i className="fas fa-search" />
                            <input type="search" placeholder="Search" />
                        </form> */}
                    </nav>
                    <div className="article-container">
                        <h1 className="collapsible-sidebar-title sidenav-title mb20">Articles in this section</h1>
                        <ul className="list-article clear-fix">
                            {
                                articles.length > 0
                                    ?
                                    articles.map((item, i) =>
                                        <li key={i}>
                                            <NavLink to={"/articles-slide-content/" + createSlugName(item.description) + "/" + item.id}><img src={FILE_UPLOAD_WEB_CONTENT + item.photoDesktop} alt={item.title} /></NavLink>
                                        </li>
                                    )
                                    :
                                    <li>
                                        <h1 className="text-center">{statusText}</h1>
                                    </li>
                            }

                        </ul>
                    </div>
                </div>
            </div>
        );
    }

    renderDesktop() {
        const {
            articles,
            statusText
        } = this.state;

        return (
            <div className="components-pc">
                <div className="content-main articles-slide-list">
                    <div className="container">
                        <nav className="sub-nav">
                            <ol className="breadcrumbs">
                                <li>
                                    <NavLink to="/">Home</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/articles-slide-content">Articles Slide Content</NavLink>
                                </li>
                            </ol>

                            {/* <form className="search">
                                <i className="fas fa-search" />
                                <input type="search" placeholder="Search" />
                            </form> */}
                        </nav>
                        <div className="article-container">
                            <h1 className="collapsible-sidebar-title sidenav-title mb20">Articles in this section</h1>
                            <ul className="list-article clear-fix">
                                {
                                    articles.length > 0
                                        ?
                                        articles.map((item, i) =>
                                            <li key={i}>
                                                <NavLink to={"/articles-slide-content/" + createSlugName(item.description) + "/" + item.id}><img src={FILE_UPLOAD_WEB_CONTENT + item.photo} alt={item.title} /></NavLink>
                                            </li>
                                        )
                                        :
                                        <li>
                                            <h1 className="text-center">{statusText}</h1>
                                        </li>
                                }

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Index;

import { ACCESS_TOKEN, USER_INFO, EMAIL } from "../constants/localStorageKeys";

import { connectedRouterRedirect } from "redux-auth-wrapper/history4/redirect";

export const isSignedIn = () =>
  window.localStorage.getItem(ACCESS_TOKEN) != null;

let _currentUser = null;

export const getCurrentUser = () => {
  if (_currentUser == null) {
    _currentUser = JSON.parse(window.localStorage.getItem(USER_INFO));
  }
  return _currentUser;
};


export const setCurrentUser = (user) => {
  _currentUser = user;
  window.localStorage.setItem(USER_INFO, JSON.stringify(user))
};

export const isInRole = role => {
  // var user = getCurrentUser();
  // return user.roles.include(role);
  return true
};

export const signOut = () => {
  window.localStorage.removeItem(ACCESS_TOKEN);
  window.localStorage.removeItem(USER_INFO);
  window.history.go(0);
};

export const signIn = token => {
  const { accessToken, user } = token;
  window.localStorage.setItem(ACCESS_TOKEN, accessToken);
  window.localStorage.setItem(USER_INFO, JSON.stringify(user));
};

export const IsAuthenticatedRedir = connectedRouterRedirect({
  ...IsAuthenticatedDefaults,
  authenticatedSelector: state =>
    window.localStorage.getItem(ACCESS_TOKEN) != null,
  redirectPath: "/sign-in"
});

const IsAuthenticatedDefaults = {
  wrapperDisplayName: "UserIsAuthenticated"
};

import React from "react";
import './style.scss';
import { post, get, postFormData } from "../../api";
import { BASE_API, FILE_UPLOAD_WEB_CONTENT, EMAIL } from "../../constants/appSettings";
import { validate } from "../../utils/validators";
import { isMobile, putInProcess, showError, showSuccess, takeOutProcess } from "../../utils/app";
import { NavLink } from "react-router-dom";
import { formatDate, formatDate2, formatDate3 } from "../../utils/common";
import Dropzone from "react-dropzone";
import { getCurrentUser, isSignedIn } from "../../auth";
import Textarea from 'react-textarea-autosize';
const TAKE = 10;

class Index extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activePage: "active",
            loadPage: true,
            statusText: "Waiting...",
            data: null,
            dataOption: null,
            total: 0,
            skip: 0,
            files: [],
            replyTextarea: '',
            id: ''
        }
    }

    onloadForm(id) {
        get('web/api/request/help/' + id,
            result => {
                this.setState({
                    data: result,
                    dataOption: result.dataOption,
                    statusText: "No record found"
                }, () => {
                    this.setState({
                        loadPage: false
                    });
                });
            }, () => { },
            BASE_API
        )
    }

    submitForm = () => {
        let {
            id,
            replyTextarea,
            data
        } = this.state;

        let refs = this.refs;
        const { token } = this.props.match.params;
        validate(
            [
                refs.reply
            ],
            () => {

                let param = {
                    "reply": replyTextarea,
                    "requestId": data.id,
                    "requestFiles": this.state.files,
                };

                console.log('param', param)
                putInProcess(refs.btnSubmit);
                debugger
                post("web/api/request/reply-user", param,
                    result => {
                        showSuccess("Success");
                        this.onloadForm(token);
                        takeOutProcess(refs.btnSubmit);
                        this.setState({
                            replyTextarea: '',
                            files: []
                        });
                    }, () => { },
                    BASE_API
                );
            }
        )
    };

    componentDidMount() {
        const { token } = this.props.match.params;
        this.onloadForm(token);
        this.setState({
            id: token
        });

    }

    onDrop(files) {
        if (files.length > 0) {
            for (let i = 0; i < files.length; i++) {
                this._addFile(files[i]);
            }
        }
    }

    _addFile = (file) => {
        let formData = new FormData();
        formData.append("file", file);

        let { files } = this.state;

        postFormData("api/Upload", formData, result => {
            let file = {
                relativePath: result.relativePath
            };
            files.push(file);
            this.setState({ files: files });
        },
            () => { }, BASE_API);
    };

    onCancel() {
        this.setState({
            files: []
        });
    }

    render() {
        if (isMobile()) return this.renderMobile();
        return this.renderDesktop();
    }

    renderMobile() {
        const {
            data,
            dataOption,
            statusText,
            replyTextarea,
            activePage
        } = this.state;
        const { skip } = this.state;

        const files = this.state.files.map(file => (
            <li key={file.relativePath}>
                {file.relativePath}
            </li>
        ));

        console.log('token', this.state.id)
        return (
            <div className="components-mobile">
                <div className="my-activities">
                    <div className="my-activities-nav" style={{paddingLeft: '35px'}}>
                        <div className="container-full">
                            <ul className="collapsible-nav-list">
                                <li><NavLink className={activePage} to="/user-center/my-activities/requests/my-requests">Requests</NavLink></li>
                                {/* <li><NavLink to="/user-center/my-activities/contributions">Contributions</NavLink></li>
                                <li><NavLink to="/user-center/my-activities/following">Following</NavLink></li> */}
                            </ul>
                        </div>
                    </div>
                    <div className="" style={{ padding: '0 35px' }}>

                        <div className="requests">
                            <div className="container-full">
                                <nav className="sub-nav">
                                    <ol className="breadcrumbs">
                                        <li>
                                            <NavLink to="/support">Support</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/user-center/my-activities/requests/my-requests">My activities</NavLink>
                                        </li>
                                    </ol>
                                </nav>
                                {
                                    data != null &&
                                    <div className="request-container">
                                        <h3 className="request-title">{data.subject}</h3>
                                        <div className="request-main-page">
                                            <div className="request-main">
                                                <ul className="comment-list">
                                                    {
                                                        data.requestReplies.map((item, i) =>
                                                            <li className={item.adminId == null ? "comment" : "reply"} key={i}>
                                                                <div className="comment-wrapper">
                                                                    <div className="comment-info">
                                                                        <div className="comment-author">
                                                                            <div className="avatar-comment comment-avatar">
                                                                                <img src={require("../../assets/images/default_avatar.png")} alt="Avatar" className="user-avatar-comment" />
                                                                            </div>
                                                                            <div className="comment-meta">
                                                                                <span>{item.adminId != null ? item.adminEmail : data.email}</span>
                                                                                <ul className="meta-group">
                                                                                    <li className="meta-data">
                                                                                        <time dateTime={formatDate3(item.dateCreate)} title={formatDate3(item.dateCreate)} data-datetime="relative">{formatDate3(item.dateCreate)}</time>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                        <section className="comment-body">
                                                                            <div className="zd-comment">
                                                                                <Textarea
                                                                                    style={{
                                                                                        border: 'none',
                                                                                        background: 'transparent',
                                                                                        overflow: 'hidden',
                                                                                        resize: 'none',
                                                                                        width: '100%'
                                                                                    }}
                                                                                    disabled defaultValue={item.reply} />


                                                                            </div>
                                                                            <div className="file mt10">
                                                                                <div className="list-file">
                                                                                    {
                                                                                        item.requestFiles != null &&
                                                                                        item.requestFiles.length > 0 &&
                                                                                        item.requestFiles.map((itemJ, j) =>
                                                                                            <p key={j}><i className="far fa-file" /> <a className="text-summary text-row-1" href={FILE_UPLOAD_WEB_CONTENT + itemJ.fileName} download>{itemJ.fileName}</a></p>
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </section>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        )
                                                    }
                                                </ul>
                                                <div className="comment-form">
                                                    {
                                                        data.status != 0 &&
                                                        <form>
                                                            <div className="comment-wrapper">
                                                                <div className="comment-info">
                                                                    <div className="comment-author">
                                                                        <div className="avatar-comment comment-avatar">
                                                                            <img src={require("../../assets/images/default_avatar.png")} alt="Avatar" className="user-avatar-comment" />
                                                                        </div>
                                                                        <div className="comment-meta">
                                                                            <div className="mb15">
                                                                                <div className="filed">
                                                                                    <div className="ct-filed">
                                                                                        <label className="text-layout-2">
                                                                                            <Textarea
                                                                                                rows="5"
                                                                                                ref="replyTextarea"
                                                                                                name="Reply Textarea"
                                                                                                validators="not-empty"
                                                                                                value={replyTextarea}
                                                                                                onChange={event => this.setState({ replyTextarea: event.target.value })}
                                                                                            />
                                                                                            <input
                                                                                                style={{ display: 'none' }}
                                                                                                hidden
                                                                                                ref="reply"
                                                                                                name="Reply"
                                                                                                validators="not-empty"
                                                                                                value={replyTextarea}
                                                                                                readOnly
                                                                                            />
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="mb15">
                                                                                <div className="image">
                                                                                    <div className="dropzone">
                                                                                        <Dropzone
                                                                                            onDrop={this.onDrop.bind(this)}
                                                                                            onFileDialogCancel={this.onCancel.bind(this)}
                                                                                        >
                                                                                            {({ getRootProps, getInputProps }) => (
                                                                                                <div {...getRootProps()}>
                                                                                                    <input {...getInputProps()} />
                                                                                                    {
                                                                                                        files.length == 0 ?
                                                                                                            <p><i className="fas fa-paperclip" /> Drop files here, or click to select files</p>
                                                                                                            :
                                                                                                            <aside>
                                                                                                                <ul>{files}</ul>
                                                                                                            </aside>
                                                                                                    }
                                                                                                </div>
                                                                                            )}
                                                                                        </Dropzone>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="submit-form filed clear-fix">
                                                                                <input
                                                                                    className="btn btn-default bg-orange pull-right"
                                                                                    type="button"
                                                                                    ref="btnSubmit"
                                                                                    onClick={this.submitForm}
                                                                                    value="Submit"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    }
                                                </div>
                                            </div>
                                            <div className="request-sidebar">
                                                <h3 className="collapsible-sidebar-title">Ticket details</h3>
                                                <table className="request-details table">
                                                    <tbody>
                                                        <tr>
                                                            <td>Requester</td>
                                                            <td>{data.email}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Created</td>
                                                            <td>
                                                                <time dateTime={formatDate3(data.dateCreate)} title={formatDate3(data.dateCreate)} data-datetime="calendar">{formatDate3(data.dateCreate)}</time>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Last activity</td>
                                                            <td>
                                                                <time dateTime={formatDate3(data.lastActivity)} title={formatDate3(data.lastActivity)} data-datetime="calendar">{formatDate3(data.lastActivity)}</time>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <table className="request-details table">
                                                    <tbody>
                                                        <tr>
                                                            <td>Id</td>
                                                            <td>#{data.id}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Status</td>
                                                            <td>
                                                                {
                                                                    data.status == 1 ?
                                                                        <label className="status-label success">awaiting your reply</label> :
                                                                        data.status == 2 ?
                                                                            <label className="status-label primary">answered</label> :
                                                                            <label className="status-label basic">solved</label>
                                                                }
                                                            </td>
                                                        </tr>
                                                        {
                                                            dataOption.map((item, i) =>
                                                                <tr key={i}>
                                                                    <td>{item.title}</td>
                                                                    <td>{item.value}</td>
                                                                </tr>
                                                            )
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderDesktop() {
        const {
            data,
            dataOption,
            statusText,
            replyTextarea,
            activePage
        } = this.state;
        const { skip } = this.state;

        const files = this.state.files.map(file => (
            <li key={file.relativePath}>
                {file.relativePath}
            </li>
        ));

        return (
            <div className="components-pc">
                <div className="content-main my-activities">

                    <div className="my-activities-nav">
                        <div className="container-full">

                            <ul className="collapsible-nav-list" style={{ paddingLeft: '35px' }}>
                                <li><a className={activePage}>Requests</a></li>
                                {/* <li><NavLink to="/user-center/my-activities/contributions">Contributions</NavLink></li>
                                <li><NavLink to="/user-center/my-activities/following">Following</NavLink></li> */}
                            </ul>
                        </div>
                    </div>
                    <div className="" style={{ padding: '0 35px' }}>
                        <div className="requests">
                            <div className="container-full">
                                <nav className="sub-nav">
                                    <ol className="breadcrumbs">
                                        <li>
                                            <NavLink to="/support">Support</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/user-center/my-activities/requests/my-requests">My activities</NavLink>
                                        </li>
                                    </ol>
                                </nav>
                                {
                                    data != null &&
                                    <div className="request-container">
                                        <h3 className="request-title">{data.subject}</h3>
                                        <div className="request-main-page">
                                            <div className="request-main">
                                                <ul className="comment-list">
                                                    {
                                                        data.requestReplies.map((item, i) =>
                                                            <li className={item.adminId == null ? "comment" : "reply"} key={i}>
                                                                <div className="comment-wrapper">
                                                                    <div className="comment-info">
                                                                        <div className="comment-author">
                                                                            <div className="avatar-comment comment-avatar">
                                                                                <img src={require("../../assets/images/default_avatar.png")} alt="Avatar" className="user-avatar-comment" />
                                                                            </div>
                                                                            <div className="comment-meta">
                                                                                <span>{item.adminId != null ? item.adminEmail : data.email}</span>
                                                                                <ul className="meta-group">
                                                                                    <li className="meta-data">
                                                                                        <time dateTime={formatDate3(item.dateCreate)} title={formatDate3(item.dateCreate)} data-datetime="relative">{formatDate3(item.dateCreate)}</time>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                        <section className="comment-body">
                                                                            <div className="zd-comment">
                                                                                <Textarea
                                                                                    style={{
                                                                                        border: 'none',
                                                                                        background: 'transparent',
                                                                                        overflow: 'hidden',
                                                                                        resize: 'none',
                                                                                        width: '100%'
                                                                                    }}
                                                                                    disabled defaultValue={item.reply} />


                                                                            </div>
                                                                            <div className="file mt10">
                                                                                <div className="list-file">
                                                                                    {
                                                                                        item.requestFiles != null &&
                                                                                        item.requestFiles.length > 0 &&
                                                                                        item.requestFiles.map((itemJ, j) =>
                                                                                            <p key={j}><i className="far fa-file" /> <a className="text-summary text-row-1" href={FILE_UPLOAD_WEB_CONTENT + itemJ.fileName} download>{itemJ.fileName}</a></p>
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </section>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        )
                                                    }
                                                </ul>
                                                <div className="comment-form">
                                                    {
                                                        data.status != 0 &&
                                                        <form>
                                                            <div className="comment-wrapper">
                                                                <div className="comment-info">
                                                                    <div className="comment-author">
                                                                        <div className="avatar-comment comment-avatar">
                                                                            <img src={require("../../assets/images/default_avatar.png")} alt="Avatar" className="user-avatar-comment" />
                                                                        </div>
                                                                        <div className="comment-meta">
                                                                            <div className="mb15">
                                                                                <div className="filed">
                                                                                    <div className="ct-filed">
                                                                                        <label className="text-layout-2">
                                                                                            <Textarea
                                                                                                rows="5"
                                                                                                ref="replyTextarea"
                                                                                                name="Reply Textarea"
                                                                                                validators="not-empty"
                                                                                                value={replyTextarea}
                                                                                                onChange={event => this.setState({ replyTextarea: event.target.value })}
                                                                                            />
                                                                                            <input
                                                                                                style={{ display: 'none' }}
                                                                                                hidden
                                                                                                ref="reply"
                                                                                                name="Reply"
                                                                                                validators="not-empty"
                                                                                                value={replyTextarea}
                                                                                                readOnly
                                                                                            />
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="mb15">
                                                                                <div className="image">
                                                                                    <div className="dropzone">
                                                                                        <Dropzone
                                                                                            onDrop={this.onDrop.bind(this)}
                                                                                            onFileDialogCancel={this.onCancel.bind(this)}
                                                                                        >
                                                                                            {({ getRootProps, getInputProps }) => (
                                                                                                <div {...getRootProps()}>
                                                                                                    <input {...getInputProps()} />
                                                                                                    {
                                                                                                        files.length == 0 ?
                                                                                                            <p><i className="fas fa-paperclip" /> Drop files here, or click to select files</p>
                                                                                                            :
                                                                                                            <aside>
                                                                                                                <ul>{files}</ul>
                                                                                                            </aside>
                                                                                                    }
                                                                                                </div>
                                                                                            )}
                                                                                        </Dropzone>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="submit-form filed clear-fix">
                                                                                <input
                                                                                    className="btn btn-default bg-orange pull-right"
                                                                                    type="button"
                                                                                    ref="btnSubmit"
                                                                                    onClick={this.submitForm}
                                                                                    value="Submit"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    }
                                                </div>
                                            </div>
                                            <div className="request-sidebar">
                                                <h3 className="collapsible-sidebar-title">Ticket details</h3>
                                                <table className="request-details table">
                                                    <tbody>
                                                        <tr>
                                                            <td>Requester</td>
                                                            <td>{data.email}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Created</td>
                                                            <td>
                                                                <time dateTime={formatDate3(data.dateCreate)} title={formatDate3(data.dateCreate)} data-datetime="calendar">{formatDate3(data.dateCreate)}</time>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Last activity</td>
                                                            <td>
                                                                <time dateTime={formatDate3(data.lastActivity)} title={formatDate3(data.lastActivity)} data-datetime="calendar">{formatDate3(data.lastActivity)}</time>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <table className="request-details table">
                                                    <tbody>
                                                        <tr>
                                                            <td>Id</td>
                                                            <td>#{data.id}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Status</td>
                                                            <td>
                                                                {
                                                                    data.status == 0 && <label className="status-label success">Inactive</label>
                                                                }
                                                                {
                                                                    data.status == 1 && <label className="status-label success">Active</label>
                                                                }
                                                                {
                                                                    data.status == 2 && <label className="status-label primary">Solved</label>
                                                                }
                                                                {
                                                                    data.status == 3 &&
                                                                    <label className="status-label basic">Awaiting your reply</label>
                                                                }
                                                                {
                                                                    data.status == 4 &&
                                                                    <label className="status-label basic">Answered</label>
                                                                }
                                                            </td>
                                                        </tr>
                                                        {
                                                            dataOption.map((item, i) =>
                                                                <tr key={i}>
                                                                    <td>{item.title}</td>
                                                                    <td>{item.value}</td>
                                                                </tr>
                                                            )
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Index;

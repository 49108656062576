import React from "react";
import '../../../assets/common.scss';
import { BASE_API } from "../../../constants/appSettings";
import { get, post } from "../../../api/index";
import Barlink from "../bar-link";
import Banner from "../banner";
import DownPaneSlide from "../downpane-slide";
import './style.scss';
import $ from 'jquery'
class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listBanner: null,
            barLinkPOS: [
                {
                    value: 'Free POS Software'
                },
                {
                    value: 'Rất Dễ Dùng'
                },
                {
                    value: 'Chia Turn'
                }
            ],
            PosBanner: [
                {
                    id: 1,
                    background: require('../../../assets/images/eeee.png'),
                    backgroundMobile: require('../../../assets/images/eeee.png'),
                }
            ]
        };
    }

    getBanner() {
        get('web/api/page-home/slide-banner',
            result => {
                this.setState({
                    listBanner: result
                }, () => {
                    this.setState({
                        loadPage: false
                    });
                });
            }, () => { },
            BASE_API
        )
    }


    _handleCheckWindowSize() {
        let width = window.innerWidth;
        this.setState({
            isMoble: false
        }, () => {
            this.setState({
                isMoble: width < 768
            })
        })

    }

    componentDidMount() {
        this.getBanner();
        this._handleCheckWindowSize();
        window.addEventListener("resize", () => {
            this._handleCheckWindowSize()
        })

    }
    render() {
        const settings = {
            dots: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            speed: 1000,
            autoplaySpeed: 4000,
            cssEase: "linear"
        };
        let { barLinkPOS, PosBanner } = this.state

        return (
            <div className="email-sms">

            </div>
        );
    }
}

export default Index;

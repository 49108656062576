import React from "react";
import './style.scss';
import '../../assets/common.scss';
import Footer from "../template/footer";
import Header from "../template/header";
import Slider from "react-slick";
import { PlayerControl } from "../common/video";
import DocumentTitle from 'react-document-title';

import TextInput from '../common/text-input';
import Select from 'react-select'
import $ from "jquery";

import Recaptcha from "react-recaptcha";
import countryList from 'react-select-country-list'
import {
    notEmpty,
    emailAddress,
    justNumber,
    isValid
} from "../../utils/validators"
import { showError, showSuccess } from "../../utils/app";
import { post } from '../../api'
import { BASE_API } from '../../constants/appSettings'

const recaptchaRef = React.createRef();



class Home extends React.Component {
    constructor(props) {
        super(props);
        this.options = countryList().getData()
        this.recaptchaLoader = this.recaptchaLoader.bind(this)
        this.verifyCallback = this.verifyCallback.bind(this)
        this.state = {
            isMoble: false,
            isMobileMini: false,
            titleDocument: '',
            y: 0,
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            company: '',
            country: null,
            optionCountry: this.options,
            content: '',
            isVerify: false

        };
        this.validationData = {
            email: [notEmpty, emailAddress],
            firstName: [notEmpty],
            phone: [notEmpty, justNumber],
            lastName: [notEmpty],
            company: [notEmpty]
        };
    }

    _handleCheckWindowSize() {
        let width = window.innerWidth;
        this.setState({
            isMoble: false,
            isMobileMini: false
        }, () => {
            this.setState({
                isMoble: width < 992,
                isMobileMini: width < 576
            })
        })
    }
    changeHandler = country => {
        this.setState({ country })
    }
    onSubmitContact() {
        let validationData = this.validationData;
        // if (!isValid(validationData))
        //     return
        let { firstName, lastName, email, phone, company, country, content } = this.state
        let param = {
            firstName,
            lastName,
            email,
            phone,
            company,
            country: '',
            description: content
        }
        // console.log('param:', param)
        if (this.state.isVerify) {
            // showSuccess('you submit success')
            post('api/send-contact', param,
                () => {
                    showSuccess("Submit success")
                    this.setState({
                        firstName: '',
                        lastName: '',
                        email: '',
                        phone: '',
                        company: '',
                        content: ''
                    });
                }, () => { },
                BASE_API
            )
        }
        else {
            showError('Please check verify')
        }
    }
    recaptchaLoader() {
        console.log('capcha successfully loader !')
    }
    verifyCallback(response) {
        if (response) {
            this.setState({ isVerify: true })
            console.log(response)
        }
    };
    componentDidMount() {
        this._handleCheckWindowSize();
        window.addEventListener("resize", () => {
            this._handleCheckWindowSize()
        })
        $(window).scroll(() => this.setState({ y: $(window).scrollTop() }));
    }
    render() {
        let { isMoble, y, firstName, email, company, country, lastName, phone, optionCountry, content } = this.state

        let validationData = this.validationData;
        // console.log('options:', content)
        return (
            <DocumentTitle title='Contact-us'>
                <div>
                    <Header />
                    <div className='contact-us'>
                        <div className='background-contact'></div>
                        <div className='box-content'>
                            <div className='address mb-lg-5'>
                                <p classNam="mb-2">Please fill out this form to be contacted by a Merchantpossolutions sales representative.</p>
                                <p className='text-span mb-2'>For immediate sales help, call us at <span style={{ fontWeight: '600' }}>210 - 607 - 8888</span>. </p>
                                <p className='text-span' >Address:<span style={{ fontWeight: '600' }}>  16770 Hedgecroft Dr, Ste 702 Houston, TX 77060.</span> </p>
                            </div>
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <div className='box-form-input'>
                                        <label>First Name  <span className='text-danger'>*</span></label>
                                        <TextInput
                                            className='input-form'
                                            edit={true}
                                            multiline={false}
                                            name="firstName"
                                            displayName="FirstName"
                                            value={firstName}
                                            validations={validationData.firstName}
                                            onChange={e => {
                                                this.setState({
                                                    firstName: e.target.value
                                                })
                                            }} />
                                    </div>
                                    <div className='box-form-input'>
                                        <label>Email  <span className='text-danger'>*</span></label>
                                        <TextInput
                                            className='input-form'
                                            edit={true}
                                            multiline={false}
                                            name="email"
                                            displayName="Email"
                                            value={email}
                                            validations={validationData.email}
                                            onChange={e => {
                                                this.setState({
                                                    email: e.target.value
                                                })
                                            }} />
                                    </div>
                                    <div className='box-form-input'>
                                        <label>Company  <span className='text-danger'>*</span></label>
                                        <TextInput
                                            className='input-form'
                                            edit={true}
                                            multiline={false}
                                            name="company"
                                            displayName="Company"
                                            value={company}
                                            validations={validationData.company}
                                            onChange={e => {
                                                this.setState({
                                                    company: e.target.value
                                                })
                                            }} />
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <div className='box-form-input'>
                                        <label>Last Name  <span className='text-danger'>*</span></label>
                                        <TextInput
                                            className='input-form'
                                            edit={true}
                                            multiline={false}
                                            name="lastName"
                                            displayName="LastName"
                                            value={lastName}
                                            validations={validationData.lastName}
                                            onChange={e => {
                                                this.setState({
                                                    lastName: e.target.value
                                                })
                                            }} />
                                    </div>
                                    <div className='box-form-input'>
                                        <label>Phone Number  <span className='text-danger'>*</span></label>
                                        <TextInput
                                            className='input-form'
                                            edit={true}
                                            multiline={false}
                                            name="phone"
                                            displayName="Phone"
                                            value={phone}
                                            validations={validationData.phone}
                                            onChange={e => {
                                                this.setState({
                                                    phone: e.target.value
                                                })
                                            }} />
                                    </div>
                                    {/* <div className='box-form-input mb-3 mb-lg-0'>
                                        <label>Country  <span className='text-danger'>*</span></label>
                                        <Select
                                            options={optionCountry}
                                            value={country}
                                            onChange={(e) => this.changeHandler(e)}
                                        />
                                    </div> */}
                                </div>
                            </div>
                            <div className='box-form-input'>
                                <label>Please description what you need help withs (200 char max)  <span className='text-danger'>*</span></label>
                                <textarea
                                    rows="8"
                                    className='pl-2'
                                    style={{ width: '100%' }}
                                    value={content}
                                    onChange={e => {
                                        this.setState({
                                            content: e.target.value
                                        })
                                    }}
                                >
                                </textarea>
                            </div>
                            {/* <div class="g-recaptcha" data-sitekey="6LfuuM0UAAAAAHk8BbogxrlE252iUF7kIDlapzLt"></div> */}
                            <p className='text-span mb-4 mt-4'>By clicking the "SUBMIT" button below, you agree to the <span>Terms & Conditions</span> and <span>Privacy Policy.</span></p>
                            <Recaptcha
                                sitekey="6LfuuM0UAAAAAHk8BbogxrlE252iUF7kIDlapzLt"
                                render="explicit"
                                onloadCallback={this.recaptchaLoader}
                                verifyCallback={this.verifyCallback}
                            />
                            <div className='button-submit text-center mt-3 mb-5'>
                                <button className='btn btn-primary' style={{ padding: '8px 25px' }} onClick={() => this.onSubmitContact()}>Submit</button>
                            </div>
                        </div>


                    </div>
                </div>
            </DocumentTitle>
        );
    }
}

export default Home;

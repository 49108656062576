import React from "react";
import './style.scss';
import '../../../assets/common.scss';
import { BASE_API } from "../../../constants/appSettings";
import { get } from "../../../api/index";



class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listBarLink: null
        };
    }




    _handleCheckWindowSize() {
        let width = window.innerWidth;
        this.setState({
            isMoble: false
        }, () => {
            this.setState({
                isMoble: width < 768
            })
        })

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.barLink != this.props.barLink) {
            this.setState({
                listBarLink: nextProps.barLink
            })
        }
    }
    componentDidMount() {
        this._handleCheckWindowSize();
        window.addEventListener("resize", () => {
            this._handleCheckWindowSize()
        })

    }
    render() {
        const settings = {
            dots: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            speed: 1000,
            autoplaySpeed: 4000,
            cssEase: "linear"
        };
        let { listBanner, isMoble } = this.state
        let { barLink } = this.props
        return (
            <div className="bar-link">
                <ul className="content-bar-link">
                    {
                        barLink && barLink.map((item, index) =>
                            <li key={index} className="item-bar-link">
                                <p className="text-item-bar ">{item.value} <span><i className="fas fa-check-circle"></i></span></p>
                            </li>
                        )
                    }

                </ul>
            </div>

        );
    }
}

export default Home;

import React from "react";
import { Route, Switch } from "react-router";
import Home from "../home";
import "./style/style.scss";
import "../../assets/style/input.scss";
import { ACCESS_TOKEN, USER_INFO } from '../../constants/localStorageKeys'
import '../../assets/style/common.scss'
import '../../assets/style/default.scss'
import Support from '../support';

import Support_ListCoin from "../support/list-coin";
import Support_Faq from "../support/faq";
import Support_Faq_SubCatalog from "../support/faq/list-sub";
import Support_Faq_Detail from "../support/faq/detail";

import Support_Announcements from "../support/announcements";
import Support_Announcements_SubCatalog from "../support/announcements/list-sub";
import Support_Announcements_Detail from "../support/announcements/detail";


import ArticlesList from "../articles/list";
import Articles from "../articles/detail";
import ArticlesSlideList_Banner from "../articles-slide-banner/list";
import ArticlesSlide_Banner from "../articles-slide-banner/detail";

import ArticlesSlideList_Content from "../articles-slide-content/list";
import ArticlesSlide_Content from "../articles-slide-content/detail";

import SystemPOS from "../home/pos"

import Website from "../home/funding"
import SubmitRequest from "../submit-request";

import MyActivities_Requests from "../my-activities/requests"

import HelpPost from "../helpPost"

import PagePostFooter from "../page-post-footer"

import PagePostHeader from "../page-post-header"

import Header from "../template/header";
import Footer from "../template/footer";
import General from "../template/general-section";
import EmailSMS from "../home/email-sms";
import ContactUs from '../contact-us';
class Main extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            isMoble: false,
            dropdown: 0,
            widthpc: false,
            isSigned: false,
            email: null,
            expanded: null,
            messageList: "",
            activeFlyFooter: false,

        }
    }

    // ActiveScrollTo() {
    //     window.scrollTo()
    // }

    _onMessageWasSent(message) {
        this.setState({
            messageList: [...this.state.messageList, message]
        }, () => {
            this.setState({
                messageList: [...this.state.messageList, {
                    author: 'them',
                    type: 'text',
                    data: {
                        text: 'some text'
                    }
                }]
            })
        })
    }

    handleDrawerOpen = () => {
        this.setState({ open: true });
    };

    handleDrawerClose = () => {
        this.setState({ open: false });
    };

    _handleCheckWindowSize() {
        let width = window.innerWidth;
        this.setState({
            isMoble: false
        }, () => {
            this.setState({
                isMoble: width < 992
            })
        })

    }

    _signOut() {
        window.localStorage.removeItem(ACCESS_TOKEN)
        window.localStorage.removeItem(USER_INFO)
        this.setState({
            isSigned: false,
            email: null
        })
    }

    componentDidMount() {
        this._handleCheckWindowSize();
        window.addEventListener("resize", () => {
            this._handleCheckWindowSize()
        })

        let token = window.localStorage.getItem(ACCESS_TOKEN)
        let user = window.localStorage.getItem(USER_INFO)
        if (user) user = JSON.parse(user)
        this.setState({
            isSigned: token ? true : false,
            email: user ? user.email : ""
        })

    }

    render() {
        const { classes, theme } = this.props;
        const {
            open,
            widthpc,
            isSigned,
            email,
            expanded,
            messageList,
            isMoble,
            activeFlyFooter
        } = this.state;
        return (
            < div>
                <main>
                    <Header />
                    <Switch>
                        {/* <Route exact path="/login" component={Login} />
                    <Route exact path="/users/login/*" component={LoginByToken} />
                    <Route exact path="/basic-knowledge" component={Home} /> */}
                        <Route exact path="/" component={Home} />
                        <Route exact path="/credit-card" component={Home} />
                        <Route exact path="/support" component={Support} />
                        <Route exact path="/support/list-coin" component={Support_ListCoin} />
                        <Route exact path="/support/faq" component={Support_Faq} />
                        <Route exact path="/support/faq/:idCatalog" component={Support_Faq_SubCatalog} />
                        <Route exact path="/support/faq/:slug/:idCatalog/:id" component={Support_Faq_Detail} />


                        <Route exact path="/support/announcements" component={Support_Announcements} />
                        <Route exact path="/support/announcements/:idCatalog" component={Support_Announcements_SubCatalog} />
                        <Route exact path="/support/announcements/:slug/:idCatalog/:id" component={Support_Announcements_Detail} />



                        <Route exact path="/articles" component={ArticlesList} />
                        <Route exact path="/articles/:slug/:id" component={Articles} />

                        <Route exact path="/articles-slide-banner" component={ArticlesSlideList_Banner} />
                        <Route exact path="/articles-slide-banner/:slug/:id" component={ArticlesSlide_Banner} />

                        <Route exact path="/articles-slide-content" component={ArticlesSlideList_Content} />
                        <Route exact path="/articles-slide-content/:slug/:id" component={ArticlesSlide_Content} />


                        <Route exact path="/nails-salon" component={SystemPOS} />
                        <Route exact path="/Funding" component={Website} />
                        <Route exact path="/email-sms" component={EmailSMS} />
                        <Route exact path="/submit-request" component={SubmitRequest} />

                        {/* <Route exact path="/my-activities/request/my-requests" component={MyActivities_Requests} /> */}
                        <Route exact path="/help/request/:token" component={HelpPost} />

                        <Route exact path="/page-footer/:slug/:id" component={PagePostFooter} />
                        <Route exact path="/page-header/:slug/:id" component={PagePostHeader} />

                        <Route exact path="/contact-us" component={ContactUs} />
                    </Switch>
                    <General />
                    <Footer />
                </main>
                {/* <Launcher
                    agentProfile={{
                        teamName: 'react-chat-window',
                        imageUrl: 'https://a.slack-edge.com/66f9/img/avatars-teams/ava_0001-34.png',
                    }}
                    onMessageWasSent={this._onMessageWasSent.bind(this)}
                    messageList={this.state.messageList}
                    showEmoji
                /> */}
            </div >
        );
    }
}
export default Main;

import React from "react";
import './style/_style.scss';
import '../../assets/common.scss';
import CreditCard from "./form-creditcard";
import { BASE_API } from "../../constants/appSettings";
import { get } from "../../api/index";
import Barlink from "../home/bar-link";
import { Link } from "react-router-dom";
import DownPaneSlide from "../home/downpane-slide";
import ListPromotion from "./ListPromotion";
import Banner from "./banner";
import ResponsiveAbout from "../home/responsiveAbout"
import DocumentTitle from 'react-document-title';

const bannergif = require('../../assets/images/Rate.gif')
class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            focus: false,
            isMoble: true,
            listAboutCredit: [
                {
                    id: 2,
                    name: 'clover-station',
                    img: require('../../assets/images/station_dining.png'),
                    title: 'Clover Station',
                    desc: 'An all-in-one point-of-sale and payments system.'

                },
                {
                    id: 3,
                    name: 'clover-mini',
                    img: require('../../assets/images/clover-mini.jpg'),
                    title: 'Clover Mini',
                    desc: 'Compact countertop POS built for speed.'

                },
                {
                    id: 1,
                    name: 'clover-flex',
                    img: require('../../assets/images/clover-flex.jpg'),
                    title: 'Clover Flex',
                    desc: 'Small in size, built to do business wherever you are.'

                },
                {
                    id: 4,
                    name: 'clover-go',
                    img: require('../../assets/images/clover-go.png'),
                    title: 'Clover Go',
                    desc: 'The all-in-one contactless, chip and swipe card reader.'
                }
            ],
            barLinkHome: [
                {
                    value: 'Easy Application'
                },
                {
                    value: 'Month to Month'
                },
                {
                    value: ' Funding in 24'
                },
                {
                    value: 'Lowest rate'
                }
            ],
            bannerCredit: [
                {
                    id: 1,
                    background: require('../../assets/images/credit1.jpg'),
                    backgroundMobile: require('../../assets/images/credit1.jpg'),
                }
            ],
            isMobile576: false
        };
    }
    _handleCheckWindowSize() {
        let width = window.innerWidth;
        this.setState({
            isMoble: false,
            isMobile576: false
        }, () => {
            this.setState({
                isMoble: width < 992,
                isMobile576: width < 576
            })
        })

    }
    componentDidMount() {
        // this.getAbout()
        this._handleCheckWindowSize();
        window.addEventListener("resize", () => {
            this._handleCheckWindowSize()
        })

    }

    // getAbout() {
    //     get('web/api/page-home/about',
    //         result => {
    //             this.setState({
    //                 listAbout: result
    //             }, () => {
    //                 this.setState({
    //                     loadPage: false
    //                 });
    //             });
    //         }, () => { },
    //         BASE_API
    //     )
    // }
    render() {
        let { isMoble, listAboutCredit, barLinkHome, bannerCredit, isMobile576 } = this.state
        return (
            <DocumentTitle title='Merchant and POS Solutions'>
                <div>
                    <div className={"layout_container" + (isMoble ? ' mt55 mobile-home' : '')}>

                        <main className="l-index">
                            {/* {
                            isMobile576 && <Banner Banner={bannerCredit} />
                        } */}
                            {
                                isMobile576 && <div className='banner-mobile'><img src={bannergif} width='100%' height='auto'></img></div>
                            }
                            <CreditCard />
                            <Barlink barLink={barLinkHome} />
                            <ListPromotion />
                            <ResponsiveAbout listAbout={listAboutCredit} />
                            <DownPaneSlide />
                        </main>
                    </div>
                </div >
            </DocumentTitle>
        );
    }
}

export default Home;

import React from "react";
import './style/style.css';
import './style/style-mobile.css';
import { post, get } from "../../../api";
import { BASE_API } from "../../../constants/appSettings";
import { isMobile } from "../../../utils/app";
import { NavLink } from "react-router-dom";
import { formatDate2, formatDate, formatDate3, formatTime } from "../../../utils/common";
import { getCurrentUser, isSignedIn } from "../../../auth";
const TAKE = 10;
// this.isAuthenticated = isSignedIn();
const user = getCurrentUser();

class Index extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activePage: "active",
            loadPage: true,
            statusText: "Waiting...",
            data: [],
            total: 0,
            skip: 0,
        }
    }

    getNews() {
        var { skip, data } = this.state;

        // get('api/web/submit-request/get?email=' + user.email,
        //     result => {
        //         if (skip === 0) {
        //             this.setState({
        //                 data: result,
        //                 statusText: "No record found"
        //             }, () => {
        //                 this.setState({
        //                     loadPage: false
        //                 });
        //             });
        //         } else {
        //             this.setState({
        //                 data: data.concat(result),
        //             });
        //         }
        //     }, () => {
        //         this.setState({
        //             statusText: "No record found"
        //         }, () => {
        //             this.setState({
        //                 loadPage: false
        //             });
        //         });
        //     },
        //     BASE_API
        // )
    }

    onLoadMore = () => {
        var { skip } = this.state;

        this.setState(
            {
                skip: skip + TAKE
            },
            () => this.getNews()
        );
    };

    componentDidMount() {
        this.getNews();
    }

    render() {
        if (isMobile()) return this.renderMobile();
        return this.renderDesktop();
    }

    renderMobile() {
        const {
            data,
            statusText,
            activePage
        } = this.state;
        const { skip } = this.state;

        return (
            <div className="components-mobile">
                <div className="my-activities" >
                    <div className="my-activities-nav">
                        <div className="container-full" style={{ padding: '0 35px' }}>
                            <ul className="collapsible-nav-list">
                                <li><NavLink className={activePage} to="/my-activities/requests/my-requests">Requests</NavLink></li>
                                <li><NavLink to="/submit-request">Submit request</NavLink></li>
                            </ul>
                        </div>
                    </div>
                    <div className="requests">
                        <div className="container-full" style={{ padding: '0 35px' }}>
                            <div className="my-activities-sub-nav">
                                <ul className="collapsible-nav-list">
                                    <li><a className={activePage} style={{ fontSize: '12px' }}>My requests</a></li>
                                    <li>
                                        <NavLink style={{ fontSize: '12px' }} to="/user-center/my-activities/requests/ccd">Requests I'm CC'd on</NavLink>
                                    </li>
                                </ul>
                            </div>
                            <nav className="sub-nav">
                                {/*<form className="search">*/}
                                {/*<i className="fas fa-search"/>*/}
                                {/*<input type="search" placeholder="Search"/>*/}
                                {/*</form>*/}
                            </nav>
                            <div className="list-requests">
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Subject</th>
                                                <th>Id</th>
                                                <th>Created</th>
                                                <th>Last activity</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                data.length > 0 ?
                                                    data.map((item, i) =>
                                                        <tr key={i}>
                                                            <td><NavLink to={"/user-center/my-activities/requests/" + item.id}>{item.subject}</NavLink></td>
                                                            <td>#{item.id}</td>
                                                            <td>{formatDate3(item.dateCreate)}</td>
                                                            <td></td>
                                                            <td>
                                                                {
                                                                    item.status == 1 ?
                                                                        <label className="status-label success">awaiting your reply</label> :
                                                                        item.status == 2 ?
                                                                            <label className="status-label primary">answered</label> :
                                                                            <label className="status-label basic">solved</label>
                                                                }
                                                            </td>
                                                        </tr>
                                                    )
                                                    :
                                                    <tr>
                                                        <td colSpan={5} className="text-center">{statusText}</td>
                                                    </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }

    renderDesktop() {
        const {
            data,
            statusText,
            activePage
        } = this.state;
        const { skip } = this.state;

        return (
            <div className="components-pc">
                <div className="content-main my-activities">
                    <div className="my-activities-nav">
                        <div className="container-full" style={{ padding: '0 35px' }}>
                            <ul className="collapsible-nav-list">
                                <li><a className={activePage}>Requests</a></li>
                                <li><NavLink to="/submit-request">Submit request</NavLink></li>
                            </ul>
                        </div>
                    </div>
                    <div className="requests">
                        <div className="container-full" style={{ padding: '0 35px' }}>
                            <div className="my-activities-sub-nav">
                                <ul className="collapsible-nav-list">
                                    <li><a className={activePage} style={{ fontSize: '12px' }}>My requests</a></li>
                                    <li>
                                        <NavLink style={{ fontSize: '12px' }} to="/user-center/my-activities/requests/ccd">Requests I'm CC'd on</NavLink>
                                    </li>
                                </ul>
                            </div>
                            <nav className="sub-nav">
                                {/*<form className="search">*/}
                                {/*<i className="fas fa-search"/>*/}
                                {/*<input type="search" placeholder="Search"/>*/}
                                {/*</form>*/}
                            </nav>
                            <div className="list-requests">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Subject</th>
                                            <th>Id</th>
                                            <th>Created</th>
                                            <th>Last activity</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            data.length > 0 ?
                                                data.map((item, i) =>
                                                    <tr key={i}>
                                                        <td><NavLink to={"/user-center/my-activities/requests/" + item.id}>{item.subject}</NavLink></td>
                                                        <td>#{item.id}</td>
                                                        <td>{formatDate3(item.dateCreate)}</td>
                                                        <td>{formatDate3(item.lastActivity)}</td>
                                                        <td>
                                                            {
                                                                item.status == 1 ?
                                                                    <label className="status-label success">awaiting your reply</label> :
                                                                    item.status == 2 ?
                                                                        <label className="status-label primary">answered</label> :
                                                                        <label className="status-label basic">solved</label>
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                                :
                                                <tr>
                                                    <td colSpan={5} className="text-center">{statusText}</td>
                                                </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Index;

import React, { Component } from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import './style.scss'
export default class SelectInput extends Component {

    constructor(props) {
        super(props)
    }

    onChange(e) {
        this.props.onChange(e.target.value);
    }

    render() {

        let {
            name,
            label,
            value,
            options,
            tooltip,
            style,
            widthLabel
        } = this.props
        return (
            <div className={"form-group info"} style={style ? style : { display: 'flex', alignItems: 'center' }}>
                {
                    tooltip
                        ? <Tooltip title={<span style={{ fontSize: '10px' }}>{tooltip}</span>} placement="top-end"><label style={{ width: widthLabel }}>{label}</label></Tooltip>
                        : <label style={{ width: widthLabel }}>{label}</label>
                }
                <select value={value} className="selection_group" onChange={(e) => this.onChange(e)} >
                    {
                        options.map((option, i) =>
                            <option value={(option.value) ? option.value : option.id} key={i}>{(option.value) ? option.text : option.name}</option>
                        )
                    }
                </select>
                <span className={"text-danger"} id={"validator-for-" + name}></span>
            </div >
        )
    }
}
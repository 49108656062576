import React from "react";
import './style.scss';
import { Link } from "react-router-dom";
import ResponsiveAbout from './responsiveMobile';
import '../../../assets/common.scss';
import { createSlugName } from "../../../utils/common";
import { NavLink } from "react-router-dom";
class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMoble: true
        };
    }
    _handleCheckWindowSize() {
        let width = window.innerWidth;
        this.setState({
            isMoble: false,
            isMobile576: false
        }, () => {
            this.setState({
                isMoble: width < 992
            })
        })

    }
    componentDidMount() {
        // this.getAbout()
        this._handleCheckWindowSize();
        window.addEventListener("resize", () => {
            this._handleCheckWindowSize()
        })

    }


    render() {
        let { isMoble } = this.state
        let { listAbout } = this.props
        console.log(listAbout)
        return (
            <div>
                <div className={"layout_container" + (isMoble ? ' mt15 mobile-home' : '')}>
                    {
                        isMoble ? <ResponsiveAbout listAbout={listAbout} />
                            :
                            <div className="index-about">
                                <div className="title-index">
                                    <h2>Powerful POS solution that do more, so you can do more</h2>
                                    <p>Build your POS system the way your business operates-from simple credit card terminals to comprehensive solution to help manage your business.</p>
                                </div>
                                <div className="content-about">
                                    {
                                        listAbout && listAbout.map((item, index) =>
                                            <NavLink to={"/Post/" + item.name} className="about-item" key={index}>
                                                <div className="img-item">
                                                    <div className="content-img">
                                                        <img src={item.img} />
                                                    </div>
                                                </div>
                                                <div className="title-item">{item.title}</div>
                                                <div className="desc-item">{item.desc}</div>
                                                <div className="btn-item">
                                                    <span to={"/Post/" + item.name} className="btn">Learn more</span>
                                                </div>
                                            </NavLink>
                                        )
                                    }
                                </div>
                            </div>
                    }
                </div>
            </div >
        );
    }
}

export default Index;

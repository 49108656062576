import React from "react";
import './style/style.css';
import './style/style-mobile.css';
import { post, get } from "../../../../api";
import { BASE_API } from "../../../../constants/appSettings";
import { isMobile } from "../../../../utils/app";
import { NavLink } from "react-router-dom";
import { createSlugName, formatDate2 } from "../../../../utils/common";
import Pagination from "../../../common/pagination"
const TAKE = 10;

class Index extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loadPage: true,
            statusText: "Waiting...",
            data: null,
            page: 1,
            pageSize: 10
        }
    }

    getData(idCatalog) {
        var { page } = this.state;

        let param = {
            take: TAKE,
            page: page
        };

        post('web/api/faq/' + idCatalog, param,
            result => {
                this.setState({
                    data: result,
                    statusText: "No record found",
                    total: result.sumAll
                }, () => {
                    this.setState({
                        loadPage: false,
                    });
                });
            }, () => { },
            BASE_API
        )
    }

    _onPageChange = page => {
        this.setState({
            page: page,
        }, () => {
            const { idCatalog } = this.props.match.params;
            this.getData(idCatalog)
        });
    };

    componentDidMount() {
        const { idCatalog } = this.props.match.params;
        this.getData(idCatalog);
    }

    render() {
        return this.renderDesktop();
    }

    renderMobile() {


        return null;
    }

    renderDesktop() {
        const {
            data,
            statusText,
            page,
            total
        } = this.state;

        return (
            <div className="components-pc">
                <div className="content-main faq-support">
                    <div className="container">
                        <nav className="sub-nav">
                            <ol className="breadcrumbs">
                                <li>
                                    <NavLink to="/">Home</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/support">Support</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/support/faq">Faq</NavLink>
                                </li>
                                <li>
                                    <a>{data != null ? data.name : "No record found"}</a>
                                </li>
                            </ol>
                        </nav>
                        <div className="article-container">
                            <h1 className="collapsible-sidebar-title sidenav-title mb20">FAQ</h1>
                            <div className="list-section">
                                {
                                    data != null
                                        ?
                                        <div className="section new-listings-container mb50">
                                            <h3 className="mb20 text-left">{data.fullName}</h3>
                                            <ul className="list-article clear-fix">
                                                {
                                                    data.faqPosts.map((item, i) =>
                                                        <li className="recent-activity-item" key={i}>
                                                            <div>
                                                                <p className="recent-activity-item-link">
                                                                    <NavLink to={"/support/faq/" + createSlugName(item.title) + "/" + item.faqId + "/" + item.id}>
                                                                        <span className="text-summary text-row-1">{item.title}</span>
                                                                    </NavLink>
                                                                </p>
                                                            </div>
                                                        </li>
                                                    )
                                                }
                                            </ul>
                                        </div>
                                        :
                                        <h3 className="text-center">{statusText}</h3>
                                }
                            </div>

                            {
                                data &&
                                <Pagination
                                    pageSize={TAKE}
                                    total={total}
                                    onChange={page => this._onPageChange(page)}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Index;

import React from "react";
import '../../../assets/common.scss';
import { BASE_API } from "../../../constants/appSettings";
import { get, post } from "../../../api/index";
import Barlink from "../bar-link";
import Banner from "../banner";
import DownPaneSlide from "../downpane-slide";

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listBanner: null,
            barLinkWebsite: [
                {
                    value: 'Desktop'
                },
                {
                    value: 'Responsive'
                },
                {
                    value: 'Mobile'
                }
            ],
            bannerWeb: [
                {
                    id: 1,
                    background: require('../../../assets/images/website.jpg'),
                    backgroundMobile: require('../../../assets/images/website.jpg'),
                },
                {
                    id: 2,
                    background: require('../../../assets/images/GettyImages-1061328872-e2ad8c47a8ed4e23a46999635be12315.jpg'),
                    backgroundMobile: require('../../../assets/images/GettyImages-1061328872-e2ad8c47a8ed4e23a46999635be12315.jpg')
                }
            ]
        };
    }

    getBanner() {
        get('web/api/page-home/slide-banner',
            result => {
                this.setState({
                    listBanner: result
                }, () => {
                    this.setState({
                        loadPage: false
                    });
                });
            }, () => { },
            BASE_API
        )
    }


    _handleCheckWindowSize() {
        let width = window.innerWidth;
        this.setState({
            isMoble: false
        }, () => {
            this.setState({
                isMoble: width < 768
            })
        })

    }

    componentDidMount() {
        this.getBanner();
        this._handleCheckWindowSize();
        window.addEventListener("resize", () => {
            this._handleCheckWindowSize()
        })

    }
    render() {
        const settings = {
            dots: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false,
            speed: 1000,
            autoplaySpeed: 4000,
            cssEase: "linear"
        };
        let { listBanner, isMoble, barLinkWebsite, bannerWeb } = this.state

        return (
            <div>
                <Banner Banner={bannerWeb} />
                <Barlink barLink={barLinkWebsite} />
                <DownPaneSlide />
            </div>
        );
    }
}

export default Index;

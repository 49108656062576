import React from "react";
import { NavLink } from "react-router-dom";
import { FILE_UPLOAD_WEB_CONTENT, BASE_API } from "../../constants/appSettings";
import { get, post } from "../../api/index";
import { isMobile } from "../../utils/app";
import Collapsible from "react-collapsible";
import { formatDate4 } from "../../utils/common";
const TAKE = 10;

class Index extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loadPage: true,
            statusText: "Waiting...",
            articles: [],
            id: null,
            slug: null,
            data: null
        }
    }

    onloadDetail(id) {
        get("web/api/menu-footer/" + id,
            result => {
                this.setState({
                    data: result
                })
            }, () => { },
            BASE_API
        );
    }

    componentDidMount() {
        const { id, slug } = this.props.match.params;
        this.setState({
            id: id,
            slug: slug
        }, () => this.onloadDetail(id))
    }

    componentWillReceiveProps(props) {

        const { id, slug } = props.match.params;
        this.setState({
            id: id,
            slug: slug
        }, () => this.onloadDetail(id))
    }
    render() {
        if (isMobile()) return this.renderMobile();
        return this.renderDesktop();
    }

    renderMobile() {
        let {
            data,
            id,
            page
        } = this.state;
        return (
            <div className="components-mobile">
                <div className="articles-slide">
                    <nav className="sub-nav">
                        <ol className="breadcrumbs text-summary text-row-1 mr20">
                            <li>
                                <NavLink to="/">Home</NavLink>
                            </li>

                            <li>
                                <a>{data && data.description}</a>
                            </li>
                        </ol>

                        {/* <form className="search">
                            <i className="fas fa-search" />
                            <input type="search" placeholder="Search" />
                        </form> */}
                    </nav>

                    <div className="article-container">
                        {/* <section className="article-sidebar">
                            <ArticleSidebar
                                articleSlide={data.id}
                            />
                            <i className="fas fa-chevron-down" />
                        </section> */}

                        <article className="article">

                            <div className="article-content">
                                {
                                    data && <div className="article-body" dangerouslySetInnerHTML={{ __html: data.details }} />

                                }
                            </div>

                        </article>
                    </div>
                </div>
            </div>
        );
    }

    renderDesktop() {
        let {
            data,
            id,
            page
        } = this.state;
        console.log('datapage', data && data)
        return (
            <div className="components-pc">
                <div className="content-main articles-slide">
                    <div className="container">
                        <nav className="sub-nav">
                            <ol className="breadcrumbs text-summary text-row-1 mr20">
                                <li>
                                    <NavLink to="/">Home</NavLink>
                                </li>

                                <li>
                                    <a>{data && data.fullName}</a>
                                </li>
                            </ol>

                            {/* <form className="search">
                                <i className="fas fa-search" />
                                <input type="search" placeholder="Search" />
                            </form> */}
                        </nav>

                        <div className="article-container">
                            {/* <section className="article-sidebar">
                                <ArticleSidebar
                                    articleSlide={data && data.id}
                                />
                            </section> */}

                            <article className="article">
                                <div className="article-content">
                                    {
                                        data && <div className="article-body" dangerouslySetInnerHTML={{ __html: data.details }} />

                                    }
                                </div>

                            </article>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Index;

import React from "react";
import Dropzone from "react-dropzone";


export class ApiIssue extends React.Component {
    state = {
    };

    render() {
        return(
            <div>
            </div>
        )
    }
}


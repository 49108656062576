import React from "react";
import { Link } from "react-router-dom";
import { Route, Switch } from "react-router";
import './style.scss';
import '../style/_style.scss';
import '../../../assets/common.scss';
import { FILE_UPLOAD_WEB_CONTENT, BASE_API } from "../../../constants/appSettings";
import { get, post } from "../../../api/index";
import Slider from "react-slick";
import { createSlugName } from "../../../utils/common";


// import YouTube from 'react-youtube';
// import { Player } from 'video-react';
import YouTube from '@u-wave/react-youtube';
import { PlayerControl } from "../../common/video";

const img1 = require('../../../assets/images/plan9.PNG')
const img2 = require('../../../assets/images/plan8.PNG')

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listBanner1: [
                {
                    "idYoutube": 'sEGrN1Q8CAQ',
                    "src": 'http://charge-it-now.com/page-templates/assets/video/Video1-AcceptingPaymentsFINAL.mp4',
                    "photoDesktop": img1,
                    "title": "Watch how simple it is...",
                    "description": "We have put together some video tutorials on how to use our software.",
                    "details": "<div><p>eqwe</p><p>qw</p><p>eq</p><p>we</p><p>qweq</p></div>",
                    "link": "#",
                    "index": 1,
                    "target": false,
                    "id": 1,
                    "status": 1,
                    "dateCreate": "2019-07-15T14:01:46.8250691",
                    "userCreate": 0,
                    "dateModify": null,
                    "userModify": null,
                    "isDelete": null,
                    "deletedTime": null,
                    "deletedByUserId": null
                },
                {
                    "idYoutube": 'dxdL_LyebWI',
                    "src": 'http://171.244.38.5:2581/Uploads/21112019090631AM_MeetCloverMini..mp4',
                    "photoDesktop": img2,
                    "title": "Get started and save",
                    "description": "The all-in-one mini POS system for any business. No extra costs. No hidden fees.",
                    "details": "<div>aaaaa</div>",
                    "link": "#",
                    "index": 2,
                    "target": false,
                    "id": 2,
                    "status": 1,
                    "dateCreate": "2019-07-15T14:02:20.6640045",
                    "userCreate": 0,
                    "dateModify": null,
                    "userModify": null,
                    "isDelete": null,
                    "deletedTime": null,
                    "deletedByUserId": null
                }
            ]
        };
    }
    // getBanner1() {
    //     get('web/api/page-home/slide-content',
    //         result => {
    //             this.setState({
    //                 listBanner1: result
    //             }, () => {
    //                 this.setState({
    //                     loadPage: false
    //                 });
    //             });
    //         }, () => { },
    //         BASE_API
    //     )
    // }

    _handleCheckWindowSize() {
        let width = window.innerWidth;
        this.setState({
            isMoble: false
        }, () => {
            this.setState({
                isMoble: width < 992
            })
        })

    }
    componentDidMount() {
        // this.getBanner1()
        this._handleCheckWindowSize();
        window.addEventListener("resize", () => {
            this._handleCheckWindowSize()
        })
        window.$('.carousel .downpaneSlided').matchHeight();

    }
    render() {
        const settings = {
            dots: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false,
            speed: 1000,
            autoplaySpeed: 10000,
            cssEase: "linear"
        };

        let { isMoble } = this.state
        let { listBanner1 } = this.state

        return (

            <div id="indexDownPane" className="carousel slide" data-ride="carousel">
                <Slider {...settings}>
                    {
                        listBanner1 && listBanner1.map((item, index) =>

                            item.link == '#' ?
                                // <Link to={("/articles-slide-content/" + createSlugName(item.description) + "/" + item.id)}>
                                <Link to="#">
                                    <div key={index} className={"down-pane downpaneSlide" + (isMoble ? ' noneDown' : '')}>

                                        <div className="down-info">
                                            <div className="title-info">{item.title}</div>
                                            <div className="desc-info">{item.description}</div>
                                            <div className="text-detail"></div>
                                            {/* <div dangerouslySetInnerHTML={{ __html: item.details }} className="text"></div> */}
                                            {
                                                item.id == 1 &&
                                                <div>
                                                    <ul className='text-left'>
                                                        <li className="videos_item">
                                                            <button type="button" class="videos_link"> Accepting Payments </button>
                                                        </li>
                                                        <li className="videos_item">
                                                            <button type="button" class="videos_link">  Managing Transactions </button>
                                                        </li>
                                                        <li className="videos_item">
                                                            <button type="button" class="videos_link"> Billing Plans </button>
                                                        </li>
                                                        <li className="videos_item">
                                                            <button type="button" class="videos_link"> Building Receipts  </button>
                                                        </li>
                                                        <li className="videos_item">
                                                            <button type="button" class="videos_link"> Dashboard and Notifications  </button>
                                                        </li>
                                                        <li className="videos_item">
                                                            <button type="button" class="videos_link"> Reporting  </button>
                                                        </li>
                                                        <li className="videos_item">
                                                            <button type="button" class="videos_link"> Creating Customers  </button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            }
                                            {
                                                item.id == 2 &&
                                                <div>
                                                    <ul className='text-left'>
                                                        <li className='mb-3 mt-3'><span style={{ display: 'block' }}>Small but powerful</span>Mini is small enough to fit into any space, but packs plenty of POS power to run your full house, front to back.</li>
                                                        <li className='mb-3'><span style={{ display: 'block' }}>Payments and deposits made easy</span>Swipe, dip, tap, or take cash—accept all the ways your customers like to pay. And get the funds in your bank account as fast as the next business day.</li>
                                                        <li className='mb-3'><span style={{ display: 'block' }}>Future-proof your business</span>Mini can be as minimal or full-featured as you want it to be. And it will always grow and scale with your business.</li>
                                                        <li className='mb-3'><span style={{ display: 'block' }}>Stay on top of your numbers</span>Monitor your sales, refunds, and best-selling items from any computer or mobile device.</li>

                                                    </ul>

                                                </div>
                                            }
                                            {/* <div className="down-btn-pane">
                                                <a>Shop</a>
                                            </div> */}
                                        </div>
                                        <div className="img-slideDown" style={{ background: '#000000' }}>
                                            {/* <Player
                                                playsInline
                                                poster="/assets/poster.png"
                                                src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4"
                                            /> */}
                                            {/* <video style={{ width: '100%', height: '100%' }} class="video-heading-container__video" autoplay="" loop="" muted="" playsinline=""><source src={item.src} type="video/mp4" /></video> */}
                                            {/* <img src={item.photoDesktop}></img> */}
                                            {/* <YouTube
                                                video={item.idYoutube}
                                                autoplay
                                                width="100%"
                                                height="100%"
                                                muted='true'
                                                controls='false'
                                            /> */}
                                            <PlayerControl
                                                src={item.src}
                                            />
                                        </div>
                                    </div>
                                </Link> :
                                item.target ?
                                    <a target="_blank" href={item.link}>
                                        <div key={index} className={"down-pane downpaneSlide" + (isMoble ? ' noneDown' : '')}>
                                            <div className="img-slideDown" style={{ background: '#000000' }}>
                                                <img src={item.photoDesktop}></img>
                                            </div>
                                            <div className="down-info">
                                                <div className="title-info">{item.title}</div>
                                                <div className="desc-info">{item.description}</div>
                                                <div className="down-btn-pane">
                                                    <span>Shop</span>
                                                </div>
                                            </div>
                                        </div>
                                    </a> :
                                    <Link to={item.link}>
                                        <div key={index} className={"down-pane downpaneSlide" + (isMoble ? ' noneDown' : '')}>
                                            <div className="img-slideDown" style={{ background: '#000000' }}>
                                                <img src={item.photoDesktop}></img>
                                            </div>
                                            <div className="down-info">
                                                <div className="title-info">{item.title}</div>
                                                <div className="desc-info">{item.description}</div>
                                                <div className="down-btn-pane">
                                                    <a>Shop</a>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                            // <Link to={item.target ? item.link : ("/articles-slide/" + createSlugName(item.title) + "/" + item.id)}>

                            // <div key={index} className={"down-pane downpaneSlide" + (isMoble ? ' noneDown' : '')}>
                            //     <div className="img-slideDown">
                            //         <img src={API + item.photoDesktop}></img>
                            //     </div>
                            //     <div className="down-info">
                            //         <div className="title-info">{item.title}</div>
                            //         <div className="desc-info">{item.description}</div>
                            //         <div className="down-btn-pane">
                            //             <a className="app-store"></a>
                            //             <a className="google-play"></a>
                            //         </div>
                            //     </div>
                            // </div>
                            // </Link>
                        )
                    }
                </Slider>
            </div>
        );
    }
}

export default Index;

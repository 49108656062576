import React from "react";
import './style/style.css';
import './style/style-mobile.css';
import { post, get, postFormData } from "../../api";
// import { BASE_API_WEB_CONTENT } from "../../constants/appSettings";
import { BASE_API, EMAIL } from "../../constants/appSettings";

import { isMobile, putInProcess, showError, showInfo, showSuccess, takeOutProcess } from "../../utils/app";
import { NavLink } from "react-router-dom";
import { formatDate2 } from "../../utils/common";
import { Alerts } from "../common/alerts";
import Select from 'react-select';
import Dropzone from "react-dropzone";
import { getCurrentUser, isSignedIn } from "../../auth";
import { validate } from "../../utils/validators";

import { ApiIssue } from "./api-issue";
import { BusinessCommunication } from "./business-communication";
import { DevelopmentSuggestions } from "./development-suggestions";
import { HelpWithAccount } from "./help-with-account";
import { HelpWithClientsIssue } from "./help-with-clients-issue";
import { HelpWithDeposits } from "./help-with-deposits";
import { HelpWithGoogleAuth } from "./help-with-google-auth";
import { HelpWithSMSAuth } from "./help-with-sms-auth";
import { HelpWithTrade } from "./help-with-trade";
import { HelpWithWithdrawals } from "./help-with-withdrawals";
import { NotReceivingEmail } from "./not-receiving-email";
import { Promotions } from "./promotions";
import { SecurityIssue } from "./security-issue";
import { ServiceDescription } from "./service-description";

const isAuthenticated = isSignedIn();
const user = getCurrentUser();

class Index extends React.Component {

    state = {
        selectedOptionTicket: null,
        selectedOptionTicketTypes: null,
        optionTicket: [],
        optionTicketTypes: [],
        files: [],
        ticketLayout: false,
        ticketLayoutHtml: "",
        ticketTypesLayout: false,
        titleTicket: "",
        description: "",
        dataOption: [],

        email: user && user.email != null ? user.email : "",
    };

    getDataOption = (text) => {

        this.setState({ dataOption: text });
    };

    handleChangeTicket = (selectedOptionTicket) => {
        this.setState({
            selectedOptionTicket,
            ticketLayout: true,
            ticketTypesLayout: false,
            selectedOptionTicketTypes: null,
            description: ""
        });
        this.onloadDataSelectTicketTypes(selectedOptionTicket.value);
    };

    handleChangeTicketTypes = (selectedOptionTicketTypes) => {
        get("/web/api/ticket-type/" + selectedOptionTicketTypes.value,
            result => {
                this.setState({
                    selectedOptionTicketTypes,
                    ticketTypesLayout: true,
                    description: result.description,
                });
            }, () => {
                this.setState({
                    ticketTypesLayout: false,
                    description: "",
                });
            },
            BASE_API
        );
    };

    onloadDataSelectTicket() {
        get("/web/api/ticket",
            result => {
                var options = [];
                if (result.length > 0) {
                    for (var i = 0; i < result.length; i++) {
                        options.push({
                            value: result[i].id,
                            label: result[i].fullName
                        })
                    }
                }

                this.setState({
                    optionTicket: options,
                });
            }, () => {
                this.setState({
                    optionTicket: [],
                    description: "",
                });
            },
            BASE_API
        );
    }

    onloadDataSelectTicketTypes(id) {
        get("/web/api/ticket-type/list/" + id,
            result => {
                var options = [];
                if (result.length > 0) {
                    for (var i = 0; i < result.length; i++) {
                        options.push({
                            value: result[i].id,
                            label: result[i].fullName
                        })
                    }
                }

                this.setState({
                    optionTicketTypes: options,
                }, () => {
                    this.renderTicketLayout(id);

                });
            }, result => {
                this.setState({
                    optionTicketTypes: [],
                    description: "",
                    ticketLayoutHtml: "",
                });

                if (result.errors == "Not found") {
                    this.setState({
                        ticketTypesLayout: true
                    });
                }
            },
            BASE_API
        );
    }

    onDrop(files) {
        if (files.length > 0) {
            for (let i = 0; i < files.length; i++) {
                this._addFile(files[i]);
            }
        }
    }

    _addFile = (file) => {
        let formData = new FormData();
        formData.append("file", file);

        let { files } = this.state;

        postFormData("api/upload", formData, result => {
            let file = {
                relativePath: result.relativePath
            };
            files.push(file);
            this.setState({ files: files });
        },
            () => { }, BASE_API);
    };

    renderTicketLayout = (id) => {
        switch (id) {
            case 1:
                this.setState({
                    ticketLayoutHtml: <HelpWithDeposits getDataOption={(e) => this.getDataOption(e)} />,
                    titleTicket: "Deposit Issue Type",
                });
                break;
            case 2:
                this.setState({
                    ticketLayoutHtml: <HelpWithWithdrawals getDataOption={(e) => this.getDataOption(e)} />,
                    titleTicket: "Withdrawal Issue Type",
                });
                break;
            case 3:
                this.setState({
                    ticketLayoutHtml: <NotReceivingEmail getDataOption={(e) => this.getDataOption(e)} />,
                    titleTicket: "",
                });
                break;
            case 4:
                this.setState({
                    ticketLayoutHtml: <HelpWithGoogleAuth getDataOption={(e) => this.getDataOption(e)} />,
                    titleTicket: "Google Auth(2FA) Issue Type",
                });
                break;
            case 5:
                this.setState({
                    ticketLayoutHtml: <ApiIssue getDataOption={(e) => this.getDataOption(e)} />,
                    titleTicket: "API Issue",
                });
                break;
            case 6:
                this.setState({
                    ticketLayoutHtml: <BusinessCommunication getDataOption={(e) => this.getDataOption(e)} />,
                    titleTicket: "Business Communication",
                });
                break;
            case 7:
                this.setState({
                    ticketLayoutHtml: <DevelopmentSuggestions getDataOption={(e) => this.getDataOption(e)} />,
                    titleTicket: "",
                });
                break;
            case 8:
                this.setState({
                    ticketLayoutHtml: <HelpWithAccount getDataOption={(e) => this.getDataOption(e)} />,
                    titleTicket: "Help with Account",
                });
                break;
            case 9:
                this.setState({
                    ticketLayoutHtml: <HelpWithClientsIssue getDataOption={(e) => this.getDataOption(e)} />,
                    titleTicket: "Clients Issue Type",
                });
                break;
            case 10:
                this.setState({
                    ticketLayoutHtml: <HelpWithSMSAuth getDataOption={(e) => this.getDataOption(e)} />,
                    titleTicket: "SMS Issue Type",
                });
                break;
            case 11:
                this.setState({
                    ticketLayoutHtml: <HelpWithTrade getDataOption={(e) => this.getDataOption(e)} />,
                    titleTicket: "Trade Issue Type",
                });
                break;
            case 12:
                this.setState({
                    ticketLayoutHtml: <Promotions getDataOption={(e) => this.getDataOption(e)} />,
                    titleTicket: "Promotions",
                });
                break;
            case 13:
                this.setState({
                    ticketLayoutHtml: <SecurityIssue getDataOption={(e) => this.getDataOption(e)} />,
                    titleTicket: "",
                });
                break;
            case 14:
                this.setState({
                    ticketLayoutHtml: <ServiceDescription getDataOption={(e) => this.getDataOption(e)} />,
                    titleTicket: "Service Description",
                });
                break;
            default:
                this.setState({
                    ticketLayoutHtml: "",
                    titleTicket: "",
                });
                break;
        }
    };

    render() {
        if (isMobile()) return this.renderMobile();
        return this.renderDesktop();
    }

    componentDidMount() {
        this.onloadDataSelectTicket();
    }

    submitForm = () => {

        var refs = this.refs;



        validate(
            [
                refs.email,
                refs.subject,
                refs.description,
            ],
            () => {
                putInProcess(refs.btnSubmit);

                let { selectedOptionTicket, selectedOptionTicketTypes, dataOption } = this.state
                var tempData = [
                    {
                        value: selectedOptionTicket && selectedOptionTicket.label,
                        title: selectedOptionTicket && selectedOptionTicket.label
                    },
                    {
                        value: selectedOptionTicketTypes && selectedOptionTicketTypes.label,
                        title: selectedOptionTicketTypes && selectedOptionTicketTypes.label
                    }
                ]
                let tempArray = tempData
                tempArray = tempArray.concat(dataOption)
                this.setState({
                    dataOption: tempArray
                }, () => {
                    let param = {
                        "email": refs.email.value,
                        // "isLogin": user && user.email != null ? true : false,
                        "dataOption": this.state.dataOption,
                        "subject": refs.subject.value,
                        // "dateCreate": new Date().toISOString(),
                        "requestReplies": {
                            "reply": refs.description.value,
                            // "dateCreate": new Date().toISOString(),
                            "requestFiles": this.state.files

                        },
                    };
                    console.log('param', param)
                    post("web/api/request", param,
                        result => {

                            window.sessionStorage.setItem(EMAIL, JSON.stringify(refs.email.value))


                            takeOutProcess(refs.btnSubmit);

                            if (user && user.email == null) {
                                refs.email.value = "";
                            }

                            refs.subject.value = "";
                            refs.description.value = "";

                            this.setState({
                                files: []
                            });

                            showSuccess("Success, check your email");
                        }, () => {
                            takeOutProcess(refs.btnSubmit)
                            this.setState({
                                dataOption: []
                            })
                        },
                        BASE_API
                    );
                })
            }
        );
    };

    renderMobile() {
        let {
            selectedOptionTicket,
            optionTicket,
            selectedOptionTicketTypes,
            optionTicketTypes,
            ticketLayout,
            ticketTypesLayout,
            description,
            ticketLayoutHtml,
            titleTicket,
            email,

        } = this.state;

        const files = this.state.files.map(file => (
            <li key={file.fileName}>
                {file.fileName}
            </li>
        ));
        return (
            <div className="components-mobile">
                <div className="submit-request">
                    <nav className="sub-nav">
                        <ol className="breadcrumbs">
                            <li>
                                <NavLink to="/">Home</NavLink>
                            </li>
                            <li>
                                <a>Submit a request</a>
                            </li>
                        </ol>

                        {/* <form className="search">
                            <i className="fas fa-search" />
                            <input type="search" placeholder="Search" />
                        </form> */}
                    </nav>
                    <div className="article-container">
                        <Alerts
                            status="warning"
                            message="Tips: Feedback and responses are in your Email;Be precise in picking the Issue Type as this will greatly expedite the support process"
                        />
                        <h1 className="mb20">Submit a request</h1>
                        <div className="request-form-box mt20">
                            <form>
                                <div className="mb15">
                                    <p className="mb05">Please select your issue below.</p>
                                    <Select
                                        value={selectedOptionTicket}
                                        onChange={
                                            this.handleChangeTicket
                                        }
                                        options={optionTicket}

                                    />
                                </div>
                                {
                                    ticketLayout == true &&
                                    optionTicketTypes.length > 0 &&
                                    <div className="mb15">
                                        <p className="mb05">{titleTicket} <span className="color-red">*</span></p>
                                        <Select
                                            value={selectedOptionTicketTypes}
                                            onChange={this.handleChangeTicketTypes}
                                            options={optionTicketTypes}
                                        />
                                    </div>
                                }

                                {
                                    description != "" &&
                                    <div className="article-body mb30" dangerouslySetInnerHTML={{ __html: description }} />
                                }

                                {
                                    ticketTypesLayout == true
                                    &&
                                    <div>
                                        {ticketLayoutHtml != "" && ticketLayoutHtml}
                                        <div className="mb15">
                                            <p className="mb05">Account Email <span className="color-red">*</span></p>
                                            <div className="filed">
                                                <div className="ct-filed">
                                                    <label className="text-layout-2">
                                                        <input type="text"
                                                            value={email}
                                                            onChange={e => this.setState({ email: e.target.value })}
                                                            ref="email"
                                                            name="Email"
                                                            validators="email"
                                                        />
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb15">
                                            <p className="mb05">Subject <span className="color-red">*</span></p>
                                            <div className="filed">
                                                <div className="ct-filed">
                                                    <label className="text-layout-2">
                                                        <input type="text"
                                                            ref="subject"
                                                            name="Subject"
                                                            validators="not-empty"
                                                        />
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb15">
                                            <p className="mb05">Description <span className="color-red">*</span></p>
                                            <div className="filed">
                                                <div className="ct-filed">
                                                    <label className="text-layout-2">
                                                        <textarea rows="5"
                                                            ref="description"
                                                            name="Description"
                                                            validators="not-empty"
                                                        />
                                                    </label>
                                                </div>
                                            </div>
                                            <p><i>Please enter the details of the request. Our support staff will respond it as soon as possible.</i></p>
                                        </div>
                                        <div className="mb15">
                                            <p className="mb05">Attachments</p>
                                            <div className="image">
                                                <div className="dropzone">
                                                    <Dropzone
                                                        onDrop={this.onDrop.bind(this)}
                                                        onFileDialogCancel={() => this.setState({ files: [] })}
                                                    >
                                                        {({ getRootProps, getInputProps }) => (
                                                            <div {...getRootProps()}>
                                                                <input {...getInputProps()} />
                                                                {
                                                                    files.length == 0 ?
                                                                        <p><i className="fas fa-paperclip" /> Drop files here, or click to select files</p>
                                                                        :
                                                                        <aside>
                                                                            <ul>{files}</ul>
                                                                        </aside>
                                                                }
                                                            </div>
                                                        )}
                                                    </Dropzone>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="clear-fix filed">
                                            <input
                                                className="btn btn-default bg-orange pull-right"
                                                aria-current="true"
                                                ref="btnSubmit"
                                                type="button"
                                                value="Submit"
                                                onClick={this.submitForm}
                                            />
                                            <div className="lds-facebook pull-right">
                                                <div />
                                                <div />
                                                <div />
                                            </div>
                                        </div>
                                    </div>
                                }
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderDesktop() {
        let {
            selectedOptionTicket,
            optionTicket,
            selectedOptionTicketTypes,
            optionTicketTypes,
            ticketLayout,
            ticketTypesLayout,
            description,
            ticketLayoutHtml,
            titleTicket,
            email,
            dataOption
        } = this.state;

        const files = this.state.files.map(file => (
            <li key={file.relativePath}>
                {file.relativePath}
            </li>
        ));
        console.log('files', this.state.files)
        return (
            <div className="components-pc">
                <div className="content-main submit-request">
                    <div className="container">
                        <nav className="sub-nav">
                            <ol className="breadcrumbs">
                                <li>
                                    <NavLink to="/">Home</NavLink>
                                </li>
                                <li>
                                    <a>Submit a request</a>
                                </li>
                            </ol>

                            {/* <form className="search">
                                <i className="fas fa-search" />
                                <input type="search" placeholder="Search" />
                            </form> */}
                        </nav>
                        <div className="article-container">
                            <Alerts
                                status="warning"
                                message="Tips: Feedback and responses are in your Email;Be precise in picking the Issue Type as this will greatly expedite the support process"
                            />
                            <h1 className="mb20">Submit a request</h1>
                            <div className="request-form-box mt20">
                                <form>
                                    <div className="mb15">
                                        <p className="mb05">Please select your issue below.</p>
                                        <Select
                                            value={selectedOptionTicket}
                                            onChange={this.handleChangeTicket}
                                            options={optionTicket}

                                        />
                                    </div>
                                    {
                                        ticketLayout == true &&
                                        optionTicketTypes.length > 0 &&
                                        <div className="mb15">
                                            <p className="mb05">{titleTicket} <span className="color-red">*</span></p>
                                            <Select
                                                value={selectedOptionTicketTypes}
                                                onChange={this.handleChangeTicketTypes}
                                                options={optionTicketTypes}

                                            />
                                        </div>
                                    }

                                    {
                                        description != "" &&
                                        <div className="article-body mb30" dangerouslySetInnerHTML={{ __html: description }} />
                                    }

                                    {
                                        ticketTypesLayout == true
                                        &&
                                        <div>
                                            {ticketLayoutHtml != "" && ticketLayoutHtml}
                                            <div className="mb15">
                                                <p className="mb05">Account Email <span className="color-red">*</span></p>
                                                <div className="filed">
                                                    <div className="ct-filed">
                                                        <label className="text-layout-2">
                                                            <input type="text"
                                                                value={email}
                                                                onChange={e => this.setState({ email: e.target.value })}
                                                                ref="email"
                                                                name="Email"
                                                                validators="email"
                                                            />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb15">
                                                <p className="mb05">Subject <span className="color-red">*</span></p>
                                                <div className="filed">
                                                    <div className="ct-filed">
                                                        <label className="text-layout-2">
                                                            <input type="text"
                                                                ref="subject"
                                                                name="Subject"
                                                                validators="not-empty"
                                                            />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb15">
                                                <p className="mb05">Description <span className="color-red">*</span></p>
                                                <div className="filed">
                                                    <div className="ct-filed">
                                                        <label className="text-layout-2">
                                                            <textarea rows="5"
                                                                ref="description"
                                                                name="Description"
                                                                validators="not-empty"
                                                            />
                                                        </label>
                                                    </div>
                                                </div>
                                                <p><i>Please enter the details of the request. Our support staff will respond it as soon as possible.</i></p>
                                            </div>
                                            <div className="mb15">
                                                <p className="mb05">Attachments</p>
                                                <div className="image">
                                                    <div className="dropzone">
                                                        <Dropzone
                                                            onDrop={this.onDrop.bind(this)}
                                                            onFileDialogCancel={() => this.setState({ files: [] })}
                                                        >
                                                            {({ getRootProps, getInputProps }) => (
                                                                <div {...getRootProps()}>
                                                                    <input {...getInputProps()} />
                                                                    {
                                                                        files.length == 0 ?
                                                                            <p><i className="fas fa-paperclip" /> Drop files here, or click to select files</p>
                                                                            :
                                                                            <aside>
                                                                                <ul>{files}</ul>
                                                                            </aside>
                                                                    }
                                                                </div>
                                                            )}
                                                        </Dropzone>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="clear-fix filed">
                                                <input
                                                    className="btn btn-default bg-orange pull-right"
                                                    aria-current="true"
                                                    ref="btnSubmit"
                                                    type="button"
                                                    value="Submit"
                                                    onClick={this.submitForm}
                                                />
                                                <div className="lds-facebook pull-right">
                                                    <div />
                                                    <div />
                                                    <div />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Index;


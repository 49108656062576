import React from "react";
import '../../../assets/common.scss';
import { BASE_API } from "../../../constants/appSettings";
import { get, post } from "../../../api/index";
import Barlink from "../bar-link";
import Banner from "../banner";
import DownPaneSlide from "../downpane-slide";
import { Link } from "react-router-dom";
import ResponsiveAbount from "../responsiveAbout";

import './style.scss';
import $ from 'jquery'
class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMoble: false,
            listBanner: null,
            barLinkPOS: [
                {
                    value: 'Free POS Software'
                },
                {
                    value: 'Rất Dễ Dùng'
                },
                {
                    value: 'Chia Turn'
                }
            ],
            PosBanner: [
                {
                    id: 1,
                    background: require('../../../assets/images/eeee.png'),
                    backgroundMobile: require('../../../assets/images/eeee.png'),
                }
            ],
            listAboutPOS: [
                {
                    id: 5,
                    img: require('../../../assets/images/posprinter.jpg'),
                    title: 'Printer',
                    desc: 'Small in size, built to do business wherever you are.'

                },
                {
                    id: 6,
                    img: require('../../../assets/images/cashdrawer.png'),
                    title: 'Cash drawer',
                    desc: 'An all-in-one point-of-sale and payments system.'

                },
                {
                    id: 7,
                    img: require('../../../assets/images/ipad.jpg'),
                    title: 'Ipad',
                    desc: 'Compact countertop POS built for speed.'

                },
                {
                    id: 8,
                    img: require('../../../assets/images/computer.png'),
                    title: 'Computer',
                    desc: 'The all-in-one contactless, chip and swipe card reader.'
                }
            ]
        };
    }

    getBanner() {
        get('web/api/page-home/slide-banner',
            result => {
                this.setState({
                    listBanner: result
                }, () => {
                    this.setState({
                        loadPage: false
                    });
                });
            }, () => { },
            BASE_API
        )
    }


    _handleCheckWindowSize() {
        let width = window.innerWidth;
        this.setState({
            isMoble: false
        }, () => {
            this.setState({
                isMoble: width < 768
            })
        })

    }

    componentDidMount() {
        this.getBanner();
        this._handleCheckWindowSize();
        window.addEventListener("resize", () => {
            this._handleCheckWindowSize()
        })

    }
    render() {
        const settings = {
            dots: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            speed: 1000,
            autoplaySpeed: 4000,
            cssEase: "linear"
        };
        let { barLinkPOS, PosBanner, isMoble, listAboutPOS } = this.state

        return (
            <div>
                <Banner Banner={PosBanner} />
                <Barlink barLink={barLinkPOS} />
                <ResponsiveAbount listAbout={listAboutPOS} />
                <DownPaneSlide />
            </div>
        );
    }
}

export default Index;

import React from "react";
import { Link } from "react-router-dom";
import { Route, Switch } from "react-router";
import './style.scss';
import '../../../assets/common.scss';
import Slider from "react-slick";
import TextInput from '../../common/text-input';
import { showError, showSuccess } from "../../../utils/app";
import {
    notEmpty,
    emailAddress,
    justNumber,
    isValid
} from "../../../utils/validators"
import { BASE_API } from '../../../constants/appSettings'
import { post } from '../../../api'
import Modal from '../../common/modal'
import { PlayerControl } from "../../common/video";
import Select from "../../common/select"
const validationData = {
    email: [notEmpty, emailAddress],
    name: [notEmpty],
    phone: [notEmpty, justNumber],
    test: [notEmpty]
};

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openFormSuccessbutton: false,
            onClose: false,
            listImg: [
                {
                    img: require('../../../assets/images/credit2.PNG')
                },
                {
                    img: require('../../../assets/images/dg1.PNG')
                },
                {
                    img: require('../../../assets/images/dg2.PNG')
                }
            ],
            isMoble: false,
            activeYN: 2,
            name: '',
            businessName: '',
            email: '',
            phone: '',
            Current: '',
            industry: 1,
            isProcess: false
        };
    }
    _handleCheckWindowSize() {
        let width = window.innerWidth;
        this.setState({
            isMoble: false
        }, () => {
            this.setState({
                isMoble: width < 568
            })
        })

    }



    _submitCredit() {

        // if (!isValid(validationData)) {
        //     return
        // }
        var checkemail = false;
        var checkphone = false;
        this.setState({ isActiveSubmit: false })
        let { name, businessName, email, phone, Current, industry, activeYN, isProcess } = this.state

        if (activeYN == 2) {
            showError("Do you currently process credit cards?")
            return
        }
        if (industry == 1) {
            showError("Please select industry")
            return
        }



        let param = {
            fullName: name,
            businessName: businessName,
            email: email,
            phone: phone,
            current: Current
        }

        if (isProcess == false) {
            this.setState({ isProcess: true })
        }

        post('api/send-sms-email', param,
            () => {
                showSuccess("Check sms and email")
                checkphone = true
                this.setState({
                    name: '',
                    businessName: '',
                    email: '',
                    phone: '',
                    Current: '',
                    activeYN: 2,
                    isProcess: false,
                    industry: 1
                });
            }, () => { },
            BASE_API
        )

        this.setState({ openFormSuccessbutton: true })
    }
    componentDidMount() {

        this._handleCheckWindowSize();
        // window.addEventListener("resize", () => {
        //     this._handleCheckWindowSize()
        // })
    }

    render() {
        const settings = {
            dots: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            speed: 1000,
            autoplaySpeed: 4000,
            cssEase: "linear"
        };
        let { isMoble, listImg, activeYN, name, businessName, phone, email, Current, openFormSuccessbutton, onClose, industry, isProcess } = this.state

        return (
            isMoble ?
                <div className="credit-mobile">
                    <div className="content-credit-mobile">
                        <div className="hotline">
                            <div className="text_inner">
                                <h2>CALL US NOW 210 - 607 - 8888</h2>
                                <p>Or complete this form to</p>
                                <span>request a FREE Quote!</span>
                            </div>
                        </div>
                        <div className="body-credit">
                            <div className="img-top-body">
                                <img src={require('../../../assets/images/arrow-below.png')} />
                            </div>
                            <div className="et_pb_text_inner">
                                <div className="home-quote_wrapper">
                                    <ul>
                                        <li className='row item-info'>
                                            <label className='col-lg-12 p-0'>
                                                <TextInput
                                                    className='input-form'
                                                    edit={true}
                                                    multiline={false}
                                                    placeholder={"Full Name"}
                                                    name="name"
                                                    displayName="Name"
                                                    value={name}
                                                    validations={validationData.name}
                                                    onChange={e => {
                                                        this.setState({
                                                            name: e.target.value
                                                        })
                                                    }} />


                                            </label>
                                        </li>
                                        <li className='row item-info'>
                                            <label className='col-lg-12 p-0'>
                                                <TextInput
                                                    className='input-form'
                                                    edit={true}
                                                    multiline={false}
                                                    placeholder={"Business Name"}
                                                    name="business-Name"
                                                    displayName="Business Name"
                                                    value={businessName}
                                                    validations={validationData.name}
                                                    onChange={e => {
                                                        this.setState({
                                                            businessName: e.target.value
                                                        })
                                                    }} />

                                            </label>
                                        </li>
                                        <li className='row item-info'>
                                            <label className='col-lg-12 p-0'>
                                                <TextInput
                                                    className='input-form'
                                                    edit={true}
                                                    multiline={false}
                                                    placeholder={"E-mail"}
                                                    name="email"
                                                    displayName="Email"
                                                    value={email}
                                                    validations={validationData.email}
                                                    onChange={e => {
                                                        this.setState({
                                                            email: e.target.value
                                                        })
                                                    }} />

                                            </label>
                                        </li>
                                        <li className='row item-info'>
                                            <label className='col-lg-12 p-0'>
                                                <TextInput
                                                    className='input-form'
                                                    edit={true}
                                                    multiline={false}
                                                    placeholder={"Phone"}
                                                    name="phone"
                                                    displayName="Phone"
                                                    value={phone}
                                                    validations={validationData.phone}
                                                    onChange={e => {
                                                        this.setState({
                                                            phone: e.target.value
                                                        })
                                                    }} />

                                            </label>
                                        </li>
                                        {/* <li className="check-box">
                                            <span className="title-check">Do you currently process credit cards?</span>
                                            <input type="radio" name="yes" value="1" /> <span>Yes</span>
                                            <input type="radio" name="no" value="0" /> <span>No</span>
                                        </li> */}
                                        <li className="option-YN">
                                            <span className="title-check">Do you currently process credit cards?</span>
                                            <p className="ctys">
                                                <span className={"opys op-yes" + (activeYN == 1 ? ' active' : '')} onClick={() => this.setState({ activeYN: 1 })}>Yes</span>
                                                <span className={"opys op-No" + (activeYN == 0 ? ' active' : '')} onClick={() => this.setState({ activeYN: 0 })}>No</span>
                                            </p>
                                        </li>
                                        <li>
                                            <span className="title-option" style={{ color: 'black', display: 'block' }}>Current Monthly Credit Card Volume:</span>
                                        </li>
                                        <li className='row item-info'>
                                            <label className='col-lg-12 input-current p-0'>
                                                <TextInput
                                                    className='input-form'
                                                    edit={true}
                                                    multiline={false}
                                                    placeholder={""}
                                                    name="current"
                                                    displayName="Current monthly Volume"
                                                    value={Current}
                                                    validations={validationData.phone}
                                                    onChange={e => {
                                                        this.setState({
                                                            Current: e.target.value
                                                        })
                                                    }} />
                                                <span className="dola-p dola-Current">$</span>
                                            </label>
                                        </li>
                                    </ul>
                                </div>
                                <div className='option-industry'>
                                    <Select
                                        name="industry"
                                        title="industry"
                                        value={industry}
                                        options={[
                                            {
                                                value: 1,
                                                text: 'Please select industry'
                                            },
                                            {
                                                value: 2,
                                                text: 'Nails'
                                            },
                                            {
                                                value: 3,
                                                text: 'Hair Salon'
                                            },
                                            {
                                                value: 4,
                                                text: 'Restaurant'
                                            },
                                            {
                                                value: 5,
                                                text: 'Retail'
                                            },
                                            {
                                                value: 6,
                                                text: 'Ecommerce'
                                            },
                                            {
                                                value: 7,
                                                text: 'Doctor'
                                            },
                                            {
                                                value: 8,
                                                text: 'Dentist'
                                            },
                                            {
                                                value: 9,
                                                text: 'Other'
                                            }

                                        ]}
                                        onChange={(value) => this.setState({ industry: value })}
                                    />
                                </div>
                                <div className="btn-submit">
                                    <span className="btn" disabled={isProcess ? "disabled" : ""} onClick={() => this._submitCredit()}>SUBMIT FORM</span>
                                </div>
                            </div>

                        </div>

                    </div>
                    <Modal
                        fullWidth={true}
                        name='Close'
                        open={openFormSuccessbutton}
                        onClose={() =>
                            this.setState({ openFormSuccessbutton: false })
                        }
                        header={
                            <span></span>
                        }
                        className="submitForm"
                        content={
                            <div>
                                <h4 className='mt-3' style={{ fontSize: '1.3em' }}>Thank you for request free quote. Our Sale Representative will contact you shortly.</h4>
                                <p className='text-right' style={{ fontSize: '1.3em' }}> Thank you</p>

                            </div>
                        }
                    />
                </div >
                :
                <div>
                    <div className="credit-card">
                        <div className="credit-left">
                            <div className="content-left">
                                <img className="poster" src={require('../../../assets/images/Rate.gif')} />
                                <div className='text-box-credit' style={{ marginTop: '10px' }}>
                                    <div style={{ marginBottom: '35px' }}>
                                        <p><span><i class="fas fa-check"></i>FREE</span> EMV Terminal</p>
                                        <p><span><i class="fas fa-check"></i>FREE</span> Smart Phone Reader</p>
                                        <p><span><i class="fas fa-check"></i>FREE</span> Terminal Paper Supply</p>
                                        <p><span><i class="fas fa-check"></i></span>No Application Fee</p>
                                        <p><span><i class="fas fa-check"></i></span>No Long Terms Contact</p>
                                        <p><span><i class="fas fa-check"></i></span>No Cancelation Fees</p>
                                        <p><span><i class="fas fa-check"></i></span>Rate Start As Low As 0.10%</p>
                                        <p><span><i class="fas fa-check"></i></span>POS CASH Register Systems</p>
                                        <p><span><i class="fas fa-check"></i></span>Online Reporting and Sales Tracking</p>
                                    </div>
                                </div>
                                {/* <div id="indexCard" className="carousel slide" data-ride="carousel" style={{ width: '250px' }}>
                                    <Slider {...settings}>
                                        {
                                            listImg.map((item, index) =>
                                                <div key={index}>
                                                    <img className="poster" src={item.img} />
                                                </div>
                                            )
                                        }
                                    </Slider>
                                </div>
                                <img className="poster" src={require('../../../assets/images/credit3.jpg')} /> */}
                            </div>
                        </div>
                        <div className="credit-center">

                            <div className="form-center clearfix">
                                <div className='bacground-text'>
                                    <h3 className='title1'>STOP PAYING</h3>
                                    <span className='tt2'>large credit card</span>
                                    <h3 className='tt3'>PROCESSING</h3>
                                    <h2 className='tt4'>FEES</h2>
                                    <p className='tt5'>CALL US NOW</p>
                                    <p className='tt6'>210 - 607 - 8888
                                    <span>or</span>
                                        <span style={{ paddingLeft: '0' }}>Complete this form</span></p>
                                </div>
                                <div className="content-form">
                                    <div className='item-info'>
                                        <TextInput
                                            className='input-form'
                                            edit={true}
                                            multiline={false}
                                            placeholder={"Full Name"}
                                            name="name"
                                            displayName="Name"
                                            value={name}
                                            validations={validationData.name}
                                            onChange={e => {
                                                this.setState({
                                                    name: e.target.value
                                                })
                                            }} />
                                    </div>
                                    <div className='item-info'>
                                        <TextInput
                                            className='input-form'
                                            edit={true}
                                            multiline={false}
                                            placeholder={"Business Name"}
                                            name="business-Name"
                                            displayName="Business Name"
                                            value={businessName}
                                            validations={validationData.name}
                                            onChange={e => {
                                                this.setState({
                                                    businessName: e.target.value
                                                })
                                            }} />
                                    </div>
                                    <div className='item-info'>
                                        <TextInput
                                            className='input-form'
                                            edit={true}
                                            multiline={false}
                                            placeholder={"E-mail"}
                                            name="email"
                                            displayName="Email"
                                            value={email}
                                            validations={validationData.email}
                                            onChange={e => {
                                                this.setState({
                                                    email: e.target.value
                                                })
                                            }} />
                                    </div>
                                    <div className='item-info'>
                                        <TextInput
                                            className='input-form'
                                            edit={true}
                                            multiline={false}
                                            placeholder={"Phone"}
                                            name="phone"
                                            displayName="Phone"
                                            value={phone}
                                            validations={validationData.phone}
                                            onChange={e => {
                                                this.setState({
                                                    phone: e.target.value
                                                })
                                            }} />
                                    </div>
                                    {/* <div className="check-box">
                                        <span>Do you currently process credit cards?</span>
                                        <input type="radio" name="yes" value="1" /> <span>Yes</span>
                                        <input type="radio" name="no" value="0" /> <span>No</span>
                                    </div>
                                    <span style={{ display: 'block', fontSize: '12px', color: 'white', textAlign: 'center' }}>Current Monthly Credit Card Volume</span>
                                    <select className="option-price">
                                        <option value="volvo">$10,000 - $15,000</option>
                                        <option value="saab">$15,000 - $20,000</option>
                                        <option value="mercedes">$20,000 - $25,000</option>
                                        <option value="audi">$25,000 - $30,000</option>
                                    </select> */}
                                    <div className="option-YN">
                                        <span className="title-check">Do you currently process credit cards?</span>
                                        <p className="ctys">
                                            <span className={"opys op-yes" + (activeYN == 1 ? ' active' : '')} onClick={() => this.setState({ activeYN: 1 })}>Yes</span>
                                            <span className={"opys op-No" + (activeYN == 0 ? ' active' : '')} onClick={() => this.setState({ activeYN: 0 })}>No</span>
                                        </p>
                                    </div>
                                    <div>
                                        <span className="title-option" style={{ color: 'black', display: 'block' }}>Current Monthly Credit Card Volume:</span>
                                        <div className="pp">
                                            <div className='item-info input-price'>
                                                <TextInput
                                                    className='input-form'
                                                    edit={true}
                                                    multiline={false}
                                                    placeholder={"Current"}
                                                    name="Current"
                                                    displayName="Current monthly Volume"
                                                    value={Current}
                                                    validations={validationData.phone}
                                                    onChange={e => {
                                                        this.setState({
                                                            Current: e.target.value
                                                        })
                                                    }} />
                                            </div>
                                            <span className="dola-p dola-Current">$</span>
                                        </div>

                                    </div>
                                    <div className='option-industry'>
                                        <Select
                                            name="industry"
                                            title="industry"
                                            value={industry}
                                            options={[
                                                {
                                                    value: 1,
                                                    text: 'Please select industry'
                                                },
                                                {
                                                    value: 2,
                                                    text: 'Nails'
                                                },
                                                {
                                                    value: 3,
                                                    text: 'Hair Salon'
                                                },
                                                {
                                                    value: 4,
                                                    text: 'Restaurant'
                                                },
                                                {
                                                    value: 5,
                                                    text: 'Retail'
                                                },
                                                {
                                                    value: 6,
                                                    text: 'Ecommerce'
                                                },
                                                {
                                                    value: 7,
                                                    text: 'Doctor'
                                                },
                                                {
                                                    value: 8,
                                                    text: 'Dentist'
                                                },
                                                {
                                                    value: 9,
                                                    text: 'Other'
                                                }

                                            ]}
                                            onChange={(value) => this.setState({ industry: value })}
                                        />
                                    </div>

                                    <div className="submit">
                                        <button type='button' className="btn btn-submit" disabled={isProcess ? "disabled" : ""} onClick={() => { this._submitCredit() }}>REQUEST A FREE QUOTE</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="credit-left credit-right">
                            <div className="content-left">
                                <img className="poster" src={require('../../../assets/images/freeclover1-01.jpg')} />
                                <img className="poster" src={require('../../../assets/images/new1.PNG')} />
                                <img className="poster" src={require('../../../assets/images/transaction1.gif')} />
                            </div>
                        </div>
                    </div>
                    <Modal
                        fullWidth={true}
                        name='Close'
                        open={openFormSuccessbutton}
                        onClose={() =>
                            this.setState({ openFormSuccessbutton: false })
                        }
                        header={
                            <span></span>
                        }
                        className="submitForm"
                        content={
                            <div>
                                <h4 className='mt-3' style={{ fontSize: '1.3em' }}>Thank you for request free quote. Our Sale Representative will contact you shortly.</h4>
                                <p className='text-right' style={{ fontSize: '1.3em' }}> Thank you</p>

                            </div>
                        }
                    />
                </div>
        );
    }
}

export default Index;

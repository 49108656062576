import moment from "moment";
import { DATETIME_FORMAT, DATE_FORMAT } from "../constants/appSettings";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
export const jsonFromUrlParams = search => {
  if (!search) return {};

  search = search.replace("?", "");

  return JSON.parse(
    '{"' +
    decodeURI(search)
      .replace(/"/g, '\\"')
      .replace(/&/g, '","')
      .replace(/=/g, '":"') +
    '"}'
  );
};

export const objToQuery = obj => {
  if (!obj) return "";

  var query = [];

  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      query.push(
        encodeURIComponent(prop) + "=" + encodeURIComponent(obj[prop])
      );
    }
  }

  return "?" + query.join("&");
};

export const formatDate = value => {
  return moment(value).format(DATETIME_FORMAT);
};

export const objToArray = obj => {
  var keys = Object.keys(obj);
  var arr = [];
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    arr.push(obj[key]);
  }
  return arr;
};

export const getBrowserName = () => {
  let isOpera =
    (!!window.opr && !!window.opr.addons) ||
    !!window.opera ||
    navigator.userAgent.indexOf(" OPR/") >= 0;
  if (isOpera) return "Opera";

  let isFirefox = typeof InstallTrigger !== "undefined";
  if (isFirefox) return "Firefox";

  let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  if (isSafari) return "Safari";

  let isIE = /*@cc_on!@*/ false || !!document.documentMode;
  if (isIE) return "Internet Explorer";

  let isEdge = !isIE && !!window.StyleMedia;
  if (isEdge) return "Microsoft Edge";

  let isChrome = !!window.chrome && !!window.chrome.webstore;
  if (isChrome) return "Google Chrome";

  return "Unknown";
};

export const confirmSubmit = (title, message, onOK, onCANCEL) => {
  confirmAlert({
    title: title,
    message: message,
    buttons: [
      {
        label: "Có",
        onClick: onOK ? () => onOK() : ""
      },
      {
        label: "Không",
        onClick: onCANCEL ? () => onCANCEL() : ""
      }
    ]
  });
};

export function parseCurrency(value) {
  let array = value.toString().split('.')
  let value1 = array[0];
  let value2 = array[1];
  if (!value1) return
  value = value1.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") + (value2 && (parseInt(value2) > 0) ? ("," + value2) : "");
  return value
}

export const createSlugName = value => {
  var slug;
  slug = value;
  slug = slug.replace(/á|à|ả|ạ|ã|ă|ắ|ằ|ẳ|ẵ|ặ|â|ấ|ầ|ẩ|ẫ|ậ/gi, 'a');
  slug = slug.replace(/é|è|ẻ|ẽ|ẹ|ê|ế|ề|ể|ễ|ệ/gi, 'e');
  slug = slug.replace(/i|í|ì|ỉ|ĩ|ị/gi, 'i');
  slug = slug.replace(/ó|ò|ỏ|õ|ọ|ô|ố|ồ|ổ|ỗ|ộ|ơ|ớ|ờ|ở|ỡ|ợ/gi, 'o');
  slug = slug.replace(/ú|ù|ủ|ũ|ụ|ư|ứ|ừ|ử|ữ|ự/gi, 'u');
  slug = slug.replace(/ý|ỳ|ỷ|ỹ|ỵ/gi, 'y');
  slug = slug.replace(/đ/gi, 'd');
  slug = slug.replace(/\`|\~|\!|\@|\#|\||\$|\%|\^|\&|\*|\(|\)|\+|\=|\,|\.|\/|\?|\>|\<|\'|\"|\:|\;|_/gi, '');
  slug = slug.replace(/ /gi, "-");
  slug = slug.replace(/\-\-\-\-\-/gi, '-');
  slug = slug.replace(/\-\-\-\-/gi, '-');
  slug = slug.replace(/\-\-\-/gi, '-');
  slug = slug.replace(/\-\-/gi, '-');
  slug = '@' + slug + '@';
  slug = slug.replace(/\@\-|\-\@|\@/gi, '');
  return slug;
};
export const formatDate3 = value => {
  var date = moment(value).format('MM-DD-YYYY h:mm:ss a');
  return date;
};
export const formatDate4 = value => {
  return moment(value).format(DATE_FORMAT);
};
export const formatDateTime = value => {
  return moment(value).format(DATETIME_FORMAT);
};
export const formatDate2 = value => {
  let date = new Date(value);
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let dt = date.getDate();

  if (dt < 10) {
    dt = "0" + dt;
  }
  if (month < 10) {
    month = "0" + month;
  }

  return month + "-" + dt + "-" + year;
};


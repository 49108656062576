import React from "react";
import Dropzone from "react-dropzone";


export class HelpWithDeposits extends React.Component {
    state = {
        fullData: [],
    };

    onChange = () => {
        let refs = this.refs;
        let fullData = [];
        let { getDataOption } = this.props;

        let coinName = {
            value: refs.coinName.value,
            title: "Coin Name",
        };

        let transactionId = {
            value: refs.transactionId.value,
            title: "Transaction ID",
        };

        let transactionAddress = {
            value: refs.transactionAddress.value,
            title: "Transaction Address",
        };

        let optional = {
            value: refs.optional.value,
            title: "(Optional)Transaction Paymentid / Memo / Tag",
        };

        fullData.push(coinName);
        fullData.push(transactionId);
        fullData.push(transactionAddress);
        fullData.push(optional);

        this.setState({
            fullData: fullData
        }, () => {
            // getDataOption(JSON.stringify(this.state.fullData))
            // console.log('fulldata', this.state.fullData)
            getDataOption(fullData)
        });
    };

    render() {
        return (
            <div>
                <div className="mb15">
                    <p className="mb05">Coin Name <span className="color-red">*</span></p>
                    <div className="filed">
                        <div className="ct-filed">
                            <label className="text-layout-2">
                                <input type="text"
                                    ref="coinName"
                                    name="Coin Name"
                                    validators="not-empty"
                                    onChange={this.onChange}
                                />
                            </label>
                        </div>
                    </div>
                </div>
                <div className="mb15">
                    <p className="mb05">Transaction ID <span className="color-red">*</span></p>
                    <div className="filed">
                        <div className="ct-filed">
                            <label className="text-layout-2">
                                <input type="text"
                                    ref="transactionId"
                                    name="Transaction ID"
                                    validators="not-empty"
                                    onChange={this.onChange}
                                />
                            </label>
                        </div>
                    </div>
                    <p><i>provide your Transaction ID that can be quickly resolved you issue. (Please enter 'none' ,If you don't have Txid)</i></p>
                </div>
                <div className="mb15">
                    <p className="mb05">Transaction Address <span className="color-red">*</span></p>
                    <div className="filed">
                        <div className="ct-filed">
                            <label className="text-layout-2">
                                <input type="text"
                                    ref="transactionAddress"
                                    name="Transaction Address"
                                    validators="not-empty"
                                    onChange={this.onChange}
                                />
                            </label>
                        </div>
                    </div>
                    <p><i>Please provide your Transaction Address that can be quickly resolved you issue. (Please enter 'none' ,If you don't have Address)</i></p>
                </div>
                <div className="mb15">
                    <p className="mb05">(Optional)Transaction Paymentid / Memo / Tag</p>
                    <div className="filed">
                        <div className="ct-filed">
                            <label className="text-layout-2">
                                <input type="text"
                                    ref="optional"
                                    name="Optional"
                                    validators="not-empty"
                                    onChange={this.onChange}
                                />
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


import React from "react";
import './style.scss';
import '../../assets/common.scss';
import Footer from "../template/footer";
import Header from "../template/header";
import Slider from "react-slick";
import { PlayerControl } from "../common/video";
import DocumentTitle from 'react-document-title';

import $ from "jquery";


class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            slugDetail: '',
            isMoble: false,
            isMobileMini: false,
            titleDocument: '',
            y: 0,
            textBanner: [
                {
                    id: 1,
                    slug: 'clover-flex',
                    titleDocument: 'Clover Flex',
                    // backgroundMobile: require('../../assets/images/clover-flex-banner.jpg'),
                    // background: require('../../assets/images/clover-flex-banner.jpg'),
                    backgroundMobile: 'https://videos.ctfassets.net/5bhn3fhxj0f8/7jhlR99A1UiQiQcGi2guOy/562d518804ef0b13edd5acc3126b0254/flex-preview-3.mp4',
                    background: 'https://videos.ctfassets.net/5bhn3fhxj0f8/7jhlR99A1UiQiQcGi2guOy/562d518804ef0b13edd5acc3126b0254/flex-preview-3.mp4',
                    title: 'Flex',
                    desc: 'Modern payments. Fast and simple.'
                },
                {
                    id: 2,
                    slug: 'clover-station',
                    titleDocument: 'Clover Station the best Point of sale POS in Spring TX 77379',
                    // backgroundMobile: require('../../assets/images/CloverStation2018.jpg'),
                    // background: require('../../assets/images/clover_station.jpg'),
                    backgroundMobile: 'https://videos.ctfassets.net/5bhn3fhxj0f8/5xaaIwUDlK2eyCKEsQEwAo/be481ff13038f42850b5b55e0350da76/station-preview.mp4',
                    background: 'https://videos.ctfassets.net/5bhn3fhxj0f8/5xaaIwUDlK2eyCKEsQEwAo/be481ff13038f42850b5b55e0350da76/station-preview.mp4',
                    title: 'Station',
                    desc: 'Our largest, fastest point of sale system.'
                },
                {
                    id: 3,
                    slug: 'clover-mini',
                    titleDocument: 'Clover Mini',
                    // backgroundMobile: require('../../assets/images/940277645001_5360347718001_4772848443001-vs.jpg'),
                    // background: require('../../assets/images/Clover-Mini-Contact-Form-Background.jpg'),
                    backgroundMobile: 'https://videos.ctfassets.net/5bhn3fhxj0f8/68suWrI3BYEqaiSKOoMGYu/24357f1fe2a3c5897e76470fa3d29c54/Clover_Mini_Preview-1.mp4',
                    background: 'https://videos.ctfassets.net/5bhn3fhxj0f8/68suWrI3BYEqaiSKOoMGYu/24357f1fe2a3c5897e76470fa3d29c54/Clover_Mini_Preview-1.mp4',
                    title: 'Mini',
                    desc: 'A full point of sale system in a small package.'
                },
                {
                    id: 4,
                    slug: 'clover-go',
                    titleDocument: 'Clover Go',
                    // backgroundMobile: require('../../assets/images/clover-go-review-600x325.jpg'),
                    // background: require('../../assets/images/clover-go-banner.jpg'),
                    backgroundMobile: 'https://videos.ctfassets.net/5bhn3fhxj0f8/3IllL7bsqx6AX22cXuqlWO/b4a1ac77e5e5c07574f3b4647b7318f0/clover-go-landing-page-video.mp4',
                    background: 'https://videos.ctfassets.net/5bhn3fhxj0f8/3IllL7bsqx6AX22cXuqlWO/b4a1ac77e5e5c07574f3b4647b7318f0/clover-go-landing-page-video.mp4',
                    title: 'Go',
                    desc: 'Go where your business takes you.'
                },
                {
                    id: 5,
                    // backgroundMobile: require('../../assets/images/ipadnew.png'),
                    // background: require('../../assets/images/ipadnew.png'),
                    backgroundMobile: 'https://videos.ctfassets.net/5bhn3fhxj0f8/3IllL7bsqx6AX22cXuqlWO/b4a1ac77e5e5c07574f3b4647b7318f0/clover-go-landing-page-video.mp4',
                    background: 'https://videos.ctfassets.net/5bhn3fhxj0f8/3IllL7bsqx6AX22cXuqlWO/b4a1ac77e5e5c07574f3b4647b7318f0/clover-go-landing-page-video.mp4',
                    title: 'Printer',
                    desc: 'Go where your business takes you.'
                },
                {
                    id: 6,
                    // backgroundMobile: require('../../assets/images/cqYTlVQYS9aSzoCCiAKT_Cash_Register_Employee67kb.001.jpg'),
                    // background: require('../../assets/images/61mRQApvpOL._SX425_.jpg'),
                    backgroundMobile: 'https://videos.ctfassets.net/5bhn3fhxj0f8/3IllL7bsqx6AX22cXuqlWO/b4a1ac77e5e5c07574f3b4647b7318f0/clover-go-landing-page-video.mp4',
                    background: 'https://videos.ctfassets.net/5bhn3fhxj0f8/3IllL7bsqx6AX22cXuqlWO/b4a1ac77e5e5c07574f3b4647b7318f0/clover-go-landing-page-video.mp4',
                    title: 'Cash drawer',
                    desc: 'Go where your business takes you.'
                },
                {
                    id: 7,
                    // backgroundMobile: require('../../assets/images/banner-ipad.png'),
                    // background: require('../../assets/images/banner-ipad.png'),
                    backgroundMobile: 'https://videos.ctfassets.net/5bhn3fhxj0f8/3IllL7bsqx6AX22cXuqlWO/b4a1ac77e5e5c07574f3b4647b7318f0/clover-go-landing-page-video.mp4',
                    background: 'https://videos.ctfassets.net/5bhn3fhxj0f8/3IllL7bsqx6AX22cXuqlWO/b4a1ac77e5e5c07574f3b4647b7318f0/clover-go-landing-page-video.mp4',
                    title: 'Ipad',
                    desc: 'Go where your business takes you.'
                },
                {
                    id: 8,
                    // backgroundMobile: require('../../assets/images/laptop-computer-e1423765573837-400x200.jpg'),
                    // background: require('../../assets/images/Liveops_Feature-image_cropped_5-tings-to-loin-freelance.png'),
                    backgroundMobile: 'https://videos.ctfassets.net/5bhn3fhxj0f8/3IllL7bsqx6AX22cXuqlWO/b4a1ac77e5e5c07574f3b4647b7318f0/clover-go-landing-page-video.mp4',
                    background: 'https://videos.ctfassets.net/5bhn3fhxj0f8/3IllL7bsqx6AX22cXuqlWO/b4a1ac77e5e5c07574f3b4647b7318f0/clover-go-landing-page-video.mp4',
                    title: 'Computer',
                    desc: 'Go where your business takes you.'
                }
            ],
            portfolio: [
                {
                    id: 1,
                    slug: 'clover-flex',
                    title: 'Clover Flex',
                    img: require('../../assets/images/flex_checkout.png'),
                    titleBox: 'What your Flex can do for you',
                    titleContent: 'Everything your business needs, in one simple, reliable device',
                    subItem: [
                        {
                            titleSub: 'User friendly interface',
                            textSub: 'You and your cashiers can start taking orders with minimal training.'
                        },
                        {
                            titleSub: 'Always connected',
                            textSub: 'Cellular and WiFi connectivity ensures redundancy so you never miss a sale.'
                        },
                        {
                            titleSub: 'In the cloud',
                            textSub: 'Cloud accessible data means you can handle your business from any internet connected device.'
                        }
                    ]
                },
                {
                    id: 2,
                    slug: 'clover-station',
                    title: 'Clover Station',
                    img: require('../../assets/images/laptop_phone_dashboard_v2.png'),
                    titleBox: 'What your Station can do for you',
                    titleContent: 'Manage your business from anywhere',
                    subItem: [
                        {
                            titleSub: 'Streamlined operations',
                            textSub: 'Clover Dashboard is your center of operations, from sales and inventory to promotions and business reports.'
                        },
                        {
                            titleSub: 'In the cloud',
                            textSub: 'Access your data anywhere you need to run your business: on the floor, at the office, or on the road.'
                        },
                        {
                            titleSub: 'Insights at your fingertips',
                            textSub: 'Harness the power of your own data—discover the patterns and trends driving your sales and take a smarter approach to marketing.'
                        }
                    ]
                },
                {
                    id: 3,
                    slug: 'clover-mini',
                    title: 'Clover Mini',
                    img: require('../../assets/images/laptop_phone_reporting.png'),
                    titleBox: 'What your Mini can do for you',
                    titleContent: 'Everything you need, at your fingertips',
                    subItem: [
                        {
                            titleSub: 'User friendly interface',
                            textSub: 'Your staff can start taking orders with minimal training.'
                        },
                        {
                            titleSub: 'Fast processing speed',
                            textSub: 'Cut chip card transaction time down to under 3 seconds—and serve more customers.'
                        },
                        {
                            titleSub: 'Anywhere anytime access',
                            textSub: 'Access your data anywhere you need to run your business: on the floor, at the office, or on the road.'
                        }
                    ]
                },
                {
                    id: 4,
                    slug: 'clover-go',
                    title: 'Clover Go',
                    img: require('../../assets/images/clover-go-payments-device-easy-card-reader-setup.png'),
                    titleBox: 'What your Go can do for you',
                    titleContent: 'Manage your business, on the go',
                    subItem: [
                        {
                            titleSub: 'Ready out of the box',
                            textSub: 'Build orders, take payments, run reports, and email or text receipts, all from the Clover Go app.'
                        },
                        {
                            titleSub: 'Customize your Go',
                            textSub: 'Set discount, tip, and tax rates relevant to your business.'
                        },
                        {
                            titleSub: 'Bird’s eye view of transactions',
                            textSub: 'See your entire transaction history, including all open, pending, and completed transactions.'
                        }
                    ]
                },
                {
                    id: 5,
                    // img: require('../../assets/images/15319669915afae3449e8a5.jpg'),
                    img: '',
                    titleBox: 'What your Printer can do for you',
                    titleContent: '3 inch thermal receipt printer with auto cutter and fast printing speed - 230 mm/sec',
                    subItem: [
                        {
                            titleSub: 'Support',
                            textSub: 'All windows system. DO NOT support Mobile iPhone MAC system. DO NOT have bluetooth/WIFI '
                        },
                        {
                            titleSub: 'Connect',
                            textSub: 'If you wanna connect the printer via LAN cable to share the printer by multiple computers, you can set with the guide of User Manual'
                        },
                        {
                            titleSub: 'Turn on/down/off ',
                            textSub: 'You can turn on/down/off the sound volume by DIP Switch below machine'
                        },
                        {
                            titleSub: 'Add Logo',
                            textSub: 'You can add LOGO on every receipt from "Setting Tool" along with Printer Driver'
                        },
                        {
                            titleSub: '230 MM/sec high printing speed with big gear',
                            textSub: 'Power button in the front side. USB+LAN+Cash Drawer interface'
                        }
                    ]
                },
                {
                    id: 6,
                    img: require('../../assets/images/16_inch_Cash_Drawer_White_w_o_BO_large_eaa6c21d-4bc7-4137-8c2d-bafef797be56_1200x1200.webp'),
                    titleBox: 'What your Cash Drawer can do for you',
                    titleContent: '16" Manual Push Open Cash Register Drawer for Point of Sale (POS) System, White Heavy Duty Till with 5 Bills/8 Coin Slots, Key Lock with Fully Removable Money Tray and Double Media Slots',
                    subItem: [
                        {
                            titleSub: 'DURABLE- Made of heavy gauge steel for strength and protection',
                            textSub: 'A powder-coat finish to resist wear and corrosion giving its professional look'
                        },
                        {
                            titleSub: 'RELIABLE- No power connectivity needed',
                            textSub: 'Manual push open/close feature that opens with minimal effort but keeps it securely shut when closed. Rubber feet to protect surfaces and prevents slipping during use'
                        },
                        {
                            titleSub: 'BUILT FOR SECURITY- 2 media front slots for check, receipt, and bill storage without opening the drawer',
                            textSub: 'Includes 2 keys to lock the drawer and keep valuables safe and secure'
                        },
                        {
                            titleSub: 'COMMERCIAL GRADE DESIGN',
                            textSub: 'Fully removable cash tray that has 5 bills slots with stainless steel money clips and a separate compartment of 8 coins slots to allow extra storage beneath it'
                        },
                        {
                            titleSub: 'DIMENSIONS- 16” x 16” x 4” (4.5” including feet)',
                            textSub: ''
                        }
                    ]
                },
                {
                    id: 7,
                    img: require('../../assets/images/ipad.jpg'),
                    titleBox: 'What your Ipad can do for you',
                    titleContent: 'Great experience with iPad tablet',
                    subItem: [
                        {
                            titleSub: 'Finish',
                            textSub: 'Silver / Gold / Space Gray / Rose Gold'
                        },
                        {
                            titleSub: 'Connectivity',
                            textSub: 'Wi‑Fi + Cellular'
                        },
                        {
                            titleSub: 'Chip',
                            textSub: 'A9X chip with 64‑bit architecture  - Embedded M9 coprocessor'
                        },
                        {
                            titleSub: 'Screen',
                            textSub: 'Screen: 9.7 inches, rectangle 1536 x 2048 pixels'
                        },
                        {
                            titleSub: 'Sensors',
                            textSub: 'Touch ID - Three‐axis gyro - Accelerometer - Barometer - Ambient light sensor'
                        },
                        {
                            titleSub: 'Accessibility',
                            textSub: 'Accessibility features help people with disabilities get the most out of their new iPad Pro. With built-in support for vision, hearing, physical and motor skills, and learning and literacy, you can create and do amazing things'
                        }
                    ]
                },
                {
                    id: 8,
                    img: require('../../assets/images/mobile-pos.png'),
                    titleBox: 'What your Computer can do for you',
                    titleContent: 'HP 24-Inch All-in-One Computer, Intel Core i5-8250U, 12GB RAM, 1TB Hard Drive, Windows 10 (24-f0060, White)',
                    subItem: [
                        {
                            titleSub: 'Processor',
                            textSub: '8th Generation intel(r) core(tm) i5-8250U Processor, quad-core, 1.60GHz'
                        },
                        {
                            titleSub: 'Monitor',
                            textSub: '12 GB DDR4-2400 SDRAM (upgradable to 16 GB)'
                        },
                        {
                            titleSub: 'Memory',
                            textSub: 'Includes 2 keys to lock the drawer and keep valuables safe and secure'
                        },
                        {
                            titleSub: 'Hard Drive',
                            textSub: '1 TB 7200Rpm SATA'
                        },
                        {
                            titleSub: 'Operating System',
                            textSub: 'Windows 10 Home'
                        },
                        {
                            titleSub: 'Keyboard and Mouse',
                            textSub: 'HP White wireless keyboard with volume control and optical mouse'
                        }
                    ]
                }
            ],
            priceType: [
                {
                    id: 1,
                    slug: 'clover-flex',
                    title: 'Clover Flex',
                    name: 'Flex',
                    price: 'Stars at $449.00',
                    note: 'Interest-free installment plan available'
                },
                {
                    id: 2,
                    slug: 'clover-station',
                    title: 'Clover Station',
                    name: 'Station',
                    price: 'Stars at $1,149.00',
                    note: 'Interest-free installment plan available'
                },
                {
                    id: 3,
                    slug: 'clover-mini',
                    title: 'Clover Mini',
                    name: 'Mini',
                    price: 'Stars at $649.00',
                    note: 'Interest-free installment plan available'
                },
                {
                    id: 4,
                    slug: 'clover-go',
                    title: 'Clover Go',
                    name: 'Go',
                    price: '$69.00'
                },
                {
                    id: 5,
                    name: 'Printer',
                    price: '$56.00',
                    note: 'Interest-free installment plan available'
                },
                {
                    id: 6,
                    name: 'Cash drawer',
                    price: '$26.5',
                    note: 'Interest-free installment plan available'
                },
                {
                    id: 7,
                    name: 'Ipad',
                    price: '$459.00',
                    note: 'Interest-free installment plan available'
                },
                {
                    id: 8,
                    name: 'Computer',
                    price: '$400.00',
                    note: 'Interest-free installment plan available'
                }
            ],
            landingPage: [
                {
                    id: 1,
                    slug: 'clover-flex',
                    title: 'Clover Flex',
                    titleBox: '',
                    subItem: [
                        {
                            titleSub: 'Accept all payment types',
                            imgSub: require("../../assets/images/payments-icon.svg"),
                            textSub: 'Let your customers pay how they want to pay. Swipe, dip, and tap. Magstripe, chip cards, and NFC payments like Apple Pay and Samsung Pay.'
                        },
                        {
                            titleSub: 'All-in-one system',
                            imgSub: require("../../assets/images/all-in-one-icon.svg"),
                            textSub: 'Replace your cash register, terminal, and bulky printer. A single, compact device is all you need to ring people up.'
                        },
                        {
                            titleSub: 'Track your business',
                            imgSub: require("../../assets/images/track-your-business-icon.svg"),
                            textSub: 'Monitor your sales, refunds, and best-selling items from any computer or mobile device.'
                        },
                        {
                            titleSub: 'Get deposits fast and easy',
                            imgSub: require("../../assets/images/speed-reliability-icon.svg"),
                            textSub: 'Funds are automatically transferred to your bank account as fast as the next business day.'
                        }
                    ]
                },
                {
                    id: 2,
                    slug: 'clover-station',
                    title: 'Clover Station',
                    titleBox: 'The all-in-one POS system that comes with a cash drawer and receipt printer. No extra costs. No hidden fees.',
                    subItem: [
                        {
                            titleSub: 'Large, versatile display',
                            imgSub: require("../../assets/images/display-icon.svg"),
                            textSub: 'The 14" high-definition makes your work fast and easy, from inventory and employee management to payments and reporting.'
                        },
                        {
                            titleSub: 'Custom orders done right',
                            imgSub: require("../../assets/images/custom-orders-icon.svg"),
                            textSub: 'Create custom orders quickly and accurately. Adjust a menu item, add or remove optional ingredients and modifiers, and automatically update the order total.'
                        },
                        {
                            titleSub: 'Speed, power, reliability',
                            imgSub: require("../../assets/images/speed-reliability-icon.svg"),
                            textSub: 'Clover Station packs plenty of processing power to help you run your business faster: update inventory, manage your staff, take orders, and pull reports. Now with an even faster CPU and more RAM.'
                        },
                        {
                            titleSub: 'Multi-layered security',
                            imgSub: require("../../assets/images/protection-icon.svg"),
                            textSub: 'Station gives you fingerprint login for enhanced employee security and end-to-end encryption of every transaction to help protect your data—yours as well as your customers’.'
                        }
                    ]
                },
                {
                    id: 3,
                    slug: 'clover-mini',
                    title: 'Clover Mini',
                    titleBox: 'The all-in-one mini POS system for any business. No extra costs. No hidden fees.',
                    subItem: [
                        {
                            titleSub: 'Small but powerful',
                            imgSub: require("../../assets/images/all-in-one-icon.svg"),
                            textSub: 'Mini is small enough to fit into any space, but packs plenty of POS power to run your full house, front to back.'
                        },
                        {
                            titleSub: 'Payments and deposits made easy',
                            imgSub: require("../../assets/images/payments-icon.svg"),
                            textSub: 'Swipe, dip, tap, or take cash—accept all the ways your customers like to pay. And get the funds in your bank account as fast as the next business day.'
                        },
                        {
                            titleSub: 'Future-proof your business',
                            imgSub: require("../../assets/images/futureproof-icon.svg"),
                            textSub: 'Mini can be as minimal or full-featured as you want it to be. And it will always grow and scale with your business.'
                        },
                        {
                            titleSub: 'Stay on top of your numbers',
                            imgSub: require("../../assets/images/track-business-icon.svg"),
                            textSub: 'Monitor your sales, refunds, and best-selling items from any computer or mobile device.'
                        }
                    ]
                },
                {
                    id: 4,
                    slug: 'clover-go',
                    title: 'Clover Go',
                    titleBox: 'A sleek, compact, super light card reader and an app that packs a lot of processing power.',
                    subItem: [
                        {
                            titleSub: 'Take payments on the go',
                            imgSub: require("../../assets/images/display-icon.svg"),
                            textSub: 'Accept all types of payments anywhere you have a Wi-Fi or cellular connection: contactless, chip, and swipe, including Apple Pay®, Samsung Pay®, and Google Pay®.'
                        },
                        {
                            titleSub: 'Ready right out of the box',
                            imgSub: require("../../assets/images/custom-orders-icon.svg"),
                            textSub: 'Just charge, connect, and pair with the Clover Go app on your iOS or Android device.'
                        },
                        {
                            titleSub: 'Sync with other Clover devices',
                            imgSub: require("../../assets/images/speed-reliability-icon.svg"),
                            textSub: 'Whatever you do on your Clover Go—create orders, process transactions, manage employees, or update inventory— it all seamlessly syncs with the Clover web dashboard, so you’re always on top of your business no matter where you are.'
                        },
                        {
                            titleSub: 'Top-notch security',
                            imgSub: require("../../assets/images/protection-icon.svg"),
                            textSub: 'Protect your data from end to end—yours as well as your customers’—with the latest in encryption and tokenization technologies.'
                        }
                    ]
                }
            ],
            highImpact: [
                {
                    id: 2,
                    slug: 'clover-station',
                    title: 'Clover Station',
                    listItem: [
                        {
                            id: 1,
                            background: require('../../assets/images/clover-station-pos-device-full-service-restaurant-large.jpg'),
                            backgroundMobile: require('../../assets/images/clover-station-pos-device-full-service-restaurant-small.jpg'),
                            subTitleBox: 'STATION FOR FULL-SERIVICE RESTAURANTS',
                            titleBox: 'Run a smart restaurant with a smart POS',
                            price: '$1,149',
                            notePrice: 'or three payments of $383',
                            subItem: [
                                {
                                    imgSub: require("../../assets/images/dynamic-floor-plan-icon.svg"),
                                    textSub: 'Build dynamic floor plans with real-time table status'
                                },
                                {
                                    imgSub: require("../../assets/images/robust-management-icon.svg"),
                                    textSub: 'Manage your entire staff'
                                },
                                {
                                    imgSub: require("../../assets/images/pay-at-table-icon.svg"),
                                    textSub: 'Take table-side orders and payments'
                                }
                            ]
                        },
                        {
                            id: 2,
                            background: require('../../assets/images/clover-station-pos-device-any-business-large.jpg'),
                            backgroundMobile: require('../../assets/images/clover-station-pos-device-any-business-small.jpg'),
                            subTitleBox: 'Station For Any Business',
                            titleBox: 'Smart POS systems that make running your business easier',
                            price: '$1,149',
                            notePrice: 'or three payments of $400',
                            subItem: [
                                {
                                    imgSub: require("../../assets/images/signature-icon.svg"),
                                    textSub: 'Make tips and payments a breeze for your customers'
                                },
                                {
                                    imgSub: require("../../assets/images/all-payment-icon.svg"),
                                    textSub: 'Accept all payment types including Apple Pay and Google Pay'
                                },
                                {
                                    imgSub: require("../../assets/images/fully-compatible-icon.svg"),
                                    textSub: 'Integrate with barcode scanners, weight scales, and other accessories'
                                }
                            ]
                        }
                    ]
                },
                {
                    id: 3,
                    slug: 'clover-mini',
                    title: 'Clover Mini',
                    listItem: [
                        {
                            id: 1,
                            background: require('../../assets/images/clover-mini-pos-device-full-service-restaurant-large.png'),
                            backgroundMobile: require('../../assets/images/clover-mini-pos-device-full-service-restaurant-small.png'),
                            subTitleBox: 'Mini for Full Service Restaurants',
                            titleBox: 'Run a smart restaurant business  with a smart restaurant POS system',
                            price: '$599',
                            notePrice: 'or three payments of $200',
                            subItem: [
                                {
                                    imgSub: require("../../assets/images/dynamic-floor-plan-icon.svg"),
                                    textSub: 'Build dynamic floor plans'
                                },
                                {
                                    imgSub: require("../../assets/images/robust-management-icon.svg"),
                                    textSub: 'Manage your entire staff'
                                },
                                {
                                    imgSub: require("../../assets/images/bar-authorization-icon.svg"),
                                    textSub: 'Pre-authorize bar tabs'
                                }
                            ]
                        },
                        {
                            id: 2,
                            background: require('../../assets/images/clover-mini-pos-device-any-business-large.jpg'),
                            backgroundMobile: require('../../assets/images/clover-mini-pos-device-any-business-small.jpg'),
                            subTitleBox: 'Mini for Full Service Restaurants',
                            titleBox: 'Run a smart restaurant business  with a smart restaurant POS system',
                            price: '$599',
                            notePrice: 'or three payments of $200',
                            subItem: [
                                {
                                    imgSub: require("../../assets/images/signature-icon.svg"),
                                    textSub: 'Make tips and payments a breeze for your customers'
                                },
                                {
                                    imgSub: require("../../assets/images/all-payment-icon.svg"),
                                    textSub: 'Accept all payment types including Apple Pay and Google Pay'
                                },
                                {
                                    imgSub: require("../../assets/images/fully-compatible-icon.svg"),
                                    textSub: 'Integrate with barcode scanners, weight scales, and other accessories'
                                }
                            ]
                        }
                    ]
                }
            ]
        };
    }

    _handleCheckWindowSize() {
        let width = window.innerWidth;
        this.setState({
            isMoble: false,
            isMobileMini: false
        }, () => {
            this.setState({
                isMoble: width < 992,
                isMobileMini: width < 576
            })
        })
    }
    getInfoBanner(slugDetail) {
        let { textBanner } = this.state
        let result = textBanner.filter(x => x.slug == slugDetail)
        this.setState({ textBanner: result })
    }
    getLandingPage(slugDetail) {
        let { landingPage } = this.state
        let result = landingPage.filter(x => x.slug == slugDetail)
        this.setState({ landingPage: result })
    }
    gethighImpact(slugDetail) {
        let { highImpact } = this.state
        let result = highImpact.filter(x => x.slug == slugDetail)
        this.setState({ highImpact: result })
    }
    getPriceType(slugDetail) {
        let { priceType } = this.state
        let result = priceType.filter(x => x.slug == slugDetail)
        this.setState({ priceType: result })
    }
    getInfoPortfolio(slugDetail) {
        let { portfolio } = this.state
        let result = portfolio.filter(x => x.slug == slugDetail)
        this.setState({ portfolio: result })
    }
    componentDidMount() {

        this._handleCheckWindowSize();
        window.addEventListener("resize", () => {
            this._handleCheckWindowSize()
        })
        $(window).scroll(() => this.setState({ y: $(window).scrollTop() }));
        const { slug } = this.props.match.params;
        const title = this.state.textBanner.filter(x => x.slug == slug)

        console.log('id:', title[0])
        this.setState
            (
                { slugDetail: slug, titleDocument: title[0].titleDocument },
                () => {

                    this.getInfoBanner(this.state.slugDetail)
                    this.getInfoPortfolio(this.state.slugDetail)
                    this.getPriceType(this.state.slugDetail)
                    this.getLandingPage(this.state.slugDetail)
                    this.gethighImpact(this.state.slugDetail)
                }
            )
    }


    render() {
        let { isMoble, y, slugDetail, textBanner, portfolio, priceType, landingPage, highImpact, isMobileMini, titleDocument } = this.state
        const settings = {
            dots: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false,
            speed: 1000,
            autoplaySpeed: 4000,
            cssEase: "linear"
        };

        return (
            <DocumentTitle title={titleDocument}>
                <div>
                    <Header />
                    <div className={"detail" + (isMoble ? ' responsive-detail' : '')}>
                        {
                            textBanner && textBanner.length > 0 && textBanner.map((item, index) =>
                                <div key={index} className="banner-detail">
                                    <PlayerControl
                                        src={item.background}
                                    />
                                    <div className="content-banner">
                                        <div className="ct-bn-c">
                                            <h2 className="title-b">{item.title}</h2>
                                            <p>{item.desc}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        <div className="content-detail">
                            <div className="ct-detail">
                                <div className="title-content clearfix">
                                    {
                                        priceType && priceType.length > 0 && priceType.map((item, index) =>
                                            <div key={index} className="ct-title-t">
                                                <div className="title-left">
                                                    <p>{item.name}</p>
                                                </div>
                                                <div className="title-right">
                                                    <div className="content-title-r">
                                                        <a className="btn btn-shop">CONTACT SALE</a>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }

                                </div>
                                {
                                    landingPage && landingPage.length > 0 &&
                                    <section className="landing-page">

                                        <div className="ct-c-landing-page">
                                            {
                                                landingPage.map((item, index) =>
                                                    <div key={index}>
                                                        <div className="title-highlights-container">
                                                            <h4>{item.titleBox}</h4>
                                                        </div>
                                                        <ul className="contant-highlights-container row">
                                                            {
                                                                item.subItem.length > 0 && item.subItem.map((item_a, j) =>
                                                                    <li key={j} className="overview-highlight col-md-6">
                                                                        <div className="ct-a-overview">
                                                                            <img src={item_a.imgSub}></img>
                                                                            <div className="ct-overview">
                                                                                <h4>{item_a.titleSub}</h4>
                                                                                <p className="text-ct">{item_a.textSub}</p>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                )
                                                            }
                                                        </ul>
                                                    </div>
                                                )
                                            }

                                            <div id="slide-landing-page" className="carousel slide" data-ride="carousel">
                                                <Slider {...settings}>
                                                    {
                                                        landingPage.subItem && landingPage[0].subItem.map((item, index) =>
                                                            <div key={index} className="overview-highlight">
                                                                <div className="ct-a-overview">
                                                                    <img src={item.imgSub}></img>
                                                                    <div className="ct-overview">
                                                                        <h4>{item.titleSub}</h4>
                                                                        <p className="text-ct">{item.textSub}</p>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        )
                                                    }
                                                </Slider>
                                            </div>
                                        </div>
                                    </section>
                                }
                                {
                                    highImpact && highImpact.length > 0 && highImpact.map((item, index) =>
                                        <section key={index} className="high-impact">
                                            {
                                                item.listItem.map((item_a, j) =>
                                                    <div key={j} className="ct-high-impact" style={{ background: 'url(' + (isMobileMini ? item_a.backgroundMobile : item_a.background) + ') bottom / cover no-repeat' }}>
                                                        <div href="#" className="l-hight-impact">
                                                            <div className="box-ct-hight">
                                                                <div className="high-impact-item">
                                                                    <span>{item_a.subTitleBox}</span>
                                                                    <h2>{item_a.titleBox}</h2>
                                                                    <div className="high-impact-item-control">
                                                                        <button className="btn btn-hight">CONTACT SALE</button>

                                                                    </div>
                                                                    <ul className="list-info-hight">
                                                                        {
                                                                            item_a.subItem.map((item_b, z) =>
                                                                                <li key={z} className="hight-item">
                                                                                    <img src={item_b.imgSub}></img>
                                                                                    <p className="text-hight-iten">{item_b.textSub}</p>
                                                                                </li>
                                                                            )
                                                                        }
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }

                                        </section>
                                    )
                                }
                                <section className="image-portfolio">
                                    {
                                        portfolio && portfolio.length > 0 && portfolio.map((item, index) =>
                                            <div key={index}>
                                                <h2 className="title-image-po">{item.titleBox}</h2>
                                                <div className="ct-image-portfolio">
                                                    <div className="img-prot">
                                                        <img className="background-img-pr" src={item.img}></img>
                                                    </div>
                                                    <div className="content-a-image-portfolio">
                                                        <div className="blur-image-portfolio">
                                                            <h2>{item.titleContent}</h2>
                                                            {
                                                                item.subItem.length > 0 && item.subItem.map((item_a, j) =>
                                                                    <div key={j}>
                                                                        <h4>{item_a.titleSub}</h4>
                                                                        <p>{item_a.textSub}</p>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }

                                </section>
                                <section className="landing-page-tell">
                                    <div className="ct-landing-page">
                                        <h2>Need help building your system?</h2>
                                        <h2>Call us at <span>210-607-8888</span>
                                        </h2>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </DocumentTitle>
        );
    }
}

export default Home;

import React from "react";
import './style/style.css';
import './style/style-mobile.css';
import { ArticleSidebar } from '../../../common/article-sidebar';
import { NavLink } from "react-router-dom";
import { BASE_API } from "../../../../constants/appSettings";
import { get, post } from "../../../../api";
import { formatDateTime } from "../../../../utils/common";
import { showError, showSuccess, isMobile } from "../../../../utils/app";
import { ArticleFooter } from "../../../common/article-footer";

class Index extends React.Component {

    constructor() {
        super();
        this.state = {
            data: null,
            listLeftMenu: null,
            loadPage: true,
            statusText: "Waiting...",
            id: "",
            idCatalog: ""
        };
    }

    onloadDetail(id) {
        get("web/api/faq-posts/" + id,
            result => {
                this.setState({
                    data: result,
                    statusText: 'No record found'
                })
            }, () => { },
            BASE_API
        );
    }


    getDataLeftMenu(idCatalog) {
        let param = {
            take: 10,
            page: 1
        };
        post('web/api/faq/' + idCatalog, param,
            result => {
                this.setState({
                    listLeftMenu: result.faqPosts,
                    total: result.sumAll,
                    idCatalog: result.id
                }, () => {
                    this.setState({
                        loadPage: false,
                    });
                });
            }, () => { },
            BASE_API
        )

    }

    submitLike = (like) => {
        let {
            id
        } = this.state;

        let param = {
            id: id,
            like: like
        }


        post("web/api/faq-posts/like", param,
            result => {
                showSuccess("Success");
                this.onloadDetail(id);
            },
            () => {
                showError("Error");
            },
            BASE_API
        );

    };

    componentDidMount() {
        const { id, idCatalog } = this.props.match.params;
        this.setState({
            id: id,
            idCatalog: idCatalog
        }, () => {
            this.onloadDetail(id);
            this.getDataLeftMenu(idCatalog);
        });
    }

    componentWillReceiveProps(props) {
        const { id, idCatalog } = props.match.params;
        this.setState({
            id: id,
            idCatalog: idCatalog
        }, () => {
            this.onloadDetail(id);
            this.getDataLeftMenu(idCatalog);
        });
    }

    render() {
        if (isMobile()) return this.renderMobile();
        return this.renderDesktop();

    }

    renderMobile() {
        let {
            data,
            loadPage,
            statusText,
            idCatalog,
            id,
            total,
            listLeftMenu
        } = this.state;

        return (
            <div className="components-mobile">
                <div className="articles">
                    <nav className="sub-nav">
                        <ol className="breadcrumbs text-summary text-row-1 mr20">
                            <li>
                                <NavLink to="/">Home</NavLink>
                            </li>
                            <li>
                                <NavLink to="/support">Support</NavLink>
                            </li>
                            <li>
                                <NavLink to="/support/announcements">Faq</NavLink>
                            </li>
                            <li>
                                <a>{data != null ? data.title : "No record found"}</a>
                            </li>
                        </ol>

                        {/* <form className="search">
                            <i className="fas fa-search" />
                            <input type="search" placeholder="Search" />
                        </form> */}
                    </nav>

                    <div className="article-container">
                        <section className="article-sidebar">
                            {
                                listLeftMenu &&
                                <ArticleSidebar
                                    data={listLeftMenu}
                                    total={total}
                                    activeId={id}
                                    idCatalog={idCatalog}
                                    link={"/support/faq/"}
                                />
                            }
                            <i className="fas fa-chevron-down" />
                        </section>
                        {
                            statusText != "Waiting..."
                                ?
                                data != null
                                    ?
                                    <article className="article">
                                        <div className="article-header">
                                            <h1 className="article-title">{data.title}</h1>
                                            <div className="article-author">
                                                <div className="avatar article-avatar">
                                                    <img src={require("../../../../assets/images/iconnew.png")} alt="Avatar" className="user-avatar" />
                                                </div>
                                                <div className="article-meta">
                                                    Foresterx
                                                <ul className="meta-group">
                                                        <li className="meta-data">
                                                            <time>{formatDateTime(data.dateCreate)}</time>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <section className="article-info">
                                            <div className="article-content">
                                                <div className="article-body">
                                                    <div className="content-editor clearfix" dangerouslySetInnerHTML={{ __html: data.details }} />
                                                </div>
                                            </div>
                                            <ArticleFooter
                                                submitLike={(e) => this.submitLike(e)}
                                                likeNo={data.likeNo}
                                                likeYes={data.likeYes}
                                            />
                                        </section>

                                    </article>
                                    :
                                    <article className="article">
                                        <h3 className="text-center">404, No record found</h3>
                                    </article>
                                :
                                <article className="article">
                                    <p className="text-center">{statusText}</p>
                                </article>
                        }
                    </div>
                </div>

            </div>

        );
    }

    renderDesktop() {
        let {
            data,
            loadPage,
            statusText,
            idCatalog,
            id,
            total,
            listLeftMenu
        } = this.state;

        return (
            <div className="components-pc">
                <div className="content-main articles">
                    <div className="container">
                        <nav className="sub-nav">
                            <ol className="breadcrumbs text-summary text-row-1 mr20">
                                <li>
                                    <NavLink to="/">Home</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/support">Support</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/support/faq">Faq</NavLink>
                                </li>
                                <li>
                                    <a>{data != null ? data.title : "No record found"}</a>
                                </li>
                            </ol>
                            {/* 
                            <form className="search">
                                <i className="fas fa-search" />
                                <input type="search" placeholder="Search" />
                            </form> */}
                        </nav>

                        <div className="article-container">
                            <section className="article-sidebar">
                                {
                                    listLeftMenu &&
                                    <ArticleSidebar
                                        data={listLeftMenu}
                                        total={total}
                                        activeId={id}
                                        idCatalog={idCatalog}
                                        link={"/support/faq/"}
                                    />
                                }
                            </section>

                            {
                                statusText != "Waiting..."
                                    ?
                                    data != null
                                        ?
                                        <article className="article">
                                            <div className="article-header">
                                                <h1 className="article-title">{data.title}</h1>
                                                <div className="article-author">
                                                    <div className="avatar article-avatar">
                                                        <img src={require("../../../../assets/images/iconnew.png")} alt="Avatar" className="user-avatar" />
                                                    </div>
                                                    <div className="article-meta">
                                                        Foresterx
                                                    <ul className="meta-group">
                                                            <li className="meta-data">
                                                                <time>{formatDateTime(data.dateCreate)}</time>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            <section className="article-info">
                                                <div className="article-content">
                                                    <div className="article-body">
                                                        <div className="content-editor clearfix" dangerouslySetInnerHTML={{ __html: data.details }} />
                                                    </div>
                                                </div>
                                                <ArticleFooter
                                                    submitLike={(e) => this.submitLike(e)}
                                                    likeNo={data.likeNo}
                                                    likeYes={data.likeYes}
                                                />
                                            </section>

                                        </article>
                                        :
                                        <article className="article">
                                            <h3 className="text-center">404, No record found</h3>
                                        </article>
                                    :
                                    <article className="article">
                                        <p className="text-center">{statusText}</p>
                                    </article>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Index;

import React from "react";
import { NavLink } from "react-router-dom";
import { createSlugName } from "../../../utils/common";
import { isMobile } from "../../../utils/app";
import Collapsible from "../../common/Collapsible";

const TAKE = 10;

export class ArticleSidebar extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            statusText: "Waiting...",
            openCollapsible: false,
        }
    }


    componentWillReceiveProps(props) {
        this.setState({
            openCollapsible: false
        })
    }

    render() {
        if (isMobile()) return this.renderMobile();
        return this.renderDesktop();
    }
    renderMobile() {
        const {
            statusText,
            openCollapsible
        } = this.state;

        const {
            data,
            link,
            activeId,
            idCatalog } = this.props;
        return (
            <section className="section-articles collapsible-sidebar">
                <div className="accordion">
                    <Collapsible
                        trigger="Articles in this section"
                        open={openCollapsible}
                    >
                        <ul>
                            {
                                data && data.length > 0
                                    ?
                                    data.map((item, i) =>
                                        <li key={i}>
                                            <NavLink to={link + createSlugName(item.title) + "/" + idCatalog + "/" + item.id} className={"sidenav-item" + (activeId === item.id ? ' current-article' : '')}>
                                                <span className="text-summary text-row-3">{item.title}</span>
                                            </NavLink>
                                        </li>
                                    )
                                    :
                                    <li>
                                        <h1 className="text-center">{statusText}</h1>
                                    </li>
                            }
                        </ul>
                        <NavLink to={link + this.props.idCatalog} className="article-sidebar-item">See more</NavLink>
                    </Collapsible>
                </div>
            </section>
        );
    }
    renderDesktop() {
        const {
            statusText
        } = this.state;

        const {
            data,
            total,
            activeId,
            link,
            idCatalog
        } = this.props;

        console.log(data)

        return (
            <section className="section-articles collapsible-sidebar">
                <h3 className="collapsible-sidebar-title sidenav-title">Articles in this section</h3>
                <ul>
                    {
                        data && data.length > 0
                            ?
                            data.map((item, i) =>
                                <li key={i}>
                                    <NavLink to={link + createSlugName(item.title) + "/" + idCatalog + "/" + item.id} className={"sidenav-item" + (activeId == item.id ? ' current-article' : '')}>
                                        <span className="text-summary text-row-3">{item.title}</span>
                                    </NavLink>
                                </li>
                            )
                            :
                            <li>
                                <h1 className="text-center">{statusText}</h1>
                            </li>
                    }
                </ul>
                {
                    data && data != null && data.length > 0
                    &&
                    <NavLink to={link + this.props.idCatalog} className="article-sidebar-item">See more</NavLink>
                }
            </section>
        );
    }
}

import React from "react";
import { NavLink } from "react-router-dom";
import { FILE_UPLOAD_WEB_CONTENT, BASE_API } from "../../../constants/appSettings";
import { get, post } from "../../../api/index";
import './style/style.css';
import './style/style-mobile.css';
import ArticleSidebar from "../detail/article-sidebar";
import { createSlugName, formatDate2 } from "../../../utils/common";
import { isMobile } from "../../../utils/app";
const TAKE = 10;

class Index extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loadPage: true,
            statusText: "Waiting...",
            news: [],
            total: 0,
            skip: 0
        }
    }

    getNews() {
        var { skip, news } = this.state;

        let param = {};
        if (skip === 0) {
            param = {
                skip: 0,
                take: 10,
            };
        } else {
            param = {
                skip: skip,
                take: TAKE,
            };
        }

        post('web/get-news', param,
            result => {
                if (skip === 0) {
                    this.setState({
                        news: result,
                        statusText: "No record found"
                    }, () => {
                        this.setState({
                            loadPage: false
                        });
                    });
                } else {
                    this.setState({
                        news: news.concat(result),
                    });
                }
            }, () => { },
            BASE_API
        )
    }

    onLoadMore = () => {
        var { skip } = this.state;

        this.setState(
            {
                skip: skip + TAKE
            },
            () => this.getNews()
        );
    };

    componentDidMount() {
        this.getNews();
    }

    render() {
        if (isMobile()) return this.renderMobile();
        return this.renderDesktop();
    }

    renderMobile() {
        const {
            news,
            statusText
        } = this.state;
        const { skip } = this.state;

        return (
            <div className="components-mobile">
                <div className="articles-list">
                    <nav className="sub-nav">
                        <ol className="breadcrumbs">
                            <li>
                                <NavLink to="/">Home</NavLink>
                            </li>
                            <li>
                                <NavLink to="/articles">Articles</NavLink>
                            </li>
                        </ol>

                        {/* <form className="search">
                            <i className="fas fa-search" />
                            <input type="search" placeholder="Search" />
                        </form> */}
                    </nav>
                    <div className="article-container">
                        <h1 className="collapsible-sidebar-title sidenav-title mb20">Articles in this section</h1>
                        <ul className="list-article clear-fix">
                            {
                                news.length > 0
                                    ?
                                    news.map((item, i) =>
                                        <li key={i}>
                                            <p className="time">{formatDate2(item.creationDate)}</p>
                                            <NavLink to={"/articles/" + createSlugName(item.description) + "/" + item.id}><span className="text-summary text-row-1">{item.description}</span></NavLink>
                                        </li>
                                    )
                                    :
                                    <li>
                                        <h1 className="text-center">{statusText}</h1>
                                    </li>
                            }

                        </ul>
                        {skip + TAKE === news.length && (
                            <div className="mt10 text-right">
                                <button
                                    className="btn btn-default bg-orange"
                                    onClick={this.onLoadMore}
                                >
                                    Load More ...
                            </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    renderDesktop() {
        const {
            news,
            statusText
        } = this.state;
        const { skip } = this.state;

        return (
            <div className="components-pc">
                <div className="content-main articles-list">
                    <div className="container">
                        <nav className="sub-nav">
                            <ol className="breadcrumbs">
                                <li>
                                    <NavLink to="/">Home</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/articles">Articles</NavLink>
                                </li>
                            </ol>

                            {/* <form className="search">
                                <i className="fas fa-search" />
                                <input type="search" placeholder="Search" />
                            </form> */}
                        </nav>
                        <div className="article-container">
                            <h1 className="collapsible-sidebar-title sidenav-title mb20">Articles in this section</h1>
                            <ul className="list-article clear-fix">
                                {
                                    news.length > 0
                                        ?
                                        news.map((item, i) =>
                                            <li key={i}>
                                                <p className="time">{formatDate2(item.creationDate)}</p>
                                                <NavLink to={"/articles/" + createSlugName(item.description) + "/" + item.id}><span className="text-summary text-row-1">{item.description}</span></NavLink>
                                            </li>
                                        )
                                        :
                                        <li>
                                            <h1 className="text-center">{statusText}</h1>
                                        </li>
                                }

                            </ul>
                            {skip + TAKE === news.length && (
                                <div className="mt10 text-right">
                                    <button
                                        className="btn btn-default bg-orange"
                                        onClick={this.onLoadMore}
                                    >
                                        Load More ...
                                </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Index;

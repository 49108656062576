import React from "react";
import './style/style.css';
import './style/style-mobile.css';
import { post, get } from "../../api";
// import {BASE_API_WEB_CONTENT} from "../../constants/appSettings";

import { BASE_API } from "../../constants/appSettings";
import { isMobile } from "../../utils/app";
import { NavLink } from "react-router-dom";
import { createSlugName, formatDate4 } from "../../utils/common";
const TAKE = 4;

class Index extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loadPage: true,
            statusText: "Waiting...",
            data: [],
            skip: 0
        }
    }
    getData() {
        var data = [];
        let param = {
            take: TAKE,
            page: 1
        }
        post('web/api/announcement-posts', param,
            result => {
                this.setState({
                    data: result,
                    statusText: 'No record found'
                })
            }, () => { },
            BASE_API
        )
    }

    onLoadMore = () => {
        var { skip } = this.state;

        this.setState(
            {
                skip: skip + TAKE
            },
            () => this.getData()
        );
    };

    componentDidMount() {

        this.getData();
    }

    render() {
        if (isMobile()) return this.renderMobile();
        return this.renderDesktop();
    }

    renderMobile() {
        const {
            data,
            statusText,
            loadPage,
            skip,
        } = this.state;
        console.log('dataaaa', data)
        return (
            <div className="components-mobile">
                <div className="support">
                    {loadPage ? <div className="load-page"><div className="lds-dual-ring" /></div> : null}
                    <nav className="sub-nav">
                        <ol className="breadcrumbs">
                            <li>
                                <NavLink to="/">Home</NavLink>
                            </li>
                            <li>
                                <NavLink to="/support">Support</NavLink>
                            </li>
                        </ol>

                        {/* <form className="search">
                            <i className="fas fa-search" />
                            <input type="search" placeholder="Search" />
                        </form> */}
                    </nav>
                    <section className="categories">
                        <ul className="blocks-list">
                            <li className="blocks-item">
                                <NavLink to="/support/announcements" className="blocks-item-link">
                                    <h4 className="blocks-item-title">Announcements</h4>
                                </NavLink>
                            </li>
                            {/* <li className="blocks-item">
                                <NavLink to="/support/list-coin" className="blocks-item-link">
                                    <h4 className="blocks-item-title">List coin</h4>
                                </NavLink>
                            </li> */}
                            <li className="blocks-item">
                                <NavLink to="/support/faq" className="blocks-item-link">
                                    <h4 className="blocks-item-title">FAQ</h4>
                                </NavLink>
                            </li>
                        </ul>
                    </section>
                    <div className="article-container">
                        <h1 className="collapsible-sidebar-title sidenav-title mb20 text-center">Recent activity</h1>
                        <ul className="list-article clear-fix">
                            {
                                data.length > 0
                                    ?
                                    data.map((item, i) =>
                                        <li className="recent-activity-item" key={i}>
                                            <p className="time">{formatDate4(item.creationDate)}{/*<span className="comment"><i className="far fa-comment-alt"/></span>0*/}</p>
                                            <div>
                                                <p className="recent-activity-item-parent">
                                                    <NavLink to={'/support/announcements/' + createSlugName(item.title) + '/' + item.announcementId}>{item.announcementName}</NavLink>
                                                </p>
                                                <p className="recent-activity-item-link">
                                                    {
                                                        <NavLink to={'/support/announcements/' + createSlugName(item.title) + "/" + + item.announcementId + '/' + item.id}>
                                                            <span className="text-summary text-row-1">{item.title}</span>
                                                        </NavLink>
                                                    }

                                                </p>
                                            </div>
                                        </li>
                                    )
                                    :
                                    <li>
                                        <p className="text-center">{statusText}</p>
                                    </li>
                            }
                        </ul>
                        {skip + TAKE === data.length && (
                            <div className="mt10 text-right">
                                <button
                                    className="btn btn-default bg-orange"
                                    onClick={this.onLoadMore}
                                >
                                    Load More ...
                            </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    renderDesktop() {
        const {
            data,
            statusText,
            loadPage,
            skip,
        } = this.state;
        console.log('andata')
        return (
            <div className="components-pc">
                <div className="content-main support">
                    {loadPage ? <div className="load-page"><div className="lds-dual-ring" /></div> : null}
                    <div className="container">
                        <nav className="sub-nav">
                            <ol className="breadcrumbs">
                                <li>
                                    <NavLink to="/">Home</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/support">Support</NavLink>
                                </li>
                            </ol>

                            {/* <form className="search">
                                <i className="fas fa-search" />
                                <input type="search" placeholder="Search" />
                            </form> */}
                        </nav>
                        <section className="categories">
                            <ul className="blocks-list">
                                <li className="blocks-item">
                                    <NavLink to="/support/announcements" className="blocks-item-link">
                                        <h4 className="blocks-item-title">Announcements</h4>
                                    </NavLink>
                                </li>
                                {/* <li className="blocks-item">
                                    <NavLink to="/support/list-coin" className="blocks-item-link">
                                        <h4 className="blocks-item-title">List coin</h4>
                                    </NavLink>
                                </li> */}
                                <li className="blocks-item">
                                    <NavLink to="/support/faq" className="blocks-item-link">
                                        <h4 className="blocks-item-title">FAQ</h4>
                                    </NavLink>
                                </li>
                            </ul>
                        </section>
                        <div className="article-container">
                            <h1 className="collapsible-sidebar-title sidenav-title mb20 text-center">Recent activity</h1>
                            <ul className="list-article clear-fix">
                                {
                                    data.length > 0
                                        ?
                                        data.map((item, i) =>
                                            <li className="recent-activity-item" key={item.id}>
                                                <p className="time">{formatDate4(item.dateCreate)}{/*<span className="comment"><i className="far fa-comment-alt"/></span>0*/}</p>
                                                <div>
                                                    <p className="recent-activity-item-parent">
                                                        <NavLink to={'/support/announcements/' + item.announcementId}>{item.announcementName}</NavLink>
                                                    </p>
                                                    <p className="recent-activity-item-link">
                                                        {
                                                            <NavLink to={'/support/announcements/' + createSlugName(item.title) + "/" + item.announcementId + '/' + item.id}>
                                                                <span className="text-summary text-row-1">{item.title}</span>
                                                            </NavLink>
                                                        }

                                                    </p>
                                                </div>
                                            </li>
                                        )
                                        :
                                        <li>
                                            <p className="text-center">{statusText}</p>
                                        </li>
                                }
                            </ul>
                            {TAKE == data.length && (
                                <div className="mt10 text-right">
                                    <button
                                        className="btn btn-default bg-orange"
                                        onClick={this.onLoadMore}
                                    >
                                        Load More ...
                                </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Index;

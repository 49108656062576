import React from "react";
import Dropzone from "react-dropzone";


export class HelpWithSMSAuth extends React.Component {
    state = {
        fullData: [],
    };

    onChange = () => {
        let refs = this.refs;
        let fullData = [];
        let { getDataOption } = this.props;

        let cellphoneNumber = {
            value: refs.cellphoneNumber.value,
            title: "Cellphone Number",
        };

        fullData.push(cellphoneNumber);

        this.setState({
            fullData: fullData
        }, () => {
            // getDataOption(JSON.stringify(this.state.fullData))
            getDataOption(fullData)
        });
    };

    render() {
        return (
            <div>
                <div className="mb15">
                    <p className="mb05">Cellphone Number <span className="color-red">*</span></p>
                    <div className="filed">
                        <div className="ct-filed">
                            <label className="text-layout-2">
                                <input type="text"
                                    ref="cellphoneNumber"
                                    name="Cellphone Number"
                                    validators="not-empty"
                                    onChange={this.onChange}
                                />
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


import React from 'react';
import './style.css';
import { NavLink } from "react-router-dom";
import { post } from "../../../api";
import { showSuccess, takeOutProcess, showError } from "../../../utils/app";
import { BASE_API_WEB_CONTENT } from "../../../constants/appSettings";

export class ArticleFooter extends React.Component {

    constructor(props) {
        super(props)
        this.state = {

        };
    }

    render() {
        let {
            likeNo,
            likeYes,
            submitLike
        } = this.props;

        return (
            <div className="article-footer">
                <div className="article-votes">
                    <span className="article-votes-question">Was this article helpful?</span>
                    <div className="article-votes-controls" role="radiogroup">
                        <a role="radio" rel="nofollow" className="button article-vote article-vote-up" title="Yes" aria-selected="false" onClick={() => submitLike(true)} />
                        <a role="radio" rel="nofollow" className="button article-vote article-vote-down" title="No" aria-selected="false" onClick={() => submitLike(false)} />
                    </div>
                    <small className="article-votes-count">
                        <span className="article-vote-label">{likeYes} out of {likeNo + likeYes} found this helpful</span>
                    </small>
                </div>
                <div className="article-more-questions">
                    Have more questions? <NavLink to="/submit-request">Submit a request</NavLink>
                </div>
            </div>
        )
    }
}
export default ArticleFooter;
